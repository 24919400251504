"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceAwardStatus = void 0;
var ServiceAwardStatus;
(function (ServiceAwardStatus) {
    ServiceAwardStatus["NOT_PAID"] = "not-paid";
    ServiceAwardStatus["SCHEDULED"] = "scheduled";
    ServiceAwardStatus["SENT"] = "sent";
    ServiceAwardStatus["REDEEMED"] = "redeemed";
    ServiceAwardStatus["CANCELLED"] = "cancelled";
    ServiceAwardStatus["CREDITED"] = "credited";
    ServiceAwardStatus["CONVERTED"] = "converted";
})(ServiceAwardStatus || (exports.ServiceAwardStatus = ServiceAwardStatus = {}));
