import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { Button, CardActions, Typography } from '@material-ui/core';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import Accordion from 'components/Accordion/Accordion';
import CardBody from 'components/Card/CardBody';

import logo from 'assets/img/applauz-logo-full.svg';

import InfoWrapper from 'components/Applauz/InfoWrapper';
import Loading from 'components/Loading/Loading';
import SnackbarContent from 'components/Snackbar/SnackbarContent';

import UsersTable from './Tables/Users';
import LicencesTable from './Tables/Licences';
import PointsTable from './Tables/Points';
import RedemptionsTable from './Tables/Redemptions';
import ServiceAwardsTable from './Tables/ServiceAwards';
import InvoicesTable from './Tables/Invoices';
import PreferencesTable from './Tables/Preferences';

import { getAccount } from 'helpers/api/accounts';
import { useQuery } from 'react-query';

const ViewAccount = ({ history }) => {
  const navigate = useNavigate();

  const { id } = useParams();
  const accountId = parseInt(id);
  const {
    data: account = null,
    error,
    status,
  } = useQuery(['account', accountId], async () => {
    const data = await getAccount(accountId);
    return data.account;
  });

  if (error || status === 'error')
    return (
      <GridItem xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <SnackbarContent
          message={
            'Could not get the account you are looking for. Please contact IT team'
          }
          color="rose"
        />
      </GridItem>
    );

  if (!account)
    return (
      <GridItem xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Loading />
      </GridItem>
    );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={4}>
        <Card>
          <CardHeader
            image
            style={{
              backgroundColor: 'white',
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src={account.logo || logo}
              alt="organization logo"
              style={{
                width: '100%',
                maxHeight: 108,
                objectFit: 'contain',
              }}
            />
          </CardHeader>
          <CardBody>
            <InfoWrapper label="Name" value={account.name} />
            <InfoWrapper label="Date Added" value={account.dateAdded} />
            <InfoWrapper label="Created By" value={account.owner} />
            <InfoWrapper label="Type" value={account.type} />
            <InfoWrapper label="Status" value={account.status} />
            <InfoWrapper label="Country" value={account.country} />
            <InfoWrapper
              label="Points Bought"
              value={
                <NumericFormat
                  value={account.wallet.total_points_purchased}
                  displayType={'text'}
                  thousandSeparator
                />
              }
            />

            <InfoWrapper
              label="Points Redeemed"
              value={
                <NumericFormat
                  value={account.wallet.redeemed_points}
                  displayType={'text'}
                  thousandSeparator
                />
              }
            />
            <InfoWrapper
              label="Points In Circulation"
              value={
                <NumericFormat
                  value={account.wallet.circulation_points}
                  displayType={'text'}
                  thousandSeparator
                />
              }
            />

            {account.isSandbox && (
              <Typography
                variant="caption"
                color="textSecondary"
                display="block"
                align="right"
              >
                *This is sandbox account
              </Typography>
            )}
          </CardBody>
          <CardActions>
            <Button
              size="small"
              color="primary"
              onClick={() => navigate(`/admin/accounts/edit/${accountId}`)}
            >
              Edit account
            </Button>
          </CardActions>
        </Card>
      </GridItem>
      <GridItem xs={12} md={8}>
        <Card>
          <CardBody>
            <Accordion
              collapses={[
                {
                  title: 'Users',
                  content: (
                    <UsersTable history={history} users={account.users} />
                  ),
                },
                {
                  title: 'Subscription Plan & Pricing',
                  content: <LicencesTable account={account} />,
                },
                {
                  title: 'Points',
                  content: (
                    <PointsTable
                      purchases={account.purchases}
                      wallet={account.wallet}
                    />
                  ),
                },
                {
                  title: 'Redemptions',
                  content: (
                    <RedemptionsTable redemptions={account.redemptions} />
                  ),
                },
                {
                  title: 'Service Awards',
                  content: (
                    <ServiceAwardsTable
                      awards={account.awards}
                      organizationID={account.id}
                    />
                  ),
                },
                {
                  title: 'Invoices',
                  content: <InvoicesTable invoices={account.invoices} />,
                },
                {
                  title: 'Configurations / Preferences',
                  content: <PreferencesTable accountId={accountId} />,
                },
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ViewAccount;
