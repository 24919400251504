"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Supplier = void 0;
var Supplier;
(function (Supplier) {
    Supplier[Supplier["LOYALTY_SOURCE_CA"] = 1] = "LOYALTY_SOURCE_CA";
    Supplier[Supplier["LOYALTY_SOURCE_GC_CA"] = 4] = "LOYALTY_SOURCE_GC_CA";
    Supplier[Supplier["LOYALTY_SOURCE_US"] = 5] = "LOYALTY_SOURCE_US";
    Supplier[Supplier["LOYALTY_SOURCE_GC_US"] = 6] = "LOYALTY_SOURCE_GC_US";
    Supplier[Supplier["LINKS_UNLIMITED"] = 7] = "LINKS_UNLIMITED";
    Supplier[Supplier["GRS"] = 8] = "GRS";
})(Supplier || (exports.Supplier = Supplier = {}));
