/* eslint-disable camelcase */

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { isNil } from 'lodash';

// @material-ui/core components
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridItem from 'components/Grid/GridItem';
import SnackbarContent from 'components/Snackbar/SnackbarContent';
import Loading from 'components/Loading/Loading';

import Form from './Form';

import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import {
  editAccount,
  getOrganization,
  getOrganizationCatalog,
} from 'helpers/api/accounts';
import { getModules, updateModules } from 'helpers/api/modules';

import { GRSCatalogType } from '@applauz/enums';

makeStyles(styles);

const EditAccount = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ready, setReady] = useState(false);

  const comp = {
    company_name: '',
    hubspotCompanyID: '',
    xeroContactID: '',
    owner: {
      firstName: '',
      lastName: '',
      email: '',
    },
    address: {
      address_1: '',
      address_2: '',
      phone: '',
      postal_code: '',
      city: '',
      province_id: '',
      country_id: '38',
    },
    isSandbox: false,
  };

  const cata = {
    grsCatalogId: '',
    supplierIds: [],
  };

  const [company, setCompany] = useState(comp);
  const [catalog, setCatalog] = useState(cata);
  const [catalogSA, setCatalogSA] = useState({ grsCatalogId: '' });
  const [activeModuleIds, setActiveModuleIds] = useState([]);

  const queryClient = useQueryClient();
  const { data: organizations } = useQuery(['getOrganization', params.id], () =>
    getOrganization(params.id)
  );

  useEffect(() => {
    if (!organizations) return;
    const { id, name, hubspotCompanyID, xeroContactID, address, isSandbox } =
      organizations[0];

    setCompany({
      id,
      company_name: name,
      hubspotCompanyID: isNil(hubspotCompanyID) ? '' : String(hubspotCompanyID),
      xeroContactID: xeroContactID || '',
      address: {
        address_1: address.address_1 || '',
        address_2: address.address_2 || '',
        phone: address.phone || '',
        city: address.city || '',
        postal_code: address.postal_code || '',
        province_id: address.province_id || '',
        country_id: address.country_id || '38',
      },
      isSandbox,
    });
    setReady(true);
  }, [organizations]);

  const { data: orgCatalog } = useQuery(
    ['getOrganizationCatalog', params.id, GRSCatalogType.POINTS],
    () => getOrganizationCatalog(params.id, GRSCatalogType.POINTS)
  );

  const { data: orgCatalogSA } = useQuery(
    ['getOrganizationCatalog', params.id, GRSCatalogType.SERVICE_AWARDS],
    () => getOrganizationCatalog(params.id, GRSCatalogType.SERVICE_AWARDS)
  );

  useEffect(() => {
    if (orgCatalog) {
      setCatalog({
        grsCatalogId: orgCatalog.grsCatalogId || '',
        supplierIds: orgCatalog.supplierIds || [],
      });
    }

    if (orgCatalogSA) {
      setCatalogSA({
        grsCatalogId: orgCatalogSA.grsCatalogId || '',
      });
    }

    if (orgCatalog && orgCatalogSA) {
      setReady(true);
    }
  }, [orgCatalog, orgCatalogSA]);

  const updateCompany = (name, value, subProp) => {
    if (subProp) {
      setCompany({
        ...company,
        [name]: { ...company[name], [subProp]: value },
      });
      return;
    }
    setCompany({ ...company, [name]: value });
  };

  // modules
  useEffect(() => {
    // initial
    if (!company?.id) return;
    getModules(company?.id).then(({ modules }) => {
      if (!modules) return;
      const activated = modules
        .filter((appModule) => appModule.isActive)
        .map(({ id }) => id);
      setActiveModuleIds(activated);
    });
  }, [company?.id]);
  const onChangeModuleIdsHandler = React.useCallback((id) => {
    id
      && setActiveModuleIds((prev) =>
        prev.includes(id)
          ? prev.filter((moduleId) => moduleId !== id)
          : [...prev, id]
      );
  }, []);

  const onSubmit = () => {
    setError(false);
    setIsLoading(true);
    const {
      id,
      company_name,
      xeroContactID,
      hubspotCompanyID,
      address,
      supplierIds,
      grsCatalogId,
      grsCatalogIdSA,
    } = company;

    editAccount({
      id,
      name: company_name,
      xero_contact_id: xeroContactID,
      hubspot_company_id: hubspotCompanyID,
      ...address,
      supplierIds,
      grsCatalogId: grsCatalogId === '' ? null : grsCatalogId,
      grsCatalogIdSA: grsCatalogIdSA === '' ? null : grsCatalogIdSA,
    })
      .then(async (res) => {
        const moduleRes = await updateModules(id, activeModuleIds);
        if (res.success && moduleRes.success) {
          queryClient.invalidateQueries(['getOrganization', params.id]);
          queryClient.invalidateQueries(['getOrganizationCatalog', params.id]);
          setSuccess(true);
          setTimeout(() => {
            navigate(`/admin/accounts/view/${id}`);
          }, 1000);
        }
        setIsLoading(false);
      })
      .catch((editAccountError) => {
        setIsLoading(false);
        console.error({ editAccountError });
        setError(`Could not edit account properly. Please contact IT team`);
      });
  };

  if (!ready)
    return (
      <GridItem xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Loading />
      </GridItem>
    );
  return (
    <>
      {error && <SnackbarContent message={error} color="rose" />}

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 20,
        }}
      >
        <Button
          color="primary"
          onClick={() =>
            navigate(`/admin/accounts/view/${organizations[0].id}`)
          }
        >
          View Organization
        </Button>
      </div>

      <Form
        company={company}
        catalog={catalog}
        catalogSA={catalogSA}
        setCompany={updateCompany}
        activeModuleIds={activeModuleIds}
        onChangeActiveModuleIds={onChangeModuleIdsHandler}
        isEdit
        onSubmit={onSubmit}
        isLoading={isLoading}
        success={success}
      />
    </>
  );
};

export default EditAccount;
