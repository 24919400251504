import React from 'react';
import { useQuery } from 'react-query';

import { getSnappointsTransactions } from 'helpers/api/accounts';

// @material-ui/data-grid
import { DataGrid } from '@material-ui/data-grid';

import useGridColumns from './useGridColumns';
import Filters from '../Filters';

export default function Homepage() {
  const [filters, setFilters] = React.useState({
    organizationsSearchQuery: '',
    usersSearchQuery: '',
  });
  const [transactions, setTransactions] = React.useState([]);
  const { isLoading, data: snappointsTransactions } = useQuery(
    ['snappointsTransactions'],
    () => getSnappointsTransactions()
  );

  React.useEffect(() => {
    if (snappointsTransactions) {
      setTransactions(snappointsTransactions);
    }
  }, [snappointsTransactions]);

  const filteredTransactions = React.useMemo(() => {
    const newTransactions = transactions.filter((transaction) => {
      if (filters.usersSearchQuery) {
        return `${transaction.firstName} ${transaction.lastName} ${transaction.userId}`
          .toLowerCase()
          .includes(filters.usersSearchQuery.toLowerCase());
      }
      if (filters.organizationsSearchQuery) {
        return `${transaction.organizationName} ${transaction.organizationId}`
          .toLowerCase()
          .includes(filters.organizationsSearchQuery.toLowerCase());
      }
      return true;
    });
    return newTransactions;
  }, [
    transactions,
    filters.usersSearchQuery,
    filters.organizationsSearchQuery,
  ]);

  const [sortModel, setSortModel] = React.useState([
    { field: 'id', sort: 'desc' },
  ]);
  const columns = useGridColumns();

  return (
    <>
      <Filters onChange={setFilters} />
      <DataGrid
        loading={isLoading}
        rowCount={filteredTransactions?.length || 0}
        rows={filteredTransactions || []}
        getRowId={(row) => row.id}
        columns={columns}
        hideFooterPagination={isLoading}
        pageSize={20}
        autoHeight
        sortingOrder={['asc', 'desc']}
        onSortModelChange={(model) => {
          if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
            setSortModel(model);
          }
        }}
        sortModel={sortModel}
      />
    </>
  );
}
