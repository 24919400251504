import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import SnackbarContent from 'components/Snackbar/SnackbarContent';

// Other components
import InfoWrapper from 'components/Applauz/InfoWrapper';
import logo from 'assets/img/applauz-logo-full.svg';
import Select from 'react-select';

// react query
import { useQuery, useMutation } from 'react-query';

// api
import {
  getProduct,
  getCategories,
  getBrands,
  saveProduct,
} from 'helpers/api/catalog';

const EditProduct = () => {
  const params = useParams();
  const [product, setProduct] = useState({});
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState({});
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const customStyles = {};

  //   backend fetch
  const { data: categories = [], isLoading: categoriesLoading } = useQuery(
    'categories',
    getCategories
  );
  const { data: brands = [], isLoading: brandsLoading } = useQuery(
    'brands',
    getBrands
  );

  // mutation
  const [productMutation] = useMutation(saveProduct);

  useEffect(() => {
    getProduct(params.id)
      .then((products) => {
        setProduct(products[0]);
        setReady(true);
      })
      .catch(() => {
        setError('Could not get this product! Please contact IT Team!');
      });
  }, []);

  useEffect(() => {
    if (product.categories) {
      setSelectedCategories(product.categories);
    }
    if (product.brand) {
      setSelectedBrand(product.brand);
    }
  }, [product]);

  const disabled = useMemo(
    () =>
      (product
        && product.brand
        && product.brand.brand_id === selectedBrand.brand_id
        && product.categories
        && product.categories.length === selectedCategories.length
        && product.categories.every((value, index) => {
          return value === selectedCategories[index];
        }))
      || categoriesLoading
      || brandsLoading,
    [selectedCategories, selectedBrand, product]
  );

  const saveChanges = () => {
    productMutation({
      product_id: product.productID,
      categories: selectedCategories.map(({ category_id }) => category_id),
      brand_id: selectedBrand.brand_id,
    }).then((response) => {
      console.log({ response });
      setSuccessMessage('The product was saved succesfully!');

      setTimeout(() => setSuccessMessage(null), 3000);
    });
  };

  if (!ready) return null;

  return (
    <GridContainer style={{ height: 2000 }}>
      {error ? (
        <div>
          <span>{error}</span>
        </div>
      ) : (
        <>
          <GridItem xs={12} sm={12} md={3}>
            <Card>
              <CardBody style={{ backgroundColor: 'white', width: 200 }}>
                <img
                  src={product.image || logo}
                  alt="..."
                  width={100}
                  height={200}
                />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <GridItem>
              {successMessage && (
                <SnackbarContent message={successMessage} color="success" />
              )}
            </GridItem>
            <Card>
              <CardBody>
                <InfoWrapper
                  label="ID"
                  value={product.productID}
                  valueStyle={{ display: 'flex', justifyContent: 'flex-end' }}
                />
                <InfoWrapper
                  label="Name"
                  value={product.name}
                  valueStyle={{ display: 'flex', justifyContent: 'flex-end' }}
                />
                <InfoWrapper
                  label="Model"
                  value={product.model}
                  valueStyle={{ display: 'flex', justifyContent: 'flex-end' }}
                />
                <InfoWrapper
                  label="Date Added"
                  value={product.dateAdded}
                  valueStyle={{ display: 'flex', justifyContent: 'flex-end' }}
                />
                <InfoWrapper
                  label="Supplier"
                  value={product.supplier}
                  valueStyle={{ display: 'flex', justifyContent: 'flex-end' }}
                />
                <InfoWrapper
                  label="Price"
                  value={parseFloat(product.price).toFixed(2)}
                  valueStyle={{ display: 'flex', justifyContent: 'flex-end' }}
                />
                <InfoWrapper
                  label="Shipping Cost"
                  value={parseFloat(product.shipping_cost).toFixed(2)}
                  valueStyle={{ display: 'flex', justifyContent: 'flex-end' }}
                />
                <InfoWrapper
                  label="MRSP"
                  value={parseFloat(product.mrsp).toFixed(2)}
                  valueStyle={{ display: 'flex', justifyContent: 'flex-end' }}
                />
                <GridItem style={{ marginTop: 20 }}>
                  <h4>Product Categories</h4>
                  <Select
                    defaultValue={selectedCategories}
                    menuIsOpen={menuIsOpen}
                    onMenuOpen={() => setMenuIsOpen(true)}
                    onMenuClose={() => setMenuIsOpen(false)}
                    closeMenuOnSelect={false}
                    isMulti
                    onChange={(options) => {
                      if (options) setSelectedCategories(options);
                      else setSelectedCategories([]);
                    }}
                    options={categories}
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ category_id }) => category_id}
                    placeholder={'Select Categories'}
                    styles={customStyles}
                  />
                </GridItem>
                <GridItem style={{ marginTop: 20 }}>
                  <h4>Product Brand</h4>
                  <Select
                    value={selectedBrand}
                    onChange={(brand) => setSelectedBrand(brand)}
                    options={brands}
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ brand_id }) => brand_id}
                    placeholder={'Select Brand'}
                    styles={customStyles}
                  />
                </GridItem>
                <GridItem
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: 10,
                  }}
                >
                  <Button
                    onClick={saveChanges}
                    color={disabled ? 'github' : 'info'}
                    disabled={disabled}
                  >
                    Save Changes
                  </Button>
                </GridItem>
              </CardBody>
            </Card>
          </GridItem>
        </>
      )}
    </GridContainer>
  );
};

export default EditProduct;
