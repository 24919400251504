import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import Homepage from './Users/Homepage';
import AddUser from './Users/AddUser';
import EditUser from './Users/EditUser';

const UsersPage = () => {
  return (
    <Routes>
      <Route path="/admin/users/manage" element={<Homepage />} />
      <Route path="/admin/users/add" element={<AddUser />} />
      <Route path="/admin/users/edit/:id" element={<EditUser />} />
      <Route
        path="/admin/users"
        element={<Navigate to="/admin/users/manage" />}
      />
    </Routes>
  );
};

export default UsersPage;
