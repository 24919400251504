const { s3, global_base_url, api, auth, super_admin, users } =
  JSON.parse(localStorage.getItem('endpoints')) || {};

export const s3Bucket = `${s3}500x500/image/`;

export const applauzUrl = global_base_url;

export const superAdminUrl = super_admin;

export const asAuthUrl = auth;

export const apiURL = api;

export const usersUrl = users;
