import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import Homepage from './Catalog/Products/Homepage';
import EditProduct from './Catalog/Products/EditProduct';

const ProductsPage = () => {
  return (
    <Routes>
      <Route path="/admin/products/manage" element={<Homepage />} />
      <Route path="/admin/products/edit/:id" element={<EditProduct />} />
      <Route
        path="/admin/products"
        element={<Navigate to="/admin/products/manage" />}
      />
    </Routes>
  );
};

export default ProductsPage;
