import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { AwardStatus } from '@applauz/enums';
// @material-ui/data-grid
import { DataGrid } from '@material-ui/data-grid';
import LinkIcon from '@material-ui/icons/Link';

import {
  getAwards,
  bulkUpdateAwards,
  bulkReactivateAwards,
  convertVouchersToPoints,
  getMagicLoginLink,
} from 'helpers/api/awards';

import { searchString } from 'helpers/index';
import mapVouchers from './utils/mapVouchers';

import SnackbarContent from 'components/Snackbar/SnackbarContent';

import Filters from './Filters';
import BulkActions from './BulkActions';
import Modal from './Modal';

function handleGenerateMagicLink(id, statusId) {
  if (statusId !== 3) {
    if (
      !confirm(
        'This voucher is not active. Are you sure you want to generate a magic link?'
      )
    ) {
      return;
    }
  }
  getMagicLoginLink(id)
    .then((res) => {
      prompt('Magic link:', res.link);
    })
    .catch((err) => {
      alert(`Error getting magic link: ${err}`);
    });
}

const Homepage = () => {
  const [vouchers, setVouchers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [bulkVoucherIds, setBulkVoucherIds] = useState([]);
  const [params, setParams] = useState({});
  const [filters, setFilters] = useState({
    organizationName: null,
    statusID: null,
    isActive: null,
    isExpiringSoon: null,
  });
  const [error, setError] = useState('');
  const [sortModel, setSortModel] = useState([
    { field: 'dateCreated', sort: 'desc' },
  ]);

  useEffect(() => {
    fetchAwards();
  }, []);

  const fetchAwards = () => {
    setVouchers([]);
    setIsLoading(true);
    getAwards()
      .then((res) => {
        setVouchers(mapVouchers(res));
        setIsLoading(false);
      })
      .catch((getBulkAwardsError) => console.error({ getBulkAwardsError }));
  };

  const filteredVouchers = useMemo(() => {
    let selectedVouchers = vouchers;
    if (filters.statusID) {
      selectedVouchers = selectedVouchers.filter(
        (voucher) => voucher.statusID && voucher.statusID == filters.statusID
      );
    }
    if (filters.organizationName) {
      selectedVouchers = selectedVouchers.filter(
        (voucher) =>
          voucher.organization
          && searchString(voucher.organization, filters.organizationName)
      );
    }

    if (filters.employeeName) {
      selectedVouchers = selectedVouchers.filter(
        (voucher) =>
          voucher.user && searchString(voucher.user, filters.employeeName)
      );
    }

    if (filters.isActive) {
      selectedVouchers = selectedVouchers.filter((voucher) =>
        moment().isBetween(
          moment(voucher.dtActivated),
          moment(voucher.dtDeactivated)
        )
      );
    }

    if (filters.isExpiringSoon) {
      selectedVouchers = selectedVouchers.filter((voucher) =>
        moment(voucher.dtDeactivated).isBetween(
          moment(),
          moment().add(30, 'days')
        )
      );
    }

    return selectedVouchers;
  }, [vouchers, filters]);

  const onSaveCelebrationDate = (celebrationDate) => {
    setModalContent(
      `You are about to change the celebrate date for ${bulkVoucherIds.length} vouchers to ${celebrationDate}. Are you sure to want to continue?`
    );
    setParams({ celebrationDate });
    setShowModal(true);
  };
  const onActivateVouchers = () => {
    setModalContent(
      `You are about to activate ${
        bulkVoucherIds.length
      } vouchers and set their licence deactivation date to ${moment()
        .add(90, 'days')
        .format('YYYY-MM-DD')}. Are you sure to want to continue?`
    );
    setParams({ activate: true });
    setShowModal(true);
  };
  const onDeactivateVouchers = () => {
    setModalContent(
      `You are about to deactivate ${bulkVoucherIds.length} vouchers. Are you sure to want to continue?`
    );
    setParams({ deactivate: true });
    setShowModal(true);
  };
  const onReactivateVouchers = () => {
    const selectedVouchers = filteredVouchers.filter((voucher) =>
      bulkVoucherIds.includes(voucher.id)
    );
    const canReactivateSelectedVouchers = selectedVouchers.every(
      (voucher) => voucher.statusID === 4
    );
    if (!canReactivateSelectedVouchers) {
      setError('Only redeemed vouchers can be reactivated.');
      setTimeout(() => setError(''), 2000);
      return;
    }
    setParams({ reactivate: true });
    setModalContent(
      `You are about to reactivate ${bulkVoucherIds.length} vouchers. Are you sure to want to continue?`
    );
    setShowModal(true);
  };

  const onConvertToPoints = () => {
    const selectedVouchers = filteredVouchers.filter((voucher) =>
      bulkVoucherIds.includes(voucher.id)
    );
    const applicableStatuses = [
      AwardStatus.PAID,
      AwardStatus.ACTIVE,
      AwardStatus.REDEEMED,
    ];
    const canConvertSelectedVouchers = selectedVouchers.every((voucher) =>
      applicableStatuses.includes(voucher.statusID)
    );
    if (!canConvertSelectedVouchers) {
      setError(
        'The selected vouchers cannot be converted to points. Only paid, active, and redeemed vouchers can be converted.'
      );
      setTimeout(() => setError(''), 5000);
      return;
    }

    setParams({ convertToPoints: true });
    setModalContent(`Warning! 
    You are about to convert ${selectedVouchers.length} voucher${
      selectedVouchers.length === 1 ? '' : 's'
    } into points. This action is irreversible. Do you want to continue?`);
    setShowModal(true);
  };

  const onConfirmModal = () => {
    if (params.reactivate) {
      bulkReactivateAwards({ awardIDs: bulkVoucherIds })
        .then(() => {
          setShowModal(false);
          fetchAwards();
        })
        .catch((getBulkReactivateAwardsError) =>
          console.error({ getBulkReactivateAwardsError })
        );
    } else if (params.convertToPoints) {
      convertVouchersToPoints({ awardIds: bulkVoucherIds })
        .then(() => {
          setShowModal(false);
          fetchAwards();
        })
        .catch((getConvertVouchersError) => {
          console.error({ getConvertVouchersError });
        });
    } else {
      bulkUpdateAwards({ ...params, awardIds: bulkVoucherIds })
        .then(() => {
          setShowModal(false);
          fetchAwards();
        })
        .catch((getBulkUpdateAwardsError) =>
          console.error({ getBulkUpdateAwardsError })
        );
    }
  };

  const columns = [
    {
      headerName: 'Created',
      field: 'dateCreated',
      width: 120,
    },
    {
      headerName: 'Account',
      field: 'organization',
      width: 300,
    },
    {
      headerName: 'Employee',
      field: 'user',
      width: 200,
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 200,
    },
    {
      headerName: 'Activated',
      field: 'dtActivated',
      width: 200,
    },
    {
      headerName: 'Deactivated',
      field: 'dtDeactivated',
      width: 200,
    },
    {
      headerName: 'Celebration',
      field: 'celebration',
      width: 100,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      width: 100,
    },
    {
      headerName: 'Actions',
      field: 'action',
      sortable: false,
      renderCell: (renderCellParam) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          handleGenerateMagicLink(
            renderCellParam.id,
            renderCellParam.row.statusID
          );
        };
        return <LinkIcon onClick={onClick} style={{ cursor: 'pointer' }} />;
      },
    },
  ];

  const onSortModelChangeHandler = React.useCallback(
    (model) => {
      if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
        setSortModel(model);
      }
    },
    [sortModel, setSortModel]
  );

  const onSelectionModelChangeHandler = React.useCallback(
    (newSelectionModel) => {
      setBulkVoucherIds(newSelectionModel);
    },
    [setBulkVoucherIds]
  );

  return (
    <>
      {error && <SnackbarContent message={error} color="rose" />}
      <Modal show={showModal} setShow={setShowModal} onConfirm={onConfirmModal}>
        {modalContent}
      </Modal>
      <Filters onChange={setFilters} />
      <BulkActions
        disabled={!bulkVoucherIds.length}
        onSaveCelebrationDate={onSaveCelebrationDate}
        onActivateVouchers={onActivateVouchers}
        onDeactivateVouchers={onDeactivateVouchers}
        onReactivateVouchers={onReactivateVouchers}
        onConvertToPoints={onConvertToPoints}
      />
      <DataGrid
        loading={isLoading}
        rowCount={vouchers.length}
        rows={filteredVouchers}
        columns={columns}
        autoHeight
        hideFooterPagination={isLoading}
        checkboxSelection
        disableSelectionOnClick
        sortModel={sortModel}
        onSortModelChange={onSortModelChangeHandler}
        onSelectionModelChange={onSelectionModelChangeHandler}
        pageSize={100}
      />
    </>
  );
};

export default Homepage;
