import React from 'react';
import { generateQuote } from 'helpers/api/licences';

function useGeneratedQuote(
  selectedPurchaseType,
  selectedAccountId,
  selectedCurrency
) {
  /**
   * These two are tracked separately so that the user can re-open the dialog
   * by pressing "Generate Quote" without having to generate a new quote if
   * nothing was changed.
   */
  const [url, setURL] = React.useState('');
  const [dialogIsOpen, setDialogIsOpen] = React.useState(false);

  /**
   * If anything the orgs or the purchase type change, clear the generated
   * quote URL so that a new one can be generated when the user presses on
   * "Generate Quote."
   */
  React.useEffect(() => {
    setDialogIsOpen(false);
    setURL('');
  }, [selectedPurchaseType, selectedAccountId, selectedCurrency]);

  /**
   * If anything in the form changes, this function which is passed
   * down to the form will be called to clear the generated quote.
   */
  const generate = React.useCallback(
    (values) => {
      if (url) {
        setDialogIsOpen(true);
      } else {
        generateQuote({
          organizationId: selectedAccountId,
          isCustomQuote: true,
          ...values,
        }).then(({ invoiceId }) => {
          setURL(
            `${process.env.REACT_APP_HUB_CLIENT_URL}/invoices?invoiceId=${invoiceId}`
          );
          setDialogIsOpen(true);
        });
      }
    },
    [url, selectedAccountId]
  );

  /**
   * This is to provide to the invoice form to inform the parent
   * that the quote needs to be cleared so that a new one is generated.
   */
  const clearURL = React.useCallback(() => {
    setURL('');
  }, []);

  const closeDialog = React.useCallback(() => {
    setDialogIsOpen(false);
  }, []);

  return {
    url,
    generate,
    clearURL,
    dialogIsOpen,
    closeDialog,
  };
}

export default useGeneratedQuote;
