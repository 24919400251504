import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import formatPrice from 'helpers/lib/formatPrice';
import formatDecimalPoint from './formatDecimalPoint';

function ProratedCreditRow({ proratedCredit, currentOriginalPricePerLicence }) {
  const {
    creditPerLicence,
    creditTotal,
    licencesCount,
    licenceOriginalEndDate,
    remainingDays,
  } = proratedCredit;

  const formattedCurrentOriginalPricePerLicence = formatDecimalPoint(
    currentOriginalPricePerLicence
  );

  return (
    <>
      <Grid item xs={6}>
        <Typography variant="body1">
          <Box color="rgb(0, 172, 193)" fontWeight="bold">
            Prorated Credit
          </Box>
          <Box color="text.secondary" fontSize={13}>
            Original subscription end date: {`${licenceOriginalEndDate}`} /
            remaining {remainingDays} {remainingDays === 1 ? 'day' : 'days'}
          </Box>
          <Box color="text.secondary" fontSize={13} mt={0.5}>
            The price per licence is normally $
            {formattedCurrentOriginalPricePerLicence || 0} for a full cycle. The
            prorated credit is that amount adjusted for the remaining days in
            the cycle.
            <Box fontWeight={600} mt={0.5}>
              ${formattedCurrentOriginalPricePerLicence} ÷ number of days in the
              cycle × {remainingDays} remaining days = $
              {formatDecimalPoint(creditPerLicence)}
            </Box>
          </Box>
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <TextField
          label="Credit per licence"
          InputProps={{
            startAdornment: (
              <Box>
                <Typography noWrap>$</Typography>
              </Box>
            ),
            readOnly: true,
          }}
          type="number"
          disabled
          variant="outlined"
          value={creditPerLicence}
        />
      </Grid>

      <Grid item xs={2}>
        <TextField
          label="Licences"
          type="number"
          disabled
          variant="outlined"
          value={licencesCount || 0}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          variant="outlined"
          label="Total credit"
          disabled
          value={formatPrice(-creditTotal)}
        />
      </Grid>
    </>
  );
}

export default ProratedCreditRow;
