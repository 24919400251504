import { useCallback, useMemo } from 'react';

const useDiscountRate = (selectedPackage) => {
  const getDiscountRate = useCallback(() => {
    if (!selectedPackage.typeId) return 0;
    const discountsSum = selectedPackage.discounts.reduce((acc, discount) => {
      return acc + discount.value;
    }, 0);
    const total = +discountsSum.toFixed(2); // string to number
    return 1 - total;
  }, [selectedPackage]);

  const discountTotalRate = useMemo(getDiscountRate, [getDiscountRate]);

  return discountTotalRate;
};

export default useDiscountRate;
