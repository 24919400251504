import React from 'react';
import { useNavigate } from 'react-router-dom';
// react component for creating dynamic tables
import MaterialTable from 'material-table';
import SearchIcon from '@material-ui/icons/Search';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import InfoWrapper from 'components/Applauz/InfoWrapper';

import Roles from 'components/Applauz/Roles';

const Users = ({ users }) => {
  const navigate = useNavigate();
  return (
    <GridContainer>
      <GridItem xs={6}>
        <InfoWrapper
          label="Number of users"
          value={`${
            users.filter(({ status }) => status == 1).length
          } (active) / ${users.length} (total)`}
        />
      </GridItem>
      <GridItem xs={12}>
        <MaterialTable
          title=""
          columns={[
            {
              title: 'Name',
              field: 'name',
              render: (rowData) => (
                <div>
                  <img
                    src={rowData.imageSrc}
                    style={{
                      width: 40,
                      borderRadius: '50%',
                      marginRight: 10,
                    }}
                  />
                  <span>{rowData.name}</span>
                </div>
              ),
            },
            { title: 'Email', field: 'email' },
            {
              title: 'Date Added',
              field: 'dateAdded',
              defaultSort: 'desc',
            },
            {
              title: 'Roles',
              field: 'roles',
              render: (rowData) => <Roles data={rowData.roles} />,
              customFilterAndSearch: (term, rowData) => {
                return (
                  rowData.roles.filter((role) =>
                    role.toLowerCase().includes(term.toLowerCase())
                  ).length > 0
                );
              },
            },
          ]}
          data={users}
          actions={[
            (rowData) => ({
              icon: 'visibility',
              tooltip: 'Edit User',
              onClick: (event, { id }) => {
                navigate(`/admin/accounts/employee/${id}`);
              },
            }),
          ]}
          options={{
            actionsColumnIndex: -1,
            // search: false,
            // toolbar: false,
          }}
          icons={{ Filter: SearchIcon }}
          style={{ boxShadow: 'none' }}
        />
      </GridItem>
    </GridContainer>
  );
};

export default Users;
