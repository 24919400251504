"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceType = void 0;
var InvoiceType;
(function (InvoiceType) {
    InvoiceType["AWARDS"] = "awards";
    InvoiceType["LICENCES"] = "licences";
    InvoiceType["LICENCES_ADDITION"] = "licences_addition";
    InvoiceType["LICENCES_RENEW"] = "licences_renew";
    InvoiceType["POINTS_PURCHASE"] = "points_purchase";
    InvoiceType["TOP_UP_POINTS"] = "top_up_points";
})(InvoiceType || (exports.InvoiceType = InvoiceType = {}));
