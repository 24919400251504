"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionType = void 0;
var TransactionType;
(function (TransactionType) {
    TransactionType["ENTITY"] = "entity";
    TransactionType["MARKETPLACE"] = "marketplace";
    TransactionType["BULK"] = "bulk";
    TransactionType["BANK"] = "bank";
})(TransactionType || (exports.TransactionType = TransactionType = {}));
