import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { getModules } from 'helpers/api/modules';
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';

import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import WidgetsIcon from '@material-ui/icons/Widgets';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(styles);

const ModulesForm = ({ companyId, activatedModuleIds, onChange }) => {
  const [modules, setModules] = useState([]);

  const classes = useStyles();

  const { data, isLoading } = useQuery(['companyId', companyId], async () => {
    const moduleData = await getModules(companyId);
    return moduleData;
  });

  const firstModuleGroup = useMemo(
    () => (modules.length && modules.slice(0, 5)) || [],
    [modules]
  );
  const secondModuleGroup = useMemo(
    () => modules.length > 5 && modules.slice(5),
    [modules]
  );

  const onChangeHandler = (e) => {
    if (!e?.target?.name) return;
    const id = parseInt(e.target.name);
    onChange(id);
  };

  useEffect(() => {
    if (!isLoading && data?.modules?.length) {
      setModules(data.modules);
    }
  }, [data?.modules, isLoading]);

  if (isLoading) return <CircularProgress />;

  return (
    <Card>
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <WidgetsIcon />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>Modules</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={6} md={5}>
            <FormGroup>
              {firstModuleGroup.map((applauzModule) => (
                <FormControlLabel
                  key={applauzModule.id}
                  label={applauzModule.name.en}
                  labelPlacement="start"
                  control={
                    <Switch
                      name={`${applauzModule.id}`}
                      checked={activatedModuleIds.includes(applauzModule.id)}
                      onChange={onChangeHandler}
                      color="primary"
                    />
                  }
                />
              ))}
            </FormGroup>
          </GridItem>
          {secondModuleGroup.length && (
            <GridItem xs={6} md={4} lg={7}>
              <FormGroup>
                {secondModuleGroup.map((applauzModule) => (
                  <FormControlLabel
                    key={applauzModule.id}
                    label={applauzModule.name.en}
                    labelPlacement="start"
                    control={
                      <Switch
                        name={`${applauzModule.id}`}
                        checked={activatedModuleIds.includes(applauzModule.id)}
                        onChange={onChangeHandler}
                        color="primary"
                      />
                    }
                  />
                ))}
              </FormGroup>
            </GridItem>
          )}
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default ModulesForm;
