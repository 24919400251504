import { get, post } from '.';

export const getLicenceTypes = () => {
  return get(`${process.env.REACT_APP_HUB_SERVER_URL}/licences/types`);
};

export const generateQuote = (params) => {
  return post(
    `${process.env.REACT_APP_HUB_SERVER_URL}/licences/makeQuoteSubscription`,
    { params }
  );
};

export const generateImmediateChangeQuote = (params) => {
  return post(
    `${process.env.REACT_APP_HUB_SERVER_URL}/licences/makeQuoteSubscriptionImmediateTurn`,
    { params }
  );
};

export const getUpcomingPlan = (params) => {
  return get(
    `${process.env.REACT_APP_HUB_SERVER_URL}/licences/getUpcomingSubscription`,
    {
      params,
    }
  );
};

// need this to get prorated price
export const getCurrentSubscription = (params) => {
  return get(
    `${process.env.REACT_APP_HUB_SERVER_URL}/licences/getCurrentSubscription`,
    {
      params,
    }
  );
};

export const cancelUpcomingPlan = (params) => {
  return post(
    `${process.env.REACT_APP_HUB_SERVER_URL}/licences/cancelUpcoming`,
    {
      params,
    }
  );
};

export const updateUpcomingPlan = (params) => {
  return post(`${process.env.REACT_APP_HUB_SERVER_URL}/licences/setUpcoming`, {
    params,
  });
};

export const updatePrice = (params) => {
  return post('as://subscriptions/admin/updatePrice', { params });
};

export const getInvoice = (params) => {
  return post(`${process.env.REACT_APP_HUB_SERVER_URL}/invoices/getInvoice`, {
    params,
  });
};
