"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankType = void 0;
var BankType;
(function (BankType) {
    BankType[BankType["PURCHASE"] = 1] = "PURCHASE";
    BankType[BankType["CREDIT"] = 2] = "CREDIT";
    BankType[BankType["TOPUP"] = 3] = "TOPUP";
    BankType[BankType["REFUND"] = 4] = "REFUND";
})(BankType || (exports.BankType = BankType = {}));
