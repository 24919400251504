"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OngoingOrderStatus = void 0;
const OrderStatus_1 = require("./OrderStatus");
var OngoingOrderStatus;
(function (OngoingOrderStatus) {
    OngoingOrderStatus[OngoingOrderStatus["PENDING"] = OrderStatus_1.OrderStatus.PENDING] = "PENDING";
    OngoingOrderStatus[OngoingOrderStatus["PROCESSING"] = OrderStatus_1.OrderStatus.PROCESSING] = "PROCESSING";
    OngoingOrderStatus[OngoingOrderStatus["PROCESSED"] = OrderStatus_1.OrderStatus.PROCESSED] = "PROCESSED";
    OngoingOrderStatus[OngoingOrderStatus["REVIEW"] = OrderStatus_1.OrderStatus.REVIEW] = "REVIEW";
    OngoingOrderStatus[OngoingOrderStatus["PICK_UP"] = OrderStatus_1.OrderStatus.PICK_UP] = "PICK_UP";
    OngoingOrderStatus[OngoingOrderStatus["PARTIALLY_SHIPPED"] = OrderStatus_1.OrderStatus.PARTIALLY_SHIPPED] = "PARTIALLY_SHIPPED";
    OngoingOrderStatus[OngoingOrderStatus["PROCESSING_RETURN"] = OrderStatus_1.OrderStatus.PROCESSING_RETURN] = "PROCESSING_RETURN";
})(OngoingOrderStatus || (exports.OngoingOrderStatus = OngoingOrderStatus = {}));
