"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcceptedFileTypeVideo = void 0;
var AcceptedFileTypeVideo;
(function (AcceptedFileTypeVideo) {
    AcceptedFileTypeVideo["M4V"] = "m4v";
    AcceptedFileTypeVideo["MOV"] = "mov";
    AcceptedFileTypeVideo["MP4"] = "mp4";
    AcceptedFileTypeVideo["MPEG"] = "mpeg";
    AcceptedFileTypeVideo["MPG"] = "mpg";
    AcceptedFileTypeVideo["QT"] = "qt";
})(AcceptedFileTypeVideo || (exports.AcceptedFileTypeVideo = AcceptedFileTypeVideo = {}));
