import React from 'react';
import { NumericFormat } from 'react-number-format';
// react component for creating dynamic tables
import MaterialTable from 'material-table';
import SearchIcon from '@material-ui/icons/Search';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import InfoWrapper from 'components/Applauz/InfoWrapper';

const Redemptions = ({ history, redemptions }) => {
  return (
    <GridContainer>
      <GridItem xs={6}>
        <InfoWrapper label="Redemptions Made" value={redemptions.length} />
      </GridItem>
      <GridItem xs={6}>
        <InfoWrapper
          label="Points redeemed"
          value={redemptions.reduce((acc, { points }) => acc + points, 0)}
        />
      </GridItem>
      <GridItem xs={12}>
        <MaterialTable
          title=""
          columns={[
            { title: 'Date', field: 'date', defaultSort: 'desc' },
            { title: 'Type', field: 'type' },
            { title: 'User', field: 'user' },
            {
              title: 'Points',
              field: 'points',
              render: (rowData) => (
                <NumericFormat
                  value={rowData.points}
                  displayType={'text'}
                  thousandSeparator
                />
              ),
              align: 'right',
            },
            { title: 'Status', field: 'status' },
          ]}
          data={redemptions}
          options={{
            actionsColumnIndex: -1,
            search: false,
            toolbar: false,
          }}
          icons={{ Filter: SearchIcon }}
          style={{ boxShadow: 'none' }}
        />
      </GridItem>
    </GridContainer>
  );
};

export default Redemptions;
