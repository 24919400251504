"use strict";
/**
 * RecurrentType and Type are directly correlated.
 * - normal -> refill
 * - carry_over -> deposit
 *
 * Type has NOT_SET which is for when there is no enabled record.
 * NOT_SET does not have a direct correlation to RecurrentType.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutoPointsRecurrentType = void 0;
var AutoPointsRecurrentType;
(function (AutoPointsRecurrentType) {
    AutoPointsRecurrentType["REFILL"] = "normal";
    AutoPointsRecurrentType["DEPOSIT"] = "carry_over";
})(AutoPointsRecurrentType || (exports.AutoPointsRecurrentType = AutoPointsRecurrentType = {}));
