"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcceptedFileTypeAttachment = void 0;
var AcceptedFileTypeAttachment;
(function (AcceptedFileTypeAttachment) {
    AcceptedFileTypeAttachment["CSV"] = "csv";
    AcceptedFileTypeAttachment["JPG"] = "jpg";
    AcceptedFileTypeAttachment["JPEG"] = "jpeg";
    AcceptedFileTypeAttachment["M4V"] = "m4v";
    AcceptedFileTypeAttachment["MOV"] = "mov";
    AcceptedFileTypeAttachment["MP4"] = "mp4";
    AcceptedFileTypeAttachment["MPEG"] = "mpeg";
    AcceptedFileTypeAttachment["MPG"] = "mpg";
    AcceptedFileTypeAttachment["PDF"] = "pdf";
    AcceptedFileTypeAttachment["PNG"] = "png";
    AcceptedFileTypeAttachment["QT"] = "qt";
    AcceptedFileTypeAttachment["RAR"] = "rar";
    AcceptedFileTypeAttachment["XLS"] = "xls";
    AcceptedFileTypeAttachment["XLSX"] = "xlsx";
    AcceptedFileTypeAttachment["ZIP"] = "zip";
})(AcceptedFileTypeAttachment || (exports.AcceptedFileTypeAttachment = AcceptedFileTypeAttachment = {}));
