import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

function PackagePicker({
  packageOptions,
  selectedPackageKey,
  setSelectedPackageKey,
  contractTermOptions,
  selectedContractTermTypeId,
  setSelectedContractTermTypeId,
  paymentFrequencyOptions,
  selectedPaymentFrequencyKey,
  setSelectedPaymentFrequencyKey,
}) {
  return (
    <Paper variant="outlined">
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="package-picker" variant="outlined">
                Package
              </InputLabel>
              <Select
                labelId="package-picker"
                value={selectedPackageKey || ''}
                label="Package"
                onChange={(event) => {
                  setSelectedPackageKey(event.target.value);
                }}
                variant="outlined"
              >
                {Object.keys(packageOptions).map((packageOptionKey) => {
                  return (
                    <MenuItem key={packageOptionKey} value={packageOptionKey}>
                      {packageOptions[packageOptionKey][0].language.en_ca}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={setSelectedPaymentFrequencyKey ? 6 : 12}>
            {!!contractTermOptions?.length && (
              <FormControl fullWidth>
                <InputLabel id="contract-term-picker" variant="outlined">
                  Contract term
                </InputLabel>
                <Select
                  labelId="contract-term-picker"
                  value={selectedContractTermTypeId || ''}
                  label="Licence Term"
                  onChange={(event) => {
                    setSelectedContractTermTypeId(event.target.value);
                  }}
                  variant="outlined"
                >
                  {contractTermOptions.map((contractTermOption) => {
                    return (
                      <MenuItem
                        key={contractTermOption.typeId}
                        value={contractTermOption.typeId}
                      >
                        {contractTermOption.length}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          </Grid>
          {!!setSelectedPaymentFrequencyKey && (
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="payment-frequency-picker" variant="outlined">
                  Payment frequency
                </InputLabel>
                <Select
                  labelId="payment-frequency-picker"
                  value={selectedPaymentFrequencyKey || ''}
                  label="Payment frequency"
                  onChange={(event) => {
                    setSelectedPaymentFrequencyKey(event.target.value);
                  }}
                  variant="outlined"
                >
                  {Object.keys(paymentFrequencyOptions).map(
                    (paymentFrequencyOptionKey) => {
                      return (
                        <MenuItem
                          key={paymentFrequencyOptionKey}
                          value={paymentFrequencyOptionKey}
                        >
                          {paymentFrequencyOptions[paymentFrequencyOptionKey]}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Box>
    </Paper>
  );
}

export default PackagePicker;
