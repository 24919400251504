/**
 *  "newCustomPricePerLicence" is the number when user types in the input field (price per licence)
 *  Prorated price (price per day) needs to be updated based on the new value.
 *  If "proratedPrice" and "newCustomPricePerlicence" are the same, returned value is the same as "proratedPrice".
 */
function getNewProratedPrice(selectedPackage, newCustomPricePerLicence) {
  if (!selectedPackage) return 0;
  const { proratedPrice, pricePerLicence } = selectedPackage;

  const licenceRatePercentage = proratedPrice / pricePerLicence;
  const newProratedPrice = newCustomPricePerLicence * licenceRatePercentage;

  return +newProratedPrice.toFixed(2);
}

export default getNewProratedPrice;
