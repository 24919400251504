import React from 'react';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

const useStyles = makeStyles(styles);

export default ({ label, children }) => {
  const classes = useStyles();
  return (
    <>
      <GridItem xs={12} sm={3}>
        <FormLabel className={classes.labelHorizontal}>{label}</FormLabel>
      </GridItem>
      <GridItem xs={12} sm={9}>
        {children}
      </GridItem>
    </>
  );
};
