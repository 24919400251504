import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Typography from '@material-ui/core/Typography';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';

import styles from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
const useStyles = makeStyles(styles);

const Filters = ({ onChange }) => {
  const debounceTimerRef = React.useRef();

  const classes = useStyles();
  const [organizationsSearchQuery, setOrganizationsSearchQuery] =
    React.useState('');
  const [usersSearchQuery, setUsersSearchQuery] = React.useState('');
  const [showSandboxesOnly, setShowSandboxesOnly] = React.useState(false);

  React.useEffect(() => {
    clearTimeout(debounceTimerRef.current);

    debounceTimerRef.current = setTimeout(() => {
      onChange({
        organizationsSearchQuery,
        usersSearchQuery,
        showSandboxesOnly,
      });
    }, 300);

    return () => {
      clearTimeout(debounceTimerRef.current);
    };
  }, [onChange, organizationsSearchQuery, usersSearchQuery, showSandboxesOnly]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <h4 className={classes.cardIconTitle}>Search</h4>
              <form
                onSubmit={(event) => {
                  event.preventDefault();

                  onChange({
                    organizationsSearchQuery,
                    usersSearchQuery,
                    showSandboxesOnly,
                  });
                }}
              >
                <GridContainer alignItems="center">
                  <GridItem xs={12} sm={12} md={5}>
                    <CustomInput
                      labelText="Organization"
                      id="organization"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                        value: organizationsSearchQuery,
                        onChange: (event) => {
                          setOrganizationsSearchQuery(event.target.value);
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Users"
                      id="users"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                        value: usersSearchQuery,
                        onChange: (event) => {
                          setUsersSearchQuery(event.target.value);
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="sandboxCheckbox"
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          checked={showSandboxesOnly}
                          onChangeCapture={() =>
                            setShowSandboxesOnly(!showSandboxesOnly)
                          }
                        />
                      }
                      label={
                        <Typography variant="body2">
                          Show sandbox only
                        </Typography>
                      }
                    />
                  </GridItem>
                </GridContainer>
                {/* This input is simply to allow the form to submit on enter */}
                <input type="submit" style={{ display: 'none' }} />
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Filters;
