import React, { useState } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CachedIcon from '@material-ui/icons/Cached';
// @material-ui/icons
import Person from '@material-ui/icons/Person';

// core components
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

// Custom form elements
import LabelWrapper from 'components/Applauz/FormElements/LabelWrapper';
import Textbox from 'components/Applauz/FormElements/Textbox';

import { getRandomPassword } from 'helpers/api/api';

const useStyles = makeStyles(styles);

const OwnerForm = ({ owner = {}, setCompany, formError }) => {
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  const [passwordError, setPasswordError] = useState(false);

  const setRandomPassword = () => {
    setPasswordError(false);
    getRandomPassword()
      .then(({ success, randomString }) => {
        if (success) setCompany('owner', randomString, 'password');
        else setPasswordError('Could not generate random password');
      })
      .catch((getRandomPasswordError) => {
        setPasswordError('Could not generate random password');
        console.log(getRandomPasswordError({ getRandomPasswordError }));
      });
  };

  return (
    <Card>
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <Person />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>Owner</h4>
      </CardHeader>
      <CardBody>
        <form>
          <GridContainer>
            <LabelWrapper label="First Name">
              <Textbox
                value={owner.firstName}
                onChange={(value) => setCompany('owner', value, 'firstName')}
              />
            </LabelWrapper>
            <LabelWrapper label="Last Name">
              <Textbox
                value={owner.lastName}
                onChange={(value) => setCompany('owner', value, 'lastName')}
              />
            </LabelWrapper>
            <LabelWrapper label="Email">
              <Textbox
                value={owner.email}
                onChange={(value) => setCompany('owner', value, 'email')}
              />
              {formError.email && (
                <span style={{ color: 'red' }}>{formError.email}</span>
              )}
            </LabelWrapper>
            <LabelWrapper label="Password">
              <Textbox
                value={owner.password}
                onChange={(value) => setCompany('owner', value, 'password')}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setRandomPassword()}
                    >
                      <CachedIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
              {passwordError && (
                <span style={{ color: 'red' }}>{passwordError}</span>
              )}
            </LabelWrapper>
          </GridContainer>
        </form>
      </CardBody>
    </Card>
  );
};

export default OwnerForm;
