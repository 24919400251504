import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import MaterialTable from 'material-table';

// @material-ui/icons
import SearchIcon from '@material-ui/icons/Search';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Loading from 'components/Loading/Loading';
import SnackbarContent from 'components/Snackbar/SnackbarContent';
import Modal from 'components/Modal/Modal';
// Applauz components
import Roles from 'components/Applauz/Roles';

// context
import { useAuth } from 'context/useAuth';

// api
import { getSuperAdminUsers, removeUser } from 'helpers/api/users';
import { getSuperAdminRoles } from 'helpers/api/auth';

const Homepage = () => {
  const navigate = useNavigate();
  const { auth: { user: { userID } } = {} } = useAuth();

  const [users, setUsers] = useState([]);
  const [error, setError] = useState(false);
  const [saRoles, setSaRoles] = useState([]);

  const [selectedUser, setSelectedUser] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [message, setMessage] = useState(false);

  useEffect(() => {
    getSuperAdminUsers()
      .then(({ success, users }) => {
        if (success) setUsers(users);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });

    getSuperAdminRoles().then((roles) => {
      setSaRoles(roles.filter(({ isAdmin }) => isAdmin));
    });
  }, []);

  const rows = useMemo(
    () =>
      users.map(
        ({ userID, name, email, imageSrc, dateAdded, roles }, index) => ({
          key: index,
          userID,
          name,
          email,
          imageSrc,
          dateAdded,
          roles,
          renderRoles: <Roles data={roles} />,
        })
      ),
    [users]
  );

  const removeUserFromAdmin = () => {
    if (!selectedUser) return;
    // proceed to optimistic delete
    setUsers(users.filter((user) => user.userID !== selectedUser));
    setVisible(false);
    removeUser(selectedUser)
      .then(({ success, name }) => {
        if (success)
          setMessage({
            text: `SuccessFully Removed User ${name}`,
            color: 'success',
          });
        else
          setMessage({
            text: `Could not remove the user!!!! Try again later.`,
            color: 'danger',
          });
      })
      .catch(() => {
        setMessage({
          text: 'Could not remove the user!!!! Try again later.',
          color: 'danger',
        });
      });

    setSelectedUser(false);
  };

  return (
    <GridContainer>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        cancelText="No"
        confirmText="Yes"
        onConfirm={removeUserFromAdmin}
        onClickCloseButton={() => setVisible(false)}
        body={modalBody}
        title={'Delete User'}
      />
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon />
          <CardBody>
            {message && (
              <SnackbarContent message={message.text} color={message.color} />
            )}
            <GridContainer>
              {error ? (
                <GridItem>
                  <h3>Sorry we could not get the users!!!</h3>
                </GridItem>
              ) : rows.length > 0 ? (
                <GridItem xs={12}>
                  <MaterialTable
                    title="Super Admin Users"
                    columns={[
                      {
                        title: 'Name',
                        field: 'name',
                        render: (rowData) => (
                          <div>
                            <img
                              src={rowData.imageSrc}
                              style={{
                                width: 40,
                                borderRadius: '50%',
                                marginRight: 10,
                              }}
                            />
                            <span>{rowData.name}</span>
                          </div>
                        ),
                      },
                      { title: 'Email', field: 'email' },
                      {
                        title: 'Date Added',
                        field: 'dateAdded',
                        defaultSort: 'desc',
                      },
                      {
                        title: 'Roles',
                        field: 'renderRoles',
                        customFilterAndSearch: (term, rowData) => {
                          return (
                            rowData.roles.filter((role) =>
                              role.toLowerCase().includes(term.toLowerCase())
                            ).length > 0
                          );
                        },
                        // lookup: saRoles.reduce(
                        //   (acc, { groupID, name }) => ({
                        //     ...acc,
                        //     [groupID]: name,
                        //   }),
                        //   {}
                        // ),
                        // saRoles.map(({ name }) => name),
                        /**
                         * saRoles.reduce(
                          (acc, { groupID, name }) => ({
                            ...acc,
                            [groupID]: name,
                          }),
                          {}
                        ),
                         */
                      },
                    ]}
                    data={rows}
                    actions={[
                      {
                        icon: 'add',
                        tooltip: 'Add User',
                        isFreeAction: true,
                        onClick: (event) => navigate('/admin/users/add'),
                      },
                      (rowData) => ({
                        icon: 'edit',
                        tooltip: 'Edit User',
                        disabled: rowData.userID === userID,
                        onClick: (event, { userID }) => {
                          navigate(`/admin/users/edit/${userID}`);
                        },
                      }),
                      (rowData) => ({
                        icon: 'delete',
                        tooltip: 'Delete User',
                        disabled: rowData.userID === userID,
                        onClick: (event, rowData) => {
                          setSelectedUser(rowData.userID);
                          setModalBody(
                            `Are you sure you want to delete ${rowData.name} ?`
                          );
                          setVisible(true);
                        },
                      }),
                    ]}
                    options={{
                      actionsColumnIndex: -1,
                      filtering: true,
                      // search: false,
                    }}
                    icons={{ Filter: SearchIcon }}
                  />
                </GridItem>
              ) : (
                <GridItem
                  xs={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Loading />
                </GridItem>
              )}
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Homepage;
