import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { getAccounts } from 'helpers/api/accounts';

// @material-ui/data-grid
import { DataGrid } from '@material-ui/data-grid';
import useGridColumns from './useGridColumns';

// applauz components
import Button from 'components/CustomButtons/Button';
import Filters from './Filters';

export default function Homepage() {
  const navigate = useNavigate();

  const [sortModel, setSortModel] = React.useState([
    { field: 'organizationId', sort: 'desc' },
  ]);
  const columns = useGridColumns(navigate);

  const [filters, setFilters] = React.useState({
    organizationsSearchQuery: '',
    usersSearchQuery: '',
    showSandboxesOnly: false,
  });

  const { isLoading, data: accounts } = useQuery(['accounts', filters], () =>
    getAccounts({
      organizations: filters.organizationsSearchQuery,
      users: filters.usersSearchQuery,
      showSandboxesOnly: filters.showSandboxesOnly,
    })
  );

  return (
    <>
      <Button
        style={{ width: '10%' }}
        color="info"
        onClick={() => navigate('/admin/accounts/add')}
      >
        {'Add Account'}
      </Button>
      <Filters onChange={setFilters} />
      <DataGrid
        loading={isLoading}
        rowCount={accounts?.length || 0}
        rows={accounts || []}
        getRowId={(row) => row.organizationId}
        columns={columns}
        hideFooterPagination={isLoading}
        pageSize={20}
        rowHeight={100}
        autoHeight
        sortingOrder={['asc', 'desc']}
        onSortModelChange={(model) => {
          if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
            setSortModel(model);
          }
        }}
        sortModel={sortModel}
      />
    </>
  );
}
