import qs from 'querystring-es3';

// variables
import { apiURL } from 'variables/config';

const endpoints = JSON.parse(localStorage.getItem('endpoints')) || {
  api: apiURL,
};

const prepareUrlAndOptions = (url, optionsPrepared = {}) => {
  let urlPrepared;
  if (url.startsWith('as://')) {
    urlPrepared = url.replace(
      /^(?:as?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/gim,
      (match) => {
        const part = match.replace('as://', '');
        if (endpoints[part]) return endpoints[part].replace(/[/\\]$/i, '');
        return match;
      }
    );
  } else {
    urlPrepared = url;
  }

  const auth = JSON.parse(localStorage.getItem('@super-admin-auth-v2'));
  const accessToken = auth && auth.accessToken;

  const { headers = {} } = optionsPrepared;

  return {
    urlPrepared,
    optionsPrepared: {
      ...optionsPrepared,
      headers: {
        ...headers,
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  };
};

export const getRequestUrl = (url) => prepareUrlAndOptions(url).urlPrepared;

const fetchResponseType = {
  text: (response) => response.text(),
  json: (response) => response.json(),
  blob: (response) => response.blob(),
  arrayBuffer: (response) => response.arrayBuffer(),
  formData: (response) => response.formData(),
};

export const get = (url, { params, responseType = null, ...options } = {}) => {
  const { urlPrepared, optionsPrepared } = prepareUrlAndOptions(url, options);

  const fetchRequestInfo = `${urlPrepared}${
    params ? `?${qs.stringify(params)}` : ''
  }`;

  return fetch(fetchRequestInfo, optionsPrepared)
    .then(async (res) => ({
      status: res.status,
      data: responseType
        ? fetchResponseType[`${responseType}`](res)
        : await res.json(),
    }))
    .then(({ status, data }) => {
      if (status !== 200) {
        throw new Error(`${data.type}: ${data.message}`);
      }
      return data;
    });
};

export const post = (url, { params = {}, ...options } = {}) => {
  const { urlPrepared, optionsPrepared } = prepareUrlAndOptions(url, options);

  return fetch(urlPrepared, {
    method: 'POST',
    body: JSON.stringify(params),
    ...optionsPrepared,
  })
    .then(async (res) => ({ status: res.status, data: await res.json() }))
    .then(({ status, data }) => {
      if (status !== 200) {
        throw new Error(`${data.type}: ${data.message}`);
      }
      return data;
    });
};

export const patch = (url, { params = {}, ...options } = {}) =>
  post(url, { params, ...options, method: 'PATCH' });

export default { get, post, patch };
