import { get, post } from '.';

export const getModules = (companyId) =>
  get(`${process.env.REACT_APP_HUB_SERVER_URL}/organization/getModules`, {
    params: { companyId },
  });

export const updateModules = (companyId, updatedActiveIds) =>
  post(`${process.env.REACT_APP_HUB_SERVER_URL}/organization/updateModules`, {
    params: { companyId, updatedActiveIds },
  });
