"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationEvent = void 0;
var NotificationEvent;
(function (NotificationEvent) {
    NotificationEvent[NotificationEvent["NEW_RECOGNITION"] = 1022] = "NEW_RECOGNITION";
    NotificationEvent[NotificationEvent["ADD_AWARD_TO_RECOGNITION"] = 1023] = "ADD_AWARD_TO_RECOGNITION";
    NotificationEvent[NotificationEvent["NEW_POST"] = 1024] = "NEW_POST";
    NotificationEvent[NotificationEvent["NEW_COMMENT"] = 1025] = "NEW_COMMENT";
    NotificationEvent[NotificationEvent["COMMENT_MY_RECOGNITION"] = 1026] = "COMMENT_MY_RECOGNITION";
    NotificationEvent[NotificationEvent["COMMENT_MY_GOAL"] = 1027] = "COMMENT_MY_GOAL";
    NotificationEvent[NotificationEvent["BIRTHDAY"] = 1033] = "BIRTHDAY";
    NotificationEvent[NotificationEvent["MILESTONES_WORK_ANNIVERSARY_RECOGNITION"] = 1034] = "MILESTONES_WORK_ANNIVERSARY_RECOGNITION";
    NotificationEvent[NotificationEvent["WORK_ANNIVERSARY_RECOGNITION"] = 1035] = "WORK_ANNIVERSARY_RECOGNITION";
})(NotificationEvent || (exports.NotificationEvent = NotificationEvent = {}));
