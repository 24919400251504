import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// @material-ui/icons
import Business from '@material-ui/icons/Business';

// core components
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

// Custom form elements
import LabelWrapper from 'components/Applauz/FormElements/LabelWrapper';
import Textbox from 'components/Applauz/FormElements/Textbox';

const useStyles = makeStyles(styles);

const GeneralForm = ({ company = {}, setCompany, formError, isSandbox }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <Business />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>Company</h4>
      </CardHeader>
      <CardBody>
        <form>
          <GridContainer>
            <LabelWrapper label="Company Name">
              <Textbox
                value={company.company_name}
                onChange={(value) => setCompany('company_name', value)}
              />
              {formError.company_name && (
                <span style={{ color: 'red' }}>{formError.company_name}</span>
              )}
            </LabelWrapper>
            <LabelWrapper label="Hubspot Company ID">
              <Textbox
                value={company.hubspotCompanyID}
                onChange={(value) => setCompany('hubspotCompanyID', value)}
              />
              {formError.hubspotCompanyID && (
                <span style={{ color: 'red' }}>
                  {formError.hubspotCompanyID}
                </span>
              )}
            </LabelWrapper>
            <LabelWrapper label="Xero Contact ID">
              <Textbox
                value={company.xeroContactID}
                onChange={(value) => setCompany('xeroContactID', value)}
              />
              {formError.xeroContactID && (
                <span style={{ color: 'red' }}>{formError.xeroContactID}</span>
              )}
            </LabelWrapper>

            {isSandbox && (
              <LabelWrapper width="100%">
                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="block"
                  align="right"
                >
                  *This is sandbox account
                </Typography>
              </LabelWrapper>
            )}
          </GridContainer>
        </form>
      </CardBody>
    </Card>
  );
};

export default GeneralForm;
