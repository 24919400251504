import _ from 'lodash';

function getSubscriptionTotalDiscounts({
  pricePerLicence,
  licencesCount,
  autoDiscounts,
  customDiscounts,
  proratedCredit,
}) {
  return _.sum([
    ...autoDiscounts.map(
      ({ value }) => value * pricePerLicence * licencesCount
    ),
    ...customDiscounts.list.map(
      ({ value }) => value * pricePerLicence * licencesCount
    ),
    proratedCredit || 0,
  ]);
}

export default getSubscriptionTotalDiscounts;
