import React from 'react';
import { useQuery } from 'react-query';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/icons
import ShoppingCart from '@material-ui/icons/ShoppingCart';

// core components
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import Typography from '@material-ui/core/Typography';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import { getSuppliers, getGRSCatalogs } from 'helpers/api/catalog';

import { GRSCatalogType } from '@applauz/enums';

const useStyles = makeStyles(styles);

const CatalogForm = ({
  countryId,
  selectedSupplierIds,
  setSelectedSupplierIds,
  selectedGRSCatalogId,
  setSelectedGRSCatalogId,
  selectedGRSCatalogIdSA,
  setSelectedGRSCatalogIdSA,
  isSandbox,
}) => {
  const classes = useStyles();

  const { data: supplierOptions } = useQuery(
    ['supplierOptions', countryId],
    () => getSuppliers(countryId)
  );

  const { data: grsCatalogOptions } = useQuery(
    ['grsCatalogOptions', countryId, GRSCatalogType.POINTS],
    () => getGRSCatalogs(countryId, GRSCatalogType.POINTS)
  );

  const { data: grsCatalogOptionsSA } = useQuery(
    ['grsCatalogOptions', countryId, GRSCatalogType.SERVICE_AWARDS],
    () => getGRSCatalogs(countryId, GRSCatalogType.SERVICE_AWARDS)
  );

  const filteredGRSCatalogOptions = React.useMemo(() => {
    if (!grsCatalogOptions?.length) return [];
    return grsCatalogOptions.filter((option) =>
      option.name.includes('Applauz Sandbox') ? isSandbox : !isSandbox
    );
  }, [isSandbox, grsCatalogOptions]);

  const filteredGRSCatalogOptionsSA = React.useMemo(() => {
    if (!grsCatalogOptionsSA?.length) return [];
    return grsCatalogOptionsSA.filter((option) =>
      option.name.includes('Applauz Sandbox') ? isSandbox : !isSandbox
    );
  }, [isSandbox, grsCatalogOptionsSA]);

  const handleSetSelectedSupplierIds = (changedSupplierId) => {
    setSelectedSupplierIds((prev) => {
      if (prev.includes(changedSupplierId)) {
        return prev.filter((supplierId) => supplierId !== changedSupplierId);
      }
      return [...prev, changedSupplierId];
    });
  };

  const handleSetSelectedGRSCatalogId = (event) => {
    setSelectedGRSCatalogId(event.target.value);
  };

  const handleSetSelectedGRSCatalogIdSA = (event) => {
    setSelectedGRSCatalogIdSA(event.target.value);
  };

  React.useEffect(() => {
    if (!isSandbox || !grsCatalogOptions?.length) return;
    if (isSandbox) {
      setSelectedSupplierIds([]);
      const sandboxCatalogId = grsCatalogOptions.find((option) =>
        option.name.includes('Applauz Sandbox') ? isSandbox : !isSandbox
      )?.grsCatalogId;
      setSelectedGRSCatalogId(
        (sandboxCatalogId === selectedGRSCatalogId && selectedGRSCatalogId)
          || ''
      );
    }
  }, [
    isSandbox,
    setSelectedGRSCatalogId,
    setSelectedSupplierIds,
    grsCatalogOptions,
    selectedGRSCatalogId,
  ]);

  return (
    <Card>
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <ShoppingCart />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>Marketplace Configuration</h4>
      </CardHeader>
      <CardBody>
        <form autoComplete="off">
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="grs-catalog-select-label">
                  GRS Program - Points
                </InputLabel>
                <Select
                  labelId="grs-catalog-select-label"
                  value={selectedGRSCatalogId || ''}
                  label="GRS Catalog"
                  onChange={handleSetSelectedGRSCatalogId}
                  placeholder=""
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {(filteredGRSCatalogOptions || []).map((option) => {
                    return (
                      <MenuItem
                        key={option.grsCatalogId}
                        value={option.grsCatalogId}
                      >
                        {option.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="column" spacing={4}>
            <Grid item>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="grs-catalog-sa-select-label">
                  GRS Program - Service Awards
                </InputLabel>
                <Select
                  labelId="grs-catalog-sa-select-label"
                  value={selectedGRSCatalogIdSA || ''}
                  label="GRS Catalog"
                  onChange={handleSetSelectedGRSCatalogIdSA}
                  placeholder=""
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {(filteredGRSCatalogOptionsSA || []).map((option) => {
                    return (
                      <MenuItem
                        key={option.grsCatalogId}
                        value={option.grsCatalogId}
                      >
                        {option.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl component="fieldset" variant="standard" fullWidth>
                <FormLabel>
                  Non GRS marketplace suppliers (SA-only) {isSandbox && <br />}
                  <Typography variant="caption" color="info">
                    {!isSandbox
                      ? ''
                      : ' *This setting is disabled for sandbox.'}
                  </Typography>
                </FormLabel>
                <FormGroup>
                  {(supplierOptions || []).map((supplier) => {
                    return (
                      <FormControlLabel
                        key={supplier.supplier_id}
                        value={supplier.supplier_id}
                        checked={selectedSupplierIds.includes(
                          supplier.supplier_id
                        )}
                        control={<Checkbox />}
                        label={supplier.name}
                        onChange={() => {
                          handleSetSelectedSupplierIds(supplier.supplier_id);
                        }}
                        disabled={isSandbox}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};

export default CatalogForm;
