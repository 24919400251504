import React, { useMemo } from 'react';
import { decode } from 'html-entities';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/icons
import MailOutline from '@material-ui/icons/MailOutline';

// core components
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

// Custom form elements
import LabelWrapper from 'components/Applauz/FormElements/LabelWrapper';
import Textbox from 'components/Applauz/FormElements/Textbox';
import CustomSelect from 'components/Applauz/FormElements/CustomSelect';

const useStyles = makeStyles(styles);

const AddressForm = ({ address = {}, setCompany, countries, formError }) => {
  const classes = useStyles();

  const provinces = useMemo(() => {
    const country = countries.find(
      ({ id }) => String(id) === String(address.country_id)
    );
    if (!country) {
      return [];
    }
    return country.provinces.map(({ id: value, name }) => ({
      value,
      name: decode(name),
    }));
  }, [address, countries]);

  return (
    <Card>
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <MailOutline />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>Address</h4>
      </CardHeader>
      <CardBody>
        <form>
          <GridContainer>
            <LabelWrapper label="Address Line 1">
              <Textbox
                value={address.address_1}
                onChange={(value) => setCompany('address', value, 'address_1')}
              />
            </LabelWrapper>
            <LabelWrapper label="Address Line 2">
              <Textbox
                value={address.address_2}
                onChange={(value) => setCompany('address', value, 'address_2')}
              />
            </LabelWrapper>
            <LabelWrapper label="Phone">
              <Textbox
                value={address.phone}
                onChange={(value) => setCompany('address', value, 'phone')}
              />
              {formError.postal_code && (
                <span style={{ color: 'red' }}>{formError.phone}</span>
              )}
            </LabelWrapper>
            <LabelWrapper label="City">
              <Textbox
                value={address.city}
                onChange={(value) => setCompany('address', value, 'city')}
              />
              {formError.city && (
                <span style={{ color: 'red' }}>{formError.city}</span>
              )}
            </LabelWrapper>
            <LabelWrapper label="Postal Code">
              <Textbox
                value={address.postal_code}
                onChange={(value) =>
                  setCompany('address', value, 'postal_code')
                }
              />
              {formError.postal_code && (
                <span style={{ color: 'red' }}>{formError.postal_code}</span>
              )}
            </LabelWrapper>
            <LabelWrapper label="Country">
              <CustomSelect
                placeholder="Select Country"
                containerStyle={{ width: '100%' }}
                options={countries.map(({ id: value, name }) => ({
                  value,
                  name,
                }))}
                value={address.country_id}
                onChange={(value) => setCompany('address', value, 'country_id')}
              />
            </LabelWrapper>

            <LabelWrapper label="Province">
              <CustomSelect
                disabled={!address.country_id || address.country_id === ''}
                placeholder="Select Province"
                containerStyle={{ width: '100%' }}
                options={provinces}
                value={address.province_id}
                onChange={(value) =>
                  setCompany('address', value, 'province_id')
                }
              />
            </LabelWrapper>
          </GridContainer>
        </form>
      </CardBody>
    </Card>
  );
};

export default AddressForm;
