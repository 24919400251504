"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionEventType = void 0;
var TransactionEventType;
(function (TransactionEventType) {
    TransactionEventType[TransactionEventType["RECOGNITION"] = 1] = "RECOGNITION";
    TransactionEventType[TransactionEventType["AUGMENTED_AWARD"] = 2] = "AUGMENTED_AWARD";
    TransactionEventType[TransactionEventType["GOAL"] = 3] = "GOAL";
    TransactionEventType[TransactionEventType["PULSE_SURVEY"] = 4] = "PULSE_SURVEY";
    TransactionEventType[TransactionEventType["ANNIVERSARY"] = 5] = "ANNIVERSARY";
    TransactionEventType[TransactionEventType["BIRTHDAY"] = 6] = "BIRTHDAY";
    TransactionEventType[TransactionEventType["ONBOARDING"] = 7] = "ONBOARDING";
    TransactionEventType[TransactionEventType["REDEMPTION_MARKETPLACE"] = 8] = "REDEMPTION_MARKETPLACE";
    TransactionEventType[TransactionEventType["REDEMPTION_PERK"] = 9] = "REDEMPTION_PERK";
    TransactionEventType[TransactionEventType["CREDIT"] = 10] = "CREDIT";
    TransactionEventType[TransactionEventType["REFUND"] = 11] = "REFUND";
    TransactionEventType[TransactionEventType["PURCHASE_POINTS_BANK"] = 12] = "PURCHASE_POINTS_BANK";
    TransactionEventType[TransactionEventType["PURCHASE_TOP_UP"] = 13] = "PURCHASE_TOP_UP";
    TransactionEventType[TransactionEventType["ALLOWANCE_ALLOCATION_MANUAL"] = 14] = "ALLOWANCE_ALLOCATION_MANUAL";
    TransactionEventType[TransactionEventType["ALLOWANCE_ALLOCATION_AUTO"] = 15] = "ALLOWANCE_ALLOCATION_AUTO";
    TransactionEventType[TransactionEventType["DEFAULT_POINTS"] = -1] = "DEFAULT_POINTS";
    TransactionEventType[TransactionEventType["DEFAULT_ALLOWANCE"] = -2] = "DEFAULT_ALLOWANCE";
})(TransactionEventType || (exports.TransactionEventType = TransactionEventType = {}));
