import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { getToken, getPermissions, logout } from '../helpers/auth';
import cookie from 'js-cookie';

const AuthContext = createContext();

const getNow = () => Math.floor(new Date().getTime() / 1000);

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(
    JSON.parse(localStorage.getItem('@super-admin-auth-v2')) || {}
  );

  const setAuthData = (data) => {
    localStorage.setItem('@super-admin-auth-v2', JSON.stringify(data));
    setAuth(data);
  };

  const logIn = useCallback((code) => {
    return getToken(code)
      .then((authData) => {
        const { accessToken, expiredIn } = authData;
        return {
          accessToken,
          expiredIn,
          expiresAt: getNow() + expiredIn,
        };
      })
      .then((authData) => {
        return getPermissions(authData)
          .then((permissions) => {
            if (!permissions['access:super.admin']) {
              throw new Error('Unauthorized');
            }
            // set the auth cookies
            cookie.set('ast', authData.accessToken, {
              expires: authData.expiresAt,
            });
            setAuthData({ ...authData, permissions });
            return { ...authData, permissions };
          })
          .catch(() => {
            throw new Error('Could not get permissions');
          });
      })
      .catch(() => {
        setAuthData({});
        throw new Error('Could not authenticate');
      });
  }, []);

  const logOut = useCallback(() => {
    setAuthData({});
    logout().then(() => {
      window.location.href = `${process.env.REACT_APP_AUTH_URL}/session/end?no-prompt`;
    });
  }, []);

  useEffect(() => {
    // periodically check to refresh access token in the background
    const intervalSeconds = 10;
    const interval = setInterval(() => {
      // add `intervalSeconds` to account for the token expiring before the next timeout
      if (auth.expiresAt <= getNow() + intervalSeconds * 2) {
        logIn().catch(() => logOut());
      }
    }, intervalSeconds * 1000);

    return () => clearInterval(interval);
  }, [auth, logIn, logOut]);

  return (
    <AuthContext.Provider value={{ auth, setAuth: setAuthData, logIn, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const { auth, setAuth, logIn, logOut } = useContext(AuthContext);
  const can = (scope) => {
    return !!auth.permissions[scope];
  };
  return { auth, setAuth, can, logIn, logOut };
}
