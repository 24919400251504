import React from 'react';
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';

import PackagePicker from './PackagePicker';
import formatDecimalPoint from './formatDecimalPoint';

function SelectedPackageRow({
  hasPaymentFrequencySelect,
  licencesCount,
  packagePicker,
  pricePerLicence,
  newProratedPricePerLicence,
  newEndDate,
  totalPrice,
  isApplyNow,
  //
  onLicenceCountChange,
  onPricePerLicenceChange,
}) {
  const formattedNewProratedPricePerLicence = formatDecimalPoint(
    newProratedPricePerLicence
  );

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <PackagePicker
            packageOptions={packagePicker.packageOptions}
            selectedPackageKey={packagePicker.selectedPackageKey}
            setSelectedPackageKey={packagePicker.setSelectedPackageKey}
            contractTermOptions={packagePicker.contractTermOptions}
            selectedContractTermTypeId={
              packagePicker.selectedContractTermTypeId
            }
            setSelectedContractTermTypeId={
              packagePicker.setSelectedContractTermTypeId
            }
            paymentFrequencyOptions={packagePicker.paymentFrequencyOptions}
            selectedPaymentFrequencyKey={
              packagePicker.selectedPaymentFrequencyKey
            }
            setSelectedPaymentFrequencyKey={
              hasPaymentFrequencySelect
                ? packagePicker.setSelectedPaymentFrequencyKey
                : undefined
            }
            isApplyNow={isApplyNow}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                label="Price per licence"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                onChangeCapture={(event) => {
                  const newValue = isNaN(event.target.value)
                    ? ''
                    : event.target.value;

                  onPricePerLicenceChange(newValue);
                }}
                onBlur={() => {
                  const newValue = parseFloat(pricePerLicence);
                  onPricePerLicenceChange(
                    (isNaN(newValue) ? 0 : newValue).toFixed(2)
                  );
                }}
                value={pricePerLicence}
                type="number"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                label="No. of licences"
                type="number"
                value={licencesCount}
                onChange={(event) => {
                  onLicenceCountChange(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                disabled
                label="Total"
                value={totalPrice}
              />
            </Grid>
          </Grid>
          {/** 
               Show the new term end date since the total amount is NOT based on price per licence x num of licences.
               it counts based on remaining days to the end of the new term (till the 1st of month). 
               The total is based on proratedPrice x num of licences.
          */}
          {isApplyNow && newEndDate && (
            <>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    <Box fontSize={15}>
                      <Box fontWeight={600} display="inline">
                        {`$${formattedNewProratedPricePerLicence} `}
                      </Box>
                      per licence for remaining days
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} justifyContent="end">
                <Grid item xs={12} justifyContent="end">
                  <Typography variant="body1">
                    <Box color="text.secondary" fontSize={13} mt={0.75}>
                      {`*The per licence fee ($${formattedNewProratedPricePerLicence}) is prorated based on the remaining days until the new term end (${newEndDate}).`}
                    </Box>
                    <Box color="text.secondary" fontSize={13} fontWeight={600}>
                      {`$${formatDecimalPoint(pricePerLicence)} ÷ ${
                        packagePicker?.selectedContractTerm?.totalDays
                      } total days × ${
                        packagePicker?.selectedContractTerm?.numberOfDaysLeft
                      } remaining days = $${formattedNewProratedPricePerLicence}/licence`}
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SelectedPackageRow;
