import moment from 'moment';

/**
 *  Prorated Credit = "daily cost of a single licence" x "number of days left in their term" x "number of licences"
 *  New contract/plan starts from today
 *  "end date" for the new plan needs to be the 1st of month (payment is always on the 1st of month)
 *  "downgrade" should not be allowed. downgrade: change to cheaper plan, decrease the number of licences, shorten the term(frequency)
 */

function getProratedCredit(
  nextPaymentDate,
  length,
  licencesCount,
  proratedCreditTotal
) {
  const proratedZeroCredit = {
    creditPerLicence: 0,
    creditTotal: 0,
    licencesCount,
  };
  if (!length) return proratedZeroCredit;

  const todayMoment = moment().startOf('day');
  const currentLicenceEndDate = moment(nextPaymentDate);

  // no credit
  // 1. if the licence has already expired
  // 2. if the licence is expiring today
  if (
    currentLicenceEndDate?.isBefore(todayMoment)
    || currentLicenceEndDate?.isSame(todayMoment)
  )
    return proratedZeroCredit;

  const remainingDays = currentLicenceEndDate.diff(todayMoment, 'days');
  const creditPerLicence = +proratedCreditTotal.toFixed(2);

  return {
    creditPerLicence,
    creditTotal: creditPerLicence * parseInt(licencesCount),
    licencesCount,
    licenceOriginalEndDate: currentLicenceEndDate.format('YYYY-MM-DD'),
    remainingDays,
  };
}

export default getProratedCredit;
