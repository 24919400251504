import React from 'react';
import MaterialTable from 'material-table';

export default function SubscriptionHistory({ subscriptions }) {
  return (
    <MaterialTable
      title="Licence history"
      columns={[
        { title: 'Type', field: 'type' },
        {
          title: 'Start Date',
          field: 'startDate',
          defaultSort: 'desc',
        },
        { title: 'End Date', field: 'endDate' },
        { title: 'Payment Date', field: 'paymentDate' },
        { title: 'Licences', field: 'licences', align: 'right' },
        { title: 'Status', field: 'status' },
      ]}
      data={subscriptions}
      options={{
        actionsColumnIndex: -1,
        toolbar: false,
      }}
      style={{ boxShadow: 'none' }}
    />
  );
}
