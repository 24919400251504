import moment from 'moment';

const getStatusString = (voucher) => {
  switch (voucher.status_id) {
    case 1:
      return 'Unpaid';
    case 2:
      return `Scheduled ${moment(voucher.celebration_date).format(
        'YYYY-MM-DD'
      )}`;
    case 3:
      return `Sent ${moment(voucher.dt_sent).format('YYYY-MM-DD')}`;
    case 4:
      return `Redeemed ${moment(voucher.dt_redeemed).format('YYYY-MM-DD')}`;
    case 5:
      return `Credited ${moment(voucher.dt_credited).format('YYYY-MM-DD')}`;
  }
};
export default (rawVouchers) =>
  rawVouchers.map((voucher) => {
    return {
      id: voucher.award_id,
      dateCreated: moment(voucher.ts_inserted).format('YYYY-MM-DD'),
      celebration: voucher.year
        ? `${voucher.year} year${voucher.year > 1 ? 's' : ''}`
        : 'Spot-gift',
      organization: voucher.organization_name,
      user: `${voucher.first_name} ${voucher.last_name}`,
      amount: voucher.amount,
      statusID: voucher.status_id,
      status: getStatusString(voucher),
      dtSent:
        voucher.dt_sent
        && moment(voucher.dt_sent).format('YYYY-MM-DD HH:mm:ss'),
      dateCelebration:
        voucher.celebration_date
        && moment(voucher.celebration_date).format('YYYY-MM-DD'),
      dtActivated:
        voucher.dt_activated
        && moment(voucher.dt_activated).format('YYYY-MM-DD HH:mm:ss'),
      dtDeactivated:
        voucher.dt_deactivated
        && moment(voucher.dt_deactivated).format('YYYY-MM-DD HH:mm:ss'),
    };
  });
