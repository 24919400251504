export default {
  id: 1,
  fullName: 'Jackie Chan',
  organization: 'Golden Harvest',
  imageSrc:
    'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/jackie-chan-news-photo-83389121-1567001252.jpg',
  email: 'superuser@applauz.me',
  dateAdded: '2020-05-04',
  roles: ['Super User'],
};
