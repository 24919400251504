"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentStatus = void 0;
var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus[PaymentStatus["PENDING"] = 1] = "PENDING";
    PaymentStatus[PaymentStatus["APPROVED"] = 2] = "APPROVED";
    PaymentStatus[PaymentStatus["DECLINED"] = 3] = "DECLINED";
    PaymentStatus[PaymentStatus["DUPLICATE"] = 4] = "DUPLICATE";
    PaymentStatus[PaymentStatus["DEMO"] = 5] = "DEMO";
})(PaymentStatus || (exports.PaymentStatus = PaymentStatus = {}));
