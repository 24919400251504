import React from 'react';

import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import CountUp from 'react-countup';

import { getGiveCreditEntities } from 'helpers/api/users';
import { giveCredits } from 'helpers/api/points';

const GiveCreditsPage = () => {
  // users and orgs from api
  const [giveCreditEntities, setGiveCreditEntities] = React.useState({
    organizations: [],
    users: [],
  });
  // type of entity you want to give credit to {name, list, label}
  const [giveCreditEntity, setGiveCreditEntity] = React.useState(
    getEntityData(giveCreditEntities)
  );
  // the selected id (organization or user id)
  const [selectedEntityID, setSelectedEntityID] = React.useState();
  const [points, setPoints] = React.useState(0);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [actionLogs, setActionLogs] = React.useState([]);

  React.useEffect(() => {
    getGiveCreditEntities()
      .then((res) => {
        setGiveCreditEntities(res);
        setGiveCreditEntity(getEntityData(res));
        console.log({ res });
      })
      .catch(({ getGiveCreditEntitiesError }) =>
        console.error({ getGiveCreditEntitiesError })
      );
  }, []);

  console.log({ selectedEntityID });
  console.log({ giveCreditEntity });
  console.log({ points });
  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <ToggleButtonGroup
            value={giveCreditEntity.name}
            exclusive
            onChange={(event, entity) => {
              setGiveCreditEntity(getEntityData(giveCreditEntities, entity));
            }}
          >
            <ToggleButton value="organization">Organization</ToggleButton>
            <ToggleButton value="user">Individual</ToggleButton>
          </ToggleButtonGroup>
        </GridItem>

        <GridItem xs={12} style={{ margin: 1 }}>
          <Autocomplete
            options={giveCreditEntity.list}
            onChange={(event, newValue) => {
              setSelectedEntityID(
                getSelectedEntity(giveCreditEntity, newValue)
              );
            }}
            getOptionLabel={(option) => `${option.name} <${option.email}>`}
            renderInput={(params) => (
              <TextField
                {...params}
                label={giveCreditEntity.label}
                variant="outlined"
              />
            )}
          />
        </GridItem>

        <GridItem xs={12}>
          <TextField
            value={points}
            onChange={(event) => setPoints(parseInt(event.target.value) || 0)}
            label="Points"
            variant="filled"
            type="number"
            inputProps={{ min: 0, step: 10 }}
          />
        </GridItem>

        <GridItem xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setDialogOpen(true)}
          >
            Give Credits
          </Button>
        </GridItem>
        {actionLogs.map((log) => (
          <GridItem xs={12}>
            {log.success ? (
              <>
                Success! {log.name} now has{' '}
                <CountUp start={log.previous} end={log.current} duration={5} />{' '}
                points. Before: {log.previous} After: {log.current}
              </>
            ) : (
              <>Failed to give credits {log.message}</>
            )}
          </GridItem>
        ))}
      </GridContainer>

      <Dialog open={dialogOpen}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>{points} points will be given.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              const name = getEntityNameByID(
                giveCreditEntities,
                selectedEntityID
              );
              giveCredits({ ...selectedEntityID, points })
                .then((res) => {
                  setDialogOpen(false);
                  setActionLogs([
                    ...actionLogs,
                    {
                      ...res,
                      name,
                    },
                  ]);
                })
                .catch((giveCreditsError) => {
                  console.error({ giveCreditsError });
                  setDialogOpen(false);
                  setActionLogs([...actionLogs, { success: false }]);
                });
            }}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GiveCreditsPage;

const getEntityData = (giveCreditEntities, entity = 'organization') => {
  if (entity === 'organization')
    return {
      name: entity,
      list: giveCreditEntities.organizations,
      label: 'Organizations',
    };
  return {
    name: entity,
    list: giveCreditEntities.users,
    label: 'Individuals',
  };
};

const getSelectedEntity = (entity, newValue) => {
  if (!newValue) return null;
  return {
    entityID: entity.name === 'organization' ? 2 : 4,
    id: newValue.id,
  };
};

const getEntityNameByID = (giveCreditEntities, selectedEntityID) => {
  const list =
    selectedEntityID.entityID === 4
      ? giveCreditEntities.users
      : giveCreditEntities.organizations;

  return list.find((row) => row.id === selectedEntityID.id).name;
};
