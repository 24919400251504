import { get, post } from '.';

export const getPreferences = (organizationId) =>
  get(
    `${process.env.REACT_APP_HUB_SERVER_URL}/preferences/getAdminPreferences`,
    { params: { organizationId } }
  ).then((preferences) => {
    console.log('got preferences', preferences);
    return preferences;
  });

export const setPreference = (organizationId, key, value) =>
  post(`${process.env.REACT_APP_HUB_SERVER_URL}/preferences/setPreference`, {
    params: { organizationId, key, value },
  }).then((preferences) => {
    console.log('got preferences', preferences);
    return preferences;
  });
