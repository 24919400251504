"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionStatus = void 0;
var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus[TransactionStatus["PENDING"] = 1] = "PENDING";
    TransactionStatus[TransactionStatus["APPROVED"] = 2] = "APPROVED";
    TransactionStatus[TransactionStatus["DECLINED"] = 3] = "DECLINED";
    TransactionStatus[TransactionStatus["DUPLICATE"] = 4] = "DUPLICATE";
    TransactionStatus[TransactionStatus["DEMO"] = 5] = "DEMO";
})(TransactionStatus || (exports.TransactionStatus = TransactionStatus = {}));
