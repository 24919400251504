import React, { useEffect, useState } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// @material-ui/icons
import Check from '@material-ui/icons/Check';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button';
import SnackbarContent from 'components/Snackbar/SnackbarContent';

import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

const useStyles = makeStyles(styles);

const Form = ({ user, onSubmit, roles }) => {
  const [checked, setChecked] = useState([24, 22]);

  const [message, setMessage] = useState(false);
  const [toAddUser, setToAddser] = useState({ email: '', roles: [] });
  const [editUser, setEditUser] = useState({ id: '', roles: [] });

  useEffect(() => {
    if (user && user.roles)
      setEditUser({
        id: user.userID,
        roles: roles
          .filter((role) => user.roles.includes(role.name))
          .map((role) => role.groupID),
      });
  }, [user, roles]);

  const handleOnCheck = (role) => {
    if (user) {
      setEditUser({
        ...editUser,
        roles: editUser.roles.includes(role.groupID)
          ? editUser.roles.filter((r) => r !== role.groupID)
          : [...editUser.roles, role.groupID],
      });
    } else {
      setToAddser({
        ...toAddUser,
        roles: toAddUser.roles.includes(role.groupID)
          ? toAddUser.roles.filter((r) => r !== role.groupID)
          : [...toAddUser.roles, role.groupID],
      });
    }
  };

  const classes = useStyles();

  return (
    <form>
      {!user ? (
        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              Email address
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={7}>
            <CustomInput
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'text',
                value: toAddUser.email,
                onChange: (event) =>
                  setToAddser({ ...toAddUser, email: event.target.value }),
              }}
              disabled
            />
          </GridItem>
          {/* <GridItem xs={12} sm={3}>
            <Button fullWidth color="info" type="button" onClick={() => {}}>
              Find user
            </Button>
          </GridItem> */}
        </GridContainer>
      ) : (
        <GridContainer direction="row" alignItems="flex-end">
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              Email address
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={10}>
            <div>{user.email}</div>
          </GridItem>
        </GridContainer>
      )}
      {!user && (
        <GridContainer>
          <GridItem xs={12} sm={12}>
            {message && (
              <SnackbarContent message={message.text} color={message.color} />
            )}
          </GridItem>
        </GridContainer>
      )}

      <GridContainer>
        <GridItem xs={12} sm={2}>
          <FormLabel
            className={
              `${classes.labelHorizontal
               } ${
               classes.labelHorizontalRadioCheckbox}`
            }
          >
            Roles
          </FormLabel>
        </GridItem>
        <GridItem xs={12} sm={10}>
          {roles.map((role, index) => {
            return (
              <div
                key={index}
                className={
                  `${classes.checkboxAndRadio
                   } ${
                   classes.checkboxAndRadioHorizontal}`
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleOnCheck(role)}
                      checked={
                        user ? editUser.roles.includes(role.groupID) : undefined
                      }
                      value={role.groupID}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label={role.name}
                />
              </div>
            );
          })}
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Button
            color="success"
            fullWidth
            onClick={() => onSubmit(user ? editUser : toAddUser, setMessage)}
            disabled={
              user
                ? false
                : toAddUser.email === '' || toAddUser.roles.length === 0
            }
          >
            {user ? 'Save User' : 'Add User'}
          </Button>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default <Form />;
