"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrencyCode = void 0;
var CurrencyCode;
(function (CurrencyCode) {
    CurrencyCode["CAD"] = "CAD";
    CurrencyCode["USD"] = "USD";
    CurrencyCode["GBP"] = "GBP";
    CurrencyCode["AUD"] = "AUD";
})(CurrencyCode || (exports.CurrencyCode = CurrencyCode = {}));
