"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RedeemedOrderStatus = void 0;
const OrderStatus_1 = require("./OrderStatus");
var RedeemedOrderStatus;
(function (RedeemedOrderStatus) {
    RedeemedOrderStatus[RedeemedOrderStatus["PENDING"] = OrderStatus_1.OrderStatus.PENDING] = "PENDING";
    RedeemedOrderStatus[RedeemedOrderStatus["PROCESSING"] = OrderStatus_1.OrderStatus.PROCESSING] = "PROCESSING";
    RedeemedOrderStatus[RedeemedOrderStatus["PROCESSED"] = OrderStatus_1.OrderStatus.PROCESSED] = "PROCESSED";
    RedeemedOrderStatus[RedeemedOrderStatus["SHIPPED"] = OrderStatus_1.OrderStatus.SHIPPED] = "SHIPPED";
    RedeemedOrderStatus[RedeemedOrderStatus["REVIEW"] = OrderStatus_1.OrderStatus.REVIEW] = "REVIEW";
    RedeemedOrderStatus[RedeemedOrderStatus["PICK_UP"] = OrderStatus_1.OrderStatus.PICK_UP] = "PICK_UP";
    RedeemedOrderStatus[RedeemedOrderStatus["PARTIALLY_SHIPPED"] = OrderStatus_1.OrderStatus.PARTIALLY_SHIPPED] = "PARTIALLY_SHIPPED";
    RedeemedOrderStatus[RedeemedOrderStatus["PROCESSING_RETURN"] = OrderStatus_1.OrderStatus.PROCESSING_RETURN] = "PROCESSING_RETURN";
})(RedeemedOrderStatus || (exports.RedeemedOrderStatus = RedeemedOrderStatus = {}));
