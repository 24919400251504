"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcceptedFileTypeImage = void 0;
var AcceptedFileTypeImage;
(function (AcceptedFileTypeImage) {
    AcceptedFileTypeImage["AVIF"] = "avif";
    AcceptedFileTypeImage["BMP"] = "bmp";
    AcceptedFileTypeImage["GIF"] = "gif";
    AcceptedFileTypeImage["HEIC"] = "heic";
    AcceptedFileTypeImage["HEIF"] = "heif";
    AcceptedFileTypeImage["JPEG"] = "jpeg";
    AcceptedFileTypeImage["JPG"] = "jpg";
    AcceptedFileTypeImage["PNG"] = "png";
    AcceptedFileTypeImage["WEBP"] = "webp";
})(AcceptedFileTypeImage || (exports.AcceptedFileTypeImage = AcceptedFileTypeImage = {}));
