import React from 'react';

const Dashboard = () => {
  return (
    <div style={{ width: '100%', height: 'inherit' }}>
      <iframe
        title="dashboard"
        width="1200"
        height="800"
        src="https://lookerstudio.google.com/embed/reporting/1Ljj-eO9pNoJsbOwQy6gkW_pmE9JHmC4X/page/lEex"
        frameBorder="0"
        style="border:0"
        allowfullscreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      />
    </div>
  );
};
export default Dashboard;
