"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Country = void 0;
var Country;
(function (Country) {
    Country[Country["CANADA"] = 38] = "CANADA";
    Country[Country["USA"] = 223] = "USA";
    Country[Country["UK"] = 222] = "UK";
    Country[Country["AUS"] = 13] = "AUS";
    Country[Country["ISO_2"] = 2] = "ISO_2";
    Country[Country["ISO_3"] = 3] = "ISO_3";
})(Country || (exports.Country = Country = {}));
