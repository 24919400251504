import React from 'react';
import moment from 'moment';
import { useQuery, useQueryClient } from 'react-query';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  AppBar,
  Tabs,
  Tab,
} from '@material-ui/core';
import Button from 'components/CustomButtons/Button';
import { validateDateRange } from 'helpers/lib/validation';
import { updatePrice, cancelPrice } from 'helpers/api/subscriptions';
import { getCurrentSubscription, getUpcomingPlan } from 'helpers/api/licences';
import { getAccounts, postOnboardingFee } from 'helpers/api/accounts';
import CurrentPlan from './CurrentPlan';
import SubscriptionHistory from './SubscriptionHistory';
import CustomPriceModal from './CustomPriceModal';

const generateOnboardingFee = (organizationId) => {
  const amount = prompt('Generate Onboarding Fee Invoice, amount:', '');
  if (isNaN(amount * 1) || amount <= 0) {
    alert('Invalid amount');
    return;
  }
  if (
    !confirm(
      `Confirmation: Generate Invoice $${Number(amount).toFixed(2)} Onboarding Fee?`
    )
  ) {
    return;
  }
  postOnboardingFee(organizationId, amount)
    .then((response) => {
      console.log(response);
      const url = `${process.env.REACT_APP_HUB_CLIENT_URL}/invoices?invoiceId=${response.invoiceId}`;
      prompt('Onboarding fee invoice URL: ', url);
      window.open(url, '_blank');
    })
    .catch((generateOnboardingFeeError) => {
      alert(generateOnboardingFeeError);
      console.log({ generateOnboardingFeeError });
    });
};

const Licences = ({
  account: {
    subscriptions,
    currentPlan,
    subscriptionTypes,
    id: organizationId,
  },
}) => {
  const [currentSubscription, setCurrentSubscription] = React.useState({});
  const [renewalEffectiveDate, setRenewalEffectiveDate] = React.useState();

  const { data: licencesRenewalData } = useQuery(
    ['nextRenewal', organizationId, renewalEffectiveDate],
    () => {
      if (!renewalEffectiveDate) {
        return {
          licencesCount: 0,
          pricePerLicence: 0,
        };
      }
      return getAccounts({
        organizations: organizationId,
        effectiveDate: moment(
          // When there's a plan change use the upcomingPlan startDate
          currentSubscription.dtNextBill || upcomingPlan.startDate
        )
          .clone()
          .add(12, 'seconds') // 12 seconds magical number, to simulate the delay the billing job will have when it runs on the first of the month, otherwise it would hit false positives on the custom prices expiring at 00:00:00
          .toISOString(),
      }).then((accountsResult) => {
        const organizationData = accountsResult?.find(
          (i) => i.organizationId === organizationId
        );
        return {
          licencesCount: organizationData?.licences?.[0].totalCount || 0,
          pricePerLicence: organizationData?.licences?.[0].pricePerLicence || 0,
        };
      });
    }
  );

  const { data: upcomingPlan } = useQuery(
    ['upcomingPlan', parseInt(currentPlan?.organization_id)],
    async () => {
      const upcomingPlanData = await getUpcomingPlan({
        organizationId: parseInt(currentPlan?.organization_id),
      });
      return upcomingPlanData;
    },
    { placeholderData: {} }
  );

  const { data } = useQuery(
    ['account.currentSubscription', organizationId],
    async () =>
      getCurrentSubscription({
        organizationId: parseInt(organizationId),
      }),
    { placeholderData: {} }
  );

  React.useEffect(() => {
    if (typeof data !== 'undefined') {
      setCurrentSubscription(data);
    }
  }, [data]);

  React.useEffect(() => {
    if (currentSubscription.dtNextBill) {
      setRenewalEffectiveDate(
        moment(currentSubscription.dtNextBill).format('YYYY-MM-DD')
      );
    }
    if (upcomingPlan.startDate) {
      setRenewalEffectiveDate(
        moment(upcomingPlan.startDate).format('YYYY-MM-DD')
      );
    }
  }, [currentSubscription.dtNextBill, upcomingPlan.startDate]);

  const queryClient = useQueryClient();
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [deletePriceModalIsOpen, setDeletePriceModalIsOpen] =
    React.useState(false);
  const [customPriceModalIsOpen, setCustomPriceModalIsOpen] =
    React.useState(false);
  const [selectedPrice, setSelectedPrice] = React.useState(null);

  const handleTabChange = (_, newIndex) => {
    setSelectedTabIndex(newIndex);
  };

  const [subscriptionPrice, setSubscriptionPrice] = React.useState({
    ppu: 0,
    type: 2,
    dt_start: '',
    dt_end: '',
  });

  const dateIsValid = React.useMemo(() => {
    return validateDateRange(
      subscriptionPrice.dt_start,
      subscriptionPrice.dt_end
    );
  }, [subscriptionPrice.dt_start, subscriptionPrice.dt_end]);

  const validateData = () => {
    const parsedPPU = parseFloat(subscriptionPrice.ppu);
    const ppuIsNumber = !Number.isNaN(parsedPPU) && parsedPPU >= 0;

    if (!dateIsValid) {
      console.error('The date range is invalid.');
      return false;
    }

    if (!ppuIsNumber) {
      console.error('The custom price is invalid.');
      return false;
    }

    return true;
  };

  const submitNewPrice = () => {
    const isValid = validateData();

    if (isValid) {
      setIsLoading(true);
      updatePrice({ organizationID: organizationId, ...subscriptionPrice })
        .then(() => {
          setIsLoading(false);
          queryClient.invalidateQueries('account');
          setCustomPriceModalIsOpen(false);
        })
        .catch((updatePriceError) => {
          console.error({ updatePriceError });
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Paper variant="outlined">
        <Grid container>
          <Grid item xs={12}>
            <AppBar position="static">
              <Tabs value={selectedTabIndex} onChange={handleTabChange}>
                {['Current Plan', 'Subscription History', 'Onboarding Fee'].map(
                  (tab) => (
                    <Tab key={tab} label={tab} />
                  )
                )}
              </Tabs>
            </AppBar>
          </Grid>
          <Grid item xs={12}>
            {
              [
                <Box p={2} key="current-plan">
                  <CurrentPlan
                    currentSubscription={currentSubscription}
                    currentPlan={currentPlan}
                    upcomingPlan={upcomingPlan}
                    renewalEffectiveDate={renewalEffectiveDate}
                    subscriptionTypes={subscriptionTypes}
                    isLoading={isLoading}
                    setSelectedPrice={setSelectedPrice}
                    setDeletePriceModalIsOpen={setDeletePriceModalIsOpen}
                    setCustomPriceModalIsOpen={setCustomPriceModalIsOpen}
                    licencesRenewalData={licencesRenewalData}
                    onEndOfTermCancel={() => {}}
                    onImmediateCancel={() => {}}
                  />
                </Box>,
                <Box key="subscription-history">
                  <SubscriptionHistory subscriptions={subscriptions} />
                </Box>,
                <Box key="onboarding-fee">
                  <Button
                    onClick={() => generateOnboardingFee(organizationId)}
                    color="primary"
                  >
                    Generate Onboarding Fee
                  </Button>
                </Box>,
              ][selectedTabIndex]
            }
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        open={deletePriceModalIsOpen}
        onClose={() => setDeletePriceModalIsOpen(false)}
      >
        <DialogTitle>Delete Price</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this price?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeletePriceModalIsOpen(false)}>
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={() => {
              setDeletePriceModalIsOpen(false);
              cancelPrice(selectedPrice).then((response) => {
                if (response.success) {
                  queryClient.invalidateQueries('account');
                }
              });
            }}
          >
            Delete Price
          </Button>
        </DialogActions>
      </Dialog>
      <CustomPriceModal
        isOpen={customPriceModalIsOpen}
        onClose={() => setCustomPriceModalIsOpen(false)}
        isLoading={isLoading}
        subscriptionPrice={subscriptionPrice}
        setSubscriptionPrice={setSubscriptionPrice}
        subscriptionTypes={subscriptionTypes}
        dateIsValid={dateIsValid}
        onNewPriceSubmit={submitNewPrice}
        currentPlan={currentPlan}
        upcomingPlan={upcomingPlan}
      />
    </>
  );
};

export default Licences;
