"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoalStatus = void 0;
var GoalStatus;
(function (GoalStatus) {
    GoalStatus[GoalStatus["ONGOING"] = 1] = "ONGOING";
    GoalStatus[GoalStatus["COMPLETE"] = 2] = "COMPLETE";
    GoalStatus[GoalStatus["PARTIAL"] = 3] = "PARTIAL";
    GoalStatus[GoalStatus["MISSED"] = 4] = "MISSED";
})(GoalStatus || (exports.GoalStatus = GoalStatus = {}));
