import React from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

function GridDivider() {
  return (
    <Grid item xs={12}>
      <Divider />
    </Grid>
  );
}

export default GridDivider;
