function formatPrice(price, currency) {
  return new Intl.NumberFormat('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: currency || 'USD',
    currencyDisplay: currency ? 'code' : 'narrowSymbol',
  }).format(price);
}

export default formatPrice;
