"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentXeroType = void 0;
/**
 * Identifies the type of payment in Xero, used when creating a quote as invoice items type
 * It maps to ```select * from invoice_definitions where `type` = 'xero_line_items'```
 */
var PaymentXeroType;
(function (PaymentXeroType) {
    PaymentXeroType["ANNUAL_LICENCES"] = "annual_licences";
    PaymentXeroType["MONTHLY_LICENCES"] = "monthly_licences";
    PaymentXeroType["LICENCE_CREDITS"] = "licence_credits";
    PaymentXeroType["MONERIS"] = "moneris";
    PaymentXeroType["POINTS_PURCHASE"] = "points_purchase";
    PaymentXeroType["POINTS_PURCHASE_CREDIT"] = "points_purchase_credit";
    PaymentXeroType["ADMIN_FEES"] = "admin_fees";
    PaymentXeroType["WIRE_TRANSFER"] = "wireTransfer";
    PaymentXeroType["EXEMPT"] = "EXEMPT";
    PaymentXeroType["AWARDS"] = "awards";
    PaymentXeroType["VOUCHER_LICENCES"] = "voucher_licences";
    PaymentXeroType["AWARDS_CREDIT"] = "awards_credit";
})(PaymentXeroType || (exports.PaymentXeroType = PaymentXeroType = {}));
