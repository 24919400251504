import React, { useState } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Datetime from 'react-datetime';
import moment from 'moment';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button';

import styles from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
const useStyles = makeStyles(styles);

const BulkActions = ({
  disabled,
  onSaveCelebrationDate,
  onActivateVouchers,
  onDeactivateVouchers,
  onReactivateVouchers,
  onConvertToPoints,
}) => {
  const classes = useStyles();
  const [dateCelebration, setDateCeleberation] = useState('');

  const handleOnSaveCelebration = () => {
    onSaveCelebrationDate(dateCelebration);
  };
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <h4 className={classes.cardIconTitle}>Bulk Actions</h4>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                      <Datetime
                        timeFormat={false}
                        inputProps={{
                          placeholder: 'Celebration date',
                          disabled,
                        }}
                        onChange={(date) => {
                          setDateCeleberation(date.format('YYYY-MM-DD'));
                        }}
                        value={dateCelebration}
                        isValidDate={(current) => current.isAfter(moment())}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} fullWidth>
                      <Button
                        onClick={handleOnSaveCelebration}
                        color="info"
                        fullWidth
                        disabled={disabled || !dateCelebration}
                      >
                        {'Set celebration date'}
                      </Button>
                    </GridItem>
                    {/* extra space to distinguish between the above button and other buttons */}
                    <GridItem xs={12} sm={12} md={1} />
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <Button
                    onClick={onActivateVouchers}
                    color="success"
                    fullWidth
                    disabled={disabled}
                  >
                    {'Activate Vouchers'}
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <Button
                    onClick={onDeactivateVouchers}
                    color="danger"
                    fullWidth
                    disabled={disabled}
                  >
                    {'Disable Vouchers'}
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <Button
                    onClick={onReactivateVouchers}
                    color="facebook"
                    fullWidth
                    disabled={disabled}
                  >
                    {'Reactivate Vouchers'}
                  </Button>
                </GridItem>

                <GridItem xs={12} sm={12} md={2}>
                  <Button
                    onClick={onConvertToPoints}
                    color="warning"
                    fullWidth
                    disabled={disabled}
                  >
                    {'Convert to Points'}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default BulkActions;
