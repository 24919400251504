import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// core components
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';

import Loading from 'components/Loading/Loading';

import { loginAs } from 'helpers/api/users';

const LoginAs = () => {
  const params = useParams();
  const [loginLink, setLoginLink] = React.useState('');
  useEffect(() => {
    loginAs(params.id)
      .then(({ token }) => {
        const magicLink =
          `${process.env.REACT_APP_AUTH_URL}/auth`
          + `?client_id=${process.env.REACT_APP_SUPER_ADMIN_HUB_CLIENT_ID}`
          + `&response_type=code`
          + `&scope=openid%20offline_access`
          + `&token=${token}`
          + `&redirect_uri=${process.env.REACT_APP_HUB_CLIENT_URL}`;
        setLoginLink(magicLink);
        if (document.location.href.includes('follow=true')) {
          location.href = magicLink;
        }
      })
      .catch((loginAsError) => {
        setLoginLink(loginAsError.toString());
      });
  }, [params.id, params.version]);

  return (
    <>
      <GridItem xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        {!loginLink ? <Loading /> : 'The Magic Login Link is ready!'}
      </GridItem>
      <GridItem xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={() => (location.href = loginLink)}>Open Link</Button>
      </GridItem>
      <GridItem xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={() => navigator.clipboard.writeText(loginLink)}>
          Copy link to clipboard
        </Button>
      </GridItem>
    </>
  );
};

export default LoginAs;
