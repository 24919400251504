import { useMemo } from 'react';

const useUpdateType = (
  packagePicker,
  licencesCount,
  newPackage,
  currentPlan,
  isApplyNow
) => {
  return useMemo(() => {
    let changeType = '';
    if (!packagePicker.selectedContractTerm) return false;
    if (!licencesCount || !newPackage.newPricePerLicence) return false;

    const selectedPackage = packagePicker.selectedContractTerm;
    if (selectedPackage?.typeId) {
      const newLength = selectedPackage?.length.split(' ');
      const newLengthValue = newLength[0];
      const newLengthUnit = newLength[1];
      const currentLength = (currentPlan.length || '1 month').split(' ');
      const currentLengthValue = currentLength[0];
      const currentLengthUnit = currentLength[1];

      if (!isApplyNow) return 'upgrade';
      // 1. Selected exactly the same package and licence count
      // 2. Downgrading
      // 3. Upgrading
      if (
        newLengthValue
        && `${selectedPackage.typeId}` === `${currentPlan.type_id}`
        && `${licencesCount}` === `${currentPlan.licences}`
      ) {
        changeType = 'same';
      } else if (
        (newLengthValue
          && parseInt(currentLengthValue) === parseInt(newLengthValue)
          && currentPlan.licences > parseInt(licencesCount))
        || (currentLengthUnit === 'year' && newLengthUnit === 'month')
      ) {
        changeType = 'downgrade';
      } else {
        changeType = 'upgrade';
      }
    }
    // TEMPORARILY REMOVED, SEE NOTES AT TOP
    // if (!selectedPaymentFrequencyKey) return false;
    return changeType;
  }, [
    packagePicker.selectedContractTerm,
    licencesCount,
    newPackage.newPricePerLicence,
    currentPlan,
    isApplyNow,
  ]);
};

export default useUpdateType;
