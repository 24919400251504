"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceStatus = void 0;
var InvoiceStatus;
(function (InvoiceStatus) {
    InvoiceStatus[InvoiceStatus["QUOTE"] = 1] = "QUOTE";
    InvoiceStatus[InvoiceStatus["INVOICED"] = 2] = "INVOICED";
    InvoiceStatus[InvoiceStatus["PAID"] = 3] = "PAID";
    InvoiceStatus[InvoiceStatus["COMPLETED"] = 4] = "COMPLETED";
})(InvoiceStatus || (exports.InvoiceStatus = InvoiceStatus = {}));
