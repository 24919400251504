import React from 'react';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

export default ({ label, value, labelStyle, valueStyle }) => (
  <GridContainer style={{ margin: '5px 0px 5px 0px' }}>
    <GridItem xs={12} sm={5} style={labelStyle}>
      <b>{label}</b>
    </GridItem>
    <GridItem xs={12} sm={7} style={valueStyle}>
      {value}
    </GridItem>
  </GridContainer>
);
