"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfigurationKey = void 0;
var ConfigurationKey;
(function (ConfigurationKey) {
    ConfigurationKey["ENABLE_POINTS"] = "enable_points";
    ConfigurationKey["SERVICE_AWARDS_CREDIT"] = "service_awards_credit";
    ConfigurationKey["ENABLE_MARKETPLACE"] = "enable_marketplace";
    ConfigurationKey["ENABLE_PERKS"] = "enable_perks";
    ConfigurationKey["ENABLE_SERVICE_AWARDS"] = "enable_service_awards";
    ConfigurationKey["DEFAULT_POST_VISIBILITY"] = "default_post_visibility";
    ConfigurationKey["BADGE_LIMIT_PER_RECOGNITION"] = "badge_limit_per_recognition";
})(ConfigurationKey || (exports.ConfigurationKey = ConfigurationKey = {}));
