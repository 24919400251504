import { useCallback } from 'react';
import moment from 'moment';

const useUpcomingDates = (selectedPackage, currentPlan, applyNow) => {
  return useCallback(() => {
    if (!selectedPackage.typeId) return '';
    const packageLength = selectedPackage.length.split(' ');
    const billDate = moment(currentPlan?.billDate)
      .startOf('day')
      .format('YYYY-MM-DD');
    const today = moment().startOf('day').format('YYYY-MM-DD');
    const startOfNewTerm = applyNow
      ? today
      : moment(billDate).startOf('day').format('YYYY-MM-DD');
    const endOfNewTerm = applyNow
      ? moment(today)
          .add(packageLength[0], `${packageLength[1]}s`)
          .startOf('month')
          .format('YYYY-MM-DD')
      : moment(billDate)
          .add(packageLength[0], `${packageLength[1]}s`)
          .startOf('day')
          .format('YYYY-MM-DD');

    return {
      startOfNewTerm,
      endOfNewTerm,
    };
  }, [selectedPackage, currentPlan?.billDate, applyNow]);
};

export default useUpcomingDates;
