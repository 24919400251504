import React from 'react';
import MaskedInput from 'react-text-mask';
import { NumericFormat } from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

NumericFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function NumericFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="$"
    />
  );
}

export default function ({ value, onChange, InputProps, label, ...props }) {
  return (
    <TextField
      value={value}
      onChange={onChange}
      InputProps={{
        inputComponent: NumericFormatCustom,
      }}
      label={label}
    />
  );
}
