"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AwardStatus = void 0;
var AwardStatus;
(function (AwardStatus) {
    AwardStatus[AwardStatus["UNPAID"] = 1] = "UNPAID";
    AwardStatus[AwardStatus["PAID"] = 2] = "PAID";
    AwardStatus[AwardStatus["ACTIVE"] = 3] = "ACTIVE";
    AwardStatus[AwardStatus["REDEEMED"] = 4] = "REDEEMED";
    AwardStatus[AwardStatus["CREDITED"] = 5] = "CREDITED";
    AwardStatus[AwardStatus["DISABLED"] = 9] = "DISABLED";
    AwardStatus[AwardStatus["CONVERTED"] = 10] = "CONVERTED";
})(AwardStatus || (exports.AwardStatus = AwardStatus = {}));
