"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomizationType = void 0;
var CustomizationType;
(function (CustomizationType) {
    CustomizationType[CustomizationType["BIRTHDAY_POST"] = 1] = "BIRTHDAY_POST";
    CustomizationType[CustomizationType["BIRTHDAY_EMAIL"] = 2] = "BIRTHDAY_EMAIL";
    CustomizationType[CustomizationType["ANNIVERSARY_POST"] = 3] = "ANNIVERSARY_POST";
    CustomizationType[CustomizationType["ANNIVERSARY_EMAIL_REGULAR"] = 4] = "ANNIVERSARY_EMAIL_REGULAR";
    CustomizationType[CustomizationType["ANNIVERSARY_EMAIL_MILESTONE"] = 5] = "ANNIVERSARY_EMAIL_MILESTONE";
    CustomizationType[CustomizationType["GOALS_POST"] = 6] = "GOALS_POST";
    CustomizationType[CustomizationType["GOALS_EMAIL"] = 7] = "GOALS_EMAIL";
    CustomizationType[CustomizationType["SERVICE_AWARD_MESSAGE"] = 8] = "SERVICE_AWARD_MESSAGE";
    CustomizationType[CustomizationType["WELCOME_EMAIL"] = 9] = "WELCOME_EMAIL";
    CustomizationType[CustomizationType["GOALS_PARTIALLY_COMPLETED_POST"] = 10] = "GOALS_PARTIALLY_COMPLETED_POST";
    CustomizationType[CustomizationType["GOALS_PARTIALLY_COMPLETED_EMAIL"] = 11] = "GOALS_PARTIALLY_COMPLETED_EMAIL";
})(CustomizationType || (exports.CustomizationType = CustomizationType = {}));
