import { useMemo } from 'react';

const useHasChanges = (selectedPackage, currentPlan, upcomingPlan) => {
  return useMemo(() => {
    const { typeId, licencesCount, length, ppl } = selectedPackage;
    const {
      type_id: currentTypeId,
      licences: currentLicencesCount,
      length: currentLength,
    } = currentPlan;

    if (upcomingPlan?.typeId) {
      return (
        typeId !== upcomingPlan.typeId
        || parseInt(licencesCount) !== upcomingPlan.total
        || length !== upcomingPlan.length
        || ppl !== upcomingPlan.pricePerLicence
      );
    }

    return (
      typeId !== currentTypeId
      || parseInt(licencesCount) !== currentLicencesCount
      || length !== currentLength
      || ppl !== currentPlan.ppu
    );
  }, [selectedPackage, currentPlan, upcomingPlan]);
};

export default useHasChanges;
