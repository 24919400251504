import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import QuoteTable from './QuoteTable';

function GeneratedQuoteDialog({ isOpen, onClose, url, organizationId }) {
  const invoiceId = url.split('invoiceId=')[1];

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Invoice</DialogTitle>

      {invoiceId && (
        <DialogContent>
          <QuoteTable invoiceId={invoiceId} organizationId={organizationId} />
        </DialogContent>
      )}

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Box mt={2}>
            This address will invoke the checkout form for the client to pay
            this invoice:
          </Box>
        </DialogContentText>
        <Paper variant="outlined">
          <Box p={3} display="flex" justifyContent="center" alignItems="center">
            <code
              style={{
                padding: 10,
                display: 'block',
                flexGrow: 1,
                marginRight: 10,
                textAlign: 'center',
              }}
            >
              {url}
            </code>
            {/*
             * If the user's browser does not have navigator,
             * they can select the url and copy it manually.
             */}
            {typeof navigator !== 'undefined'
              && !!navigator?.clipboard?.writeText && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={async () => {
                    try {
                      await navigator.clipboard.writeText(url);
                    } catch (err) {
                      console.error('Failed to copy: ', err);
                    }
                  }}
                >
                  Copy
                </Button>
              )}
          </Box>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
}

export default GeneratedQuoteDialog;
