"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostType = void 0;
var PostType;
(function (PostType) {
    PostType[PostType["REGULAR"] = 1] = "REGULAR";
    PostType[PostType["RECOGNITION"] = 2] = "RECOGNITION";
    PostType[PostType["LAUNCH"] = 8] = "LAUNCH";
    PostType[PostType["BIRTHDAY"] = 9] = "BIRTHDAY";
    PostType[PostType["ANNIVERSARY"] = 10] = "ANNIVERSARY";
    PostType[PostType["COMMENT"] = 11] = "COMMENT";
    PostType[PostType["GOAL"] = 12] = "GOAL";
    PostType[PostType["POLL"] = 13] = "POLL";
    PostType[PostType["NEW_EMPLOYEE"] = 14] = "NEW_EMPLOYEE";
})(PostType || (exports.PostType = PostType = {}));
