import Dashboard from 'views/Pages/Admin/DashBoard';
import LoginPage from 'views/Pages/LoginPage';
import AccountsPage from 'views/Pages/Admin/AccountsPage';
import GiveCreditsPage from 'views/Pages/Admin/Accounts/GiveCreditsPage';
import UsersPage from 'views/Pages/Admin/UsersPage';
import ProductsPage from 'views/Pages/Admin/ProductsPage';
import InvoicesPage from 'views/Pages/Admin/InvoicesPage';
import VouchersPage from 'views/Pages/Admin/Accounts/VouchersPage';
import SnappointsPage from 'views/Pages/Admin/Accounts/SnappointsPage';

// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DescriptionIcon from '@material-ui/icons/Description';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import BookOutlined from '@material-ui/icons/BookOutlined';
import ViewComfy from '@material-ui/icons/ViewComfy';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import WalletIcon from '@material-ui/icons/AccountBalanceWallet';

const dashRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: DashboardIcon,
    component: <Dashboard />,
    layout: '/admin',
  },
  {
    collapse: true,
    name: 'Accounts',
    icon: AccountBalanceIcon,
    state: 'accountsCollapse',
    views: [
      {
        path: '/accounts',
        name: 'List',
        icon: ListAltIcon,
        component: <AccountsPage />,
        layout: '/admin',
      },
      {
        path: '/invoices',
        name: 'Invoices',
        icon: DescriptionIcon,
        layout: '/admin',
        component: <InvoicesPage />,
      },
      {
        path: '/vouchers',
        name: 'Vouchers',
        icon: CardGiftcardIcon,
        layout: '/admin',
        component: <VouchersPage />,
      },
      {
        path: '/snappoints',
        name: 'Snappoints',
        icon: WalletIcon,
        component: <SnappointsPage />,
        layout: '/admin',
      },
      {
        path: '/credits',
        name: 'Give Credits',
        icon: PlayForWorkIcon,
        component: <GiveCreditsPage />,
        layout: '/admin',
        permissions: 'give:admin.credits',
      },
    ],
  },
  {
    path: '/users',
    name: 'Users',
    icon: PeopleIcon,
    component: <UsersPage />,
    layout: '/admin',
    permissions: 'access:admin.users',
  },
  {
    collapse: true,
    name: 'Catalog',
    icon: BookOutlined,
    state: 'catalogCollapse',
    views: [
      {
        path: '/products',
        name: 'Products',
        icon: ViewComfy,
        component: <ProductsPage />,
        layout: '/admin',
      },
    ],
  },
  {
    path: 'login-page',
    name: 'Login Page',
    mini: 'L',
    component: <LoginPage />,
    layout: '/auth',
    invisible: true,
  },
  {
    path: 'login-redirect',
    name: 'Redirect',
    component: <LoginPage />,
    layout: '/auth',
    invisible: true,
  },
];
export default dashRoutes;
