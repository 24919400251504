"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderStatus = void 0;
var OrderStatus;
(function (OrderStatus) {
    OrderStatus[OrderStatus["CART"] = 1] = "CART";
    OrderStatus[OrderStatus["PENDING"] = 2] = "PENDING";
    OrderStatus[OrderStatus["PROCESSING"] = 3] = "PROCESSING";
    OrderStatus[OrderStatus["PROCESSED"] = 4] = "PROCESSED";
    OrderStatus[OrderStatus["SHIPPED"] = 5] = "SHIPPED";
    OrderStatus[OrderStatus["WISHLIST"] = 6] = "WISHLIST";
    OrderStatus[OrderStatus["CANCELLED"] = 7] = "CANCELLED";
    OrderStatus[OrderStatus["REVIEW"] = 8] = "REVIEW";
    OrderStatus[OrderStatus["PICK_UP"] = 9] = "PICK_UP";
    OrderStatus[OrderStatus["PARTIALLY_SHIPPED"] = 10] = "PARTIALLY_SHIPPED";
    OrderStatus[OrderStatus["READY_TO_SYNC"] = 11] = "READY_TO_SYNC";
    OrderStatus[OrderStatus["PROCESSING_RETURN"] = 15] = "PROCESSING_RETURN";
})(OrderStatus || (exports.OrderStatus = OrderStatus = {}));
