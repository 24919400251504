"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LicenceType = void 0;
var LicenceType;
(function (LicenceType) {
    LicenceType[LicenceType["FREE"] = 1] = "FREE";
    LicenceType[LicenceType["MONTHLY"] = 2] = "MONTHLY";
    LicenceType[LicenceType["ANNUALLY"] = 3] = "ANNUALLY";
    LicenceType[LicenceType["VOUCHER"] = 4] = "VOUCHER";
    LicenceType[LicenceType["BIANNUALLY"] = 5] = "BIANNUALLY";
    LicenceType[LicenceType["TRIANNUALLY"] = 6] = "TRIANNUALLY";
    // SA GIFTING WITH POINTS
    LicenceType[LicenceType["SA_MONTHLY"] = 7] = "SA_MONTHLY";
    LicenceType[LicenceType["SA_ANNUALLY"] = 8] = "SA_ANNUALLY";
    LicenceType[LicenceType["SA_BIANNUALLY"] = 9] = "SA_BIANNUALLY";
    LicenceType[LicenceType["SA_TRIANNUALLY"] = 10] = "SA_TRIANNUALLY";
    // R360
    LicenceType[LicenceType["STARTER_MONTHLY"] = 11] = "STARTER_MONTHLY";
    LicenceType[LicenceType["STARTER_ANNUALLY"] = 12] = "STARTER_ANNUALLY";
    LicenceType[LicenceType["STARTER_BIANNUALLY"] = 13] = "STARTER_BIANNUALLY";
    LicenceType[LicenceType["STARTER_TRIANNUALLY"] = 14] = "STARTER_TRIANNUALLY";
    LicenceType[LicenceType["PRO_MONTHLY"] = 15] = "PRO_MONTHLY";
    LicenceType[LicenceType["PRO_ANNUALLY"] = 16] = "PRO_ANNUALLY";
    LicenceType[LicenceType["PRO_BIANNUALLY"] = 17] = "PRO_BIANNUALLY";
    LicenceType[LicenceType["PRO_TRIANNUALLY"] = 18] = "PRO_TRIANNUALLY";
})(LicenceType || (exports.LicenceType = LicenceType = {}));
