"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const enums_1 = require("@applauz/enums");
exports.default = {
    [`snappoints/transaction-statuses/${enums_1.SnappointsTransactionStatus.CANCELLED}`]: 'Cancelled',
    [`snappoints/transaction-statuses/${enums_1.SnappointsTransactionStatus.COMPLETED}`]: 'Completed',
    [`snappoints/transaction-statuses/${enums_1.SnappointsTransactionStatus.IN_PROGRESS}`]: 'In Progress',
    [`snappoints/transaction-statuses/${enums_1.SnappointsTransactionStatus.PENDING}`]: 'Pending',
    [`snappoints/transaction-statuses/${enums_1.SnappointsTransactionStatus.UNKNOWN}`]: 'Unknown',
};
