"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SnappointsTransactionStatus = void 0;
var SnappointsTransactionStatus;
(function (SnappointsTransactionStatus) {
    SnappointsTransactionStatus[SnappointsTransactionStatus["PENDING"] = 1] = "PENDING";
    SnappointsTransactionStatus[SnappointsTransactionStatus["IN_PROGRESS"] = 2] = "IN_PROGRESS";
    SnappointsTransactionStatus[SnappointsTransactionStatus["COMPLETED"] = 3] = "COMPLETED";
    SnappointsTransactionStatus[SnappointsTransactionStatus["CANCELLED"] = 4] = "CANCELLED";
    SnappointsTransactionStatus[SnappointsTransactionStatus["UNKNOWN"] = 99] = "UNKNOWN";
})(SnappointsTransactionStatus || (exports.SnappointsTransactionStatus = SnappointsTransactionStatus = {}));
