// this hook returns jsx, but is not a component file
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import moment from 'moment';
import { SnappointsLocales } from '@applauz/locales';
import { SnappointsTransactionStatus } from '@applauz/enums';
import Typography from '@material-ui/core/Typography';

const TRANSACTION_STATUS_TO_COLOR_MAP = {
  [SnappointsTransactionStatus.CANCELLED]: 'red',
  [SnappointsTransactionStatus.COMPLETED]: 'green',
  [SnappointsTransactionStatus.IN_PROGRESS]: 'black',
  [SnappointsTransactionStatus.PENDING]: 'black',
  [SnappointsTransactionStatus.UNKNOWN]: 'black',
};

const TRANSACTION_STATUSES_EN_LOCALE = SnappointsLocales.TransactionStatuses.en;

export default function useGridColumns() {
  return React.useMemo(() => {
    const alignRight = {
      headerAlign: 'right',
      align: 'right',
    };
    const columns = [
      {
        headerName: 'ID',
        field: 'id',
        minWidth: 90,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: 'Organzation',
        field: 'organizationName',
        width: 250,
        renderCell: (params) => {
          const { row } = params;
          const organizationName = `[${row.organizationId}] ${row.organizationName}`;
          return organizationName;
        },
      },
      {
        headerName: 'Employee',
        field: 'employee',
        width: 250,
        renderCell: (params) => {
          const { row } = params;
          const userName = `[${row.userId}] ${row.firstName} ${row.lastName}`;
          return userName;
        },
      },
      {
        headerName: 'Program',
        field: 'programName',
        minWidth: 150,
      },
      {
        headerName: 'Applauz Points',
        field: 'applauzPoints',
        width: 180,
        ...alignRight,
      },
      {
        headerName: 'Exchanged Points',
        field: 'exchangedPoints',
        width: 190,
        ...alignRight,
      },
      {
        headerName: 'Status',
        field: 'status',
        width: 120,
        ...alignRight,
        renderCell: (params) => {
          const { row } = params;
          const statusText =
            TRANSACTION_STATUSES_EN_LOCALE[
              `snappoints/transaction-statuses/${row.status}`
            ];

          return (
            <Typography
              style={{ color: TRANSACTION_STATUS_TO_COLOR_MAP[row.status] }}
              variant="body2"
            >
              {statusText}
            </Typography>
          );
        },
      },
      {
        headerName: 'Date',
        field: 'date',
        width: 150,
        ...alignRight,
        renderCell: (params) => {
          const { row } = params;
          const hasDifferentUpdatedDate =
            row.updatedDate && row.updatedDate !== row.date;
          const dateStyle = hasDifferentUpdatedDate ? { height: 16 } : null;
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <div style={dateStyle}>
                {moment(row.date).format('YYYY-MM-DD')}
              </div>

              {hasDifferentUpdatedDate && (
                <div
                  style={{
                    fontSize: 13,
                  }}
                >
                  {`(updated: ${
                    moment(row.updatedDate).format('YYYY-MM-DD') || ''
                  })`}
                </div>
              )}
            </div>
          );
        },
      },
    ];

    return columns;
  }, []);
}
