import React from 'react';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import GeneralForm from './GeneralForm';
import AddressForm from './AddressForm';
import MarketplaceForm from './MarketplaceForm';
import ModulesForm from './ModulesForm';
import OwnerForm from './OwnerForm';
import SnackbarContent from 'components/Snackbar/SnackbarContent';

import { getCountries } from 'helpers/api/api';
import { verifyEmail } from 'helpers/api/users';

function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

function postalCodeIsValid(postalCode, country) {
  return (
    (parseInt(country) === 38
      && /[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/.test(
        postalCode
      ))
    || /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(postalCode)
  );
}

const Form = ({
  onSubmit,
  company,
  catalog,
  catalogSA,
  setCompany,
  activeModuleIds,
  onChangeActiveModuleIds,
  isEdit,
  isLoading,
  success,
  setIsLoading,
}) => {
  const [countries, setCountries] = React.useState([]);
  const [selectedSupplierIds, setSelectedSupplierIds] = React.useState([]);
  const [selectedGRSCatalogId, setSelectedGRSCatalogId] = React.useState('');
  const [selectedGRSCatalogIdSA, setSelectedGRSCatalogIdSA] =
    React.useState('');

  React.useEffect(() => {
    setSelectedSupplierIds(catalog.supplierIds);
    setSelectedGRSCatalogId(catalog.grsCatalogId);
    if (catalogSA?.grsCatalogId) {
      setSelectedGRSCatalogIdSA(catalogSA.grsCatalogId);
    }
  }, [catalog, catalogSA]);

  React.useEffect(() => {
    if (company.supplierIds?.join(',') !== selectedSupplierIds.join(',')) {
      setCompany('supplierIds', selectedSupplierIds);
    }
    if (company.grsCatalogId !== selectedGRSCatalogId) {
      setCompany('grsCatalogId', selectedGRSCatalogId);
    }
    if (company.grsCatalogIdSA !== selectedGRSCatalogIdSA) {
      setCompany('grsCatalogIdSA', selectedGRSCatalogIdSA);
    }
  }, [
    setCompany,
    company,
    selectedSupplierIds,
    selectedGRSCatalogId,
    selectedGRSCatalogIdSA,
  ]);

  const [formError, setFormError] = React.useState({});

  const disabled = React.useMemo(
    () =>
      company.company_name === ''
      || (!isEdit
        && Object.keys(company.owner).filter((key) => company.owner[key] === '')
          .length > 0)
      || company.address.phone === ''
      || company.address.city === ''
      || company.address.country_id === ''[company],
    [company, isEdit]
  );

  const validateData = async () => {
    setFormError({});

    if (company.owner) {
      if (!emailIsValid(company.owner.email)) {
        setIsLoading(false);
        setFormError((prev) => {
          return { ...prev, email: 'The email is invalid.' };
        });
      }

      const { valid } = await verifyEmail(company.owner.email);

      if (!valid) {
        setFormError((prev) => {
          return { ...prev, email: 'This email is already in use' };
        });
      }
    }

    if (
      company.address.postal_code !== ''
      && !postalCodeIsValid(
        company.address.postal_code,
        company.address.country_id
      )
    ) {
      // Legacy camel case support
      setFormError((prev) => {
        return { ...prev, ['postal_code']: 'The postal code is invalid.' };
      });
    }

    // minimum length of international phone number is 7
    if (company.address.phone.length < 7) {
      setFormError((prev) => {
        return { ...prev, phone: 'The phone number is invalid.' };
      });
    }

    if (company.hubspotCompanyID && isNaN(Number(company.hubspotCompanyID))) {
      setFormError((prev) => {
        return {
          ...prev,
          hubspotCompanyID: 'The hubspot company ID is invalid.',
        };
      });
    }

    onSubmit();
  };

  React.useEffect(() => {
    getCountries()
      .then((res) => {
        if (res.success) setCountries(res.countries);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const isSandbox = company.isSandbox;

  return (
    <>
      <GridContainer
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <GridItem xs={12} sm={12} md={6}>
          {!isEdit && (
            <OwnerForm
              owner={company.owner}
              setCompany={setCompany}
              formError={formError}
            />
          )}
          <GeneralForm
            company={company}
            setCompany={setCompany}
            formError={formError}
            isSandbox={isSandbox}
          />
          {success && (
            <SnackbarContent message={'Successfully saved'} color="success" />
          )}
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <AddressForm
            address={company.address}
            setCompany={setCompany}
            countries={countries}
            formError={formError}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          {company?.address?.country_id && (
            <MarketplaceForm
              countryId={company.address.country_id}
              selectedSupplierIds={selectedSupplierIds}
              setSelectedSupplierIds={setSelectedSupplierIds}
              selectedGRSCatalogId={selectedGRSCatalogId}
              selectedGRSCatalogIdSA={selectedGRSCatalogIdSA}
              setSelectedGRSCatalogId={setSelectedGRSCatalogId}
              setSelectedGRSCatalogIdSA={setSelectedGRSCatalogIdSA}
              isSandbox={isSandbox}
            />
          )}
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <ModulesForm
            companyId={company.id}
            activatedModuleIds={activeModuleIds}
            onChange={onChangeActiveModuleIds}
          />
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={4} />
        <GridItem
          xs={4}
          style={{
            display: 'flex',
            alignItems: 'inherit',
            flexDirection: 'column',
          }}
        >
          <Button
            onClick={validateData}
            color={disabled || isLoading ? 'github' : 'info'}
            disabled={disabled || isLoading}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              (isEdit && 'Save Account') || 'Create Account'
            )}
          </Button>
        </GridItem>
        <GridItem xs={4} />
      </GridContainer>
    </>
  );
};

export default Form;
