import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import InputAdornment from '@material-ui/core/InputAdornment';
import CustomInput from 'components/CustomInput/CustomInput';
import Close from '@material-ui/icons/Close';
const useStyles = makeStyles(styles);

export default ({ value, onChange, mask, placeholder, type, endAdornment }) => {
  const classes = useStyles();
  const [required, setRequired] = React.useState('');
  const [requiredState, setRequiredState] = React.useState('');

  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  return (
    <CustomInput
      success={requiredState === 'success'}
      error={requiredState === 'error'}
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        onChange: (event) => {
          // if (verifyLength(event.target.value, 0)) {
          //   setRequiredState("success");
          // } else {
          //   setRequiredState("error");
          // }
          onChange(event.target.value);
        },
        mask,
        placeholder,
        type: type || 'text',
        endAdornment: endAdornment ? (
          endAdornment
        ) : requiredState === 'error' ? (
          <InputAdornment position="end">
            <Close className={classes.danger} />
          </InputAdornment>
        ) : undefined,
        value,
      }}
    />
  );
};
