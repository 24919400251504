import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import MaterialTable from 'material-table';
import GetAppIcon from '@material-ui/icons/GetApp';
import DoneIcon from '@material-ui/icons/Done';
import PostAddIcon from '@material-ui/icons/PostAdd';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import Loading from 'components/Loading/Loading';
// helpers
import {
  getInvoices,
  getPDF,
  callCallbackURL,
  setPaid,
} from 'helpers/api/payments';
import { completeWiretransferPurchase } from 'helpers/api/awards';
// Our components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import ApplauzButton from 'components/CustomButtons/Button';
// imports specific to this module
import columns from './columns';
import { InvoiceStatus } from '@applauz/enums';
import { capitalize } from 'lodash';

const Homepage = () => {
  const [invoices, setInvoices] = React.useState([]);
  const queryClient = useQueryClient();
  const { data: invoicesData, isFetching } = useQuery(
    ['account.invoices'],
    () =>
      getInvoices().then((data) =>
        data.map((invoice) => ({
          ...invoice,
          id: invoice.invoiceId,
          tsInserted: moment(invoice.tsInserted)
            .local()
            .format('YYYY-MM-DD HH:mm'),
          status: capitalize(InvoiceStatus[invoice.statusId]),
          paymentType:
            invoice.payments.length > 1
              ? `${invoice.paymentType} + ${invoice.payments.length - 1}`
              : invoice.paymentType || 'N/A',
        }))
      ),
    {
      placeholderData: [],
    }
  );

  React.useEffect(() => {
    !isFetching && setInvoices(invoicesData);
  }, [invoicesData, isFetching]);

  const onDownload = (invoiceID) => {
    getPDF(invoiceID)
      .then((data) => {
        const fileURL = URL.createObjectURL(data);

        window.open(fileURL);
      })
      .catch((getInvoicePDFError) => console.log({ getInvoicePDFError }));
  };

  const navigate = useNavigate();

  const onSetPaidClick = (invoiceId, name, type, total) => {
    if (
      !confirm(
        `${name} - ${type} - $${total}\n\n`
          + 'You’re about to set this invoice as paid by applying a manual payment\n\n'
          + 'Are you sure you want to continue? This action cannot be reversed.'
      )
    ) {
      return;
    }
    setPaid(invoiceId)
      .then(
        () => alert('Invoice has been marked as paid.'),
        (error) => alert(`!!! Error marking invoice as paid:\n\n${error}`)
      )
      .then(() => {
        queryClient.invalidateQueries(['account.invoices']);
      });
  };

  const onCompleteInvoiceClick = (
    invoiceId,
    name,
    type,
    total
    // dateInvoiced
  ) => {
    if (
      !confirm(
        `${name} - ${type} - $${total}\n\n`
          + 'You’re about to complete this invoice giving out the licences, points or vouchers pending for this invoice\n\n'
          + 'Are you sure you want to continue? This action cannot be reversed.'
      )
    ) {
      return;
    }

    let functionToCall = callCallbackURL;
    const isV1 = !!invoicesData.find(({ id }) => id === invoiceId)
      ?.callbackIsV1;

    if (type === 'awards' && isV1) {
      // Legacy SA invoices need to be completed in V1
      functionToCall = completeWiretransferPurchase;
    }
    functionToCall(invoiceId)
      .then(
        () => alert('Invoice has been completed.'),
        (error) => alert(`!!! Error completing invoice:\n\n${error}`)
      )
      .then(() => {
        queryClient.invalidateQueries(['invoices']);
      });
  };

  if (isFetching) {
    return (
      <GridItem xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Loading />
      </GridItem>
    );
  }

  return (
    <div>
      <GridContainer>
        <ApplauzButton
          style={{
            marginBottom: 35,
          }}
          color="info"
          onClick={() => navigate('/admin/invoices/new')}
        >
          Create new invoice
        </ApplauzButton>
        <GridItem xs={12}>
          <MaterialTable
            title="Invoices"
            columns={columns}
            data={invoices}
            actions={[
              (rowData) => ({
                icon: GetAppIcon,
                tooltip: 'Download Invoice',
                onClick: () => onDownload(rowData.invoiceId),
              }),
              ({ status, invoiceId, organizationName, type, total }) => {
                if (status !== 'Invoiced') return null;
                return {
                  icon: DoneIcon,
                  tooltip: 'Set Paid',
                  onClick: () =>
                    onSetPaidClick(invoiceId, organizationName, type, total),
                };
              },
              ({
                status,
                invoiceId,
                organizationName,
                type,
                total,
                dateInvoiced,
              }) => {
                if (status !== 'Paid') return null;
                return {
                  icon: PostAddIcon,
                  tooltip: 'Complete Invoice',
                  onClick: () =>
                    onCompleteInvoiceClick(
                      invoiceId,
                      organizationName,
                      type,
                      total,
                      dateInvoiced
                    ),
                };
              },
              ({ payments }) => {
                if (!payments.length) return null;
                return {
                  icon: LocalAtmIcon,
                  tooltip: 'Show payments',
                  onClick: () => alert(JSON.stringify(payments, null, 2)),
                };
              },
              ({ status, invoiceId }) => {
                if (status !== 'Quote') return null;
                return {
                  icon: ShoppingCartIcon,
                  tooltip: 'Get Payment Link',
                  onClick: () =>
                    prompt(
                      'Copy the payment link bellow:\n\n !! Must be logged in as the organization to pay the invoice !!',
                      `${process.env.REACT_APP_HUB_CLIENT_URL}/invoices?invoiceId=${invoiceId}`
                    ),
                };
              },
            ]}
            options={{
              actionsColumnIndex: -1,
              filtering: true,
              sorting: true,
              pageSize: 20,
              pageSizeOptions: [20, 50, 100],
            }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Homepage;
