"use strict";
/**
 * To properly understand this, you have to think of Liquid points
 * vs Pool points. Liquid points are points that are "in the market"
 * and that can be transferred from one entity to another.
 *
 * Pool points are where points end up when they are spent or expired.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PoolPointsBankEntity = exports.LiquidPointsBankEntity = exports.MarketplacePointsBankEntity = exports.UserPointsBankEntity = exports.PointsBankEntity = void 0;
var PointsBankEntity;
(function (PointsBankEntity) {
    PointsBankEntity[PointsBankEntity["SYSTEM"] = 1] = "SYSTEM";
    PointsBankEntity[PointsBankEntity["ORGANIZATION"] = 2] = "ORGANIZATION";
    PointsBankEntity[PointsBankEntity["MANAGER"] = 3] = "MANAGER";
    PointsBankEntity[PointsBankEntity["PARTICIPANT"] = 4] = "PARTICIPANT";
    // TOPUP: 6, // DEPRECATED
    PointsBankEntity[PointsBankEntity["PERK"] = 7] = "PERK";
    PointsBankEntity[PointsBankEntity["POOL_REDEMPTION"] = 8] = "POOL_REDEMPTION";
    PointsBankEntity[PointsBankEntity["POOL_PERK"] = 9] = "POOL_PERK";
    PointsBankEntity[PointsBankEntity["POOL_CLOSED"] = 10] = "POOL_CLOSED";
})(PointsBankEntity || (exports.PointsBankEntity = PointsBankEntity = {}));
var UserPointsBankEntity;
(function (UserPointsBankEntity) {
    UserPointsBankEntity[UserPointsBankEntity["PARTICIPANT"] = 4] = "PARTICIPANT";
    UserPointsBankEntity[UserPointsBankEntity["MANAGER"] = 3] = "MANAGER";
})(UserPointsBankEntity || (exports.UserPointsBankEntity = UserPointsBankEntity = {}));
var MarketplacePointsBankEntity;
(function (MarketplacePointsBankEntity) {
    MarketplacePointsBankEntity[MarketplacePointsBankEntity["PERK"] = 7] = "PERK";
    MarketplacePointsBankEntity[MarketplacePointsBankEntity["POOL_REDEMPTION"] = 8] = "POOL_REDEMPTION";
})(MarketplacePointsBankEntity || (exports.MarketplacePointsBankEntity = MarketplacePointsBankEntity = {}));
var LiquidPointsBankEntity;
(function (LiquidPointsBankEntity) {
    LiquidPointsBankEntity[LiquidPointsBankEntity["SYSTEM"] = 1] = "SYSTEM";
    LiquidPointsBankEntity[LiquidPointsBankEntity["ORGANIZATION"] = 2] = "ORGANIZATION";
    LiquidPointsBankEntity[LiquidPointsBankEntity["MANAGER"] = 3] = "MANAGER";
    LiquidPointsBankEntity[LiquidPointsBankEntity["PARTICIPANT"] = 4] = "PARTICIPANT";
})(LiquidPointsBankEntity || (exports.LiquidPointsBankEntity = LiquidPointsBankEntity = {}));
var PoolPointsBankEntity;
(function (PoolPointsBankEntity) {
    PoolPointsBankEntity[PoolPointsBankEntity["POOL_REDEMPTION"] = 8] = "POOL_REDEMPTION";
    PoolPointsBankEntity[PoolPointsBankEntity["POOL_PERK"] = 9] = "POOL_PERK";
    PoolPointsBankEntity[PoolPointsBankEntity["POOL_CLOSED"] = 10] = "POOL_CLOSED";
})(PoolPointsBankEntity || (exports.PoolPointsBankEntity = PoolPointsBankEntity = {}));
