import React from 'react';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table';
import { getInvoice } from 'helpers/api/licences';

function QuoteTable({ invoiceId, organizationId }) {
  const [invoiceData, setInvoiceData] = React.useState({});
  React.useEffect(() => {
    if (invoiceId) {
      getInvoice({ invoiceId, organizationId, isAdmin: true }).then((data) => {
        setInvoiceData(data);
      });
    }
  }, [invoiceId, organizationId]);

  const { items, taxes, subTotal, total } = invoiceData;
  const hasItems = items?.length;
  if (!hasItems) return null;

  const totalTax = hasItems && taxes?.find((tax) => tax?.name === 'Total Tax');
  const footerData = hasItems && [
    { item: '', quantity: '', unitAmount: 'Subtotal', amount: subTotal },
    {
      item: '',
      quantity: '',
      unitAmount: totalTax?.name || '',
      amount: (totalTax?.amount && totalTax.amount.toFixed(2)) || '',
    },
    {
      item: '',
      quantity: '',
      unitAmount: 'Total Due (USD)',
      amount: total || '',
    },
  ];

  const tableData = hasItems ? [...items, ...footerData] : [];

  return (
    <Paper variant="outlined">
      <MaterialTable
        title="Summary"
        columns={[
          { title: 'Item', field: 'item' },
          {
            title: 'Quantity',
            field: 'quantity',
            type: 'numeric',
          },
          { title: 'Unit Price', field: 'unitAmount', type: 'numeric' },
          { title: 'Total (USD)', field: 'amount', type: 'numeric' },
        ]}
        data={tableData}
        options={{
          paging: false,
          // pageSize: 10,
          // emptyRowsWhenPaging: false,
          pageSizeOptions: [5],
          search: false,
          rowStyle: (_, i) => ({
            backgroundColor:
              i !== tableData.length - 1 ? 'transparent' : '#efefef',
          }),
        }}
        style={{ boxShadow: 'none' }}
      />
    </Paper>
  );
}

export default QuoteTable;
