import React from 'react';
import { useQuery } from 'react-query';
import { getLicenceTypes } from 'helpers/api/licences';
import getSubscriptionPackageOptions from 'helpers/lib/getSubscriptionPackageOptions';

function useSubscriptionPackagePicker() {
  const { data: licenceTypes } = useQuery('licence-types', getLicenceTypes, {
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });

  const [packageOptions, setPackageOptions] = React.useState({});

  React.useEffect(() => {
    setPackageOptions(getSubscriptionPackageOptions(licenceTypes || []));
  }, [licenceTypes]);

  const [selectedPackageKey, setSelectedPackageKey] = React.useState();
  const contractTermOptions = packageOptions?.[selectedPackageKey];

  const [selectedContractTermTypeId, setSelectedContractTermTypeId] =
    React.useState();

  React.useEffect(() => {
    setSelectedContractTermTypeId();
  }, [contractTermOptions]);

  const selectedContractTerm = contractTermOptions?.find(
    (contractTermOption) => {
      return contractTermOption.typeId === selectedContractTermTypeId;
    }
  );

  const paymentFrequencyOptions = React.useMemo(() => {
    if (!selectedContractTerm) return {};
    if (
      selectedContractTerm.length === '2 year'
      || selectedContractTerm.length === '3 year'
    ) {
      return {
        prepaid: 'Prepaid',
        yearly: 'Yearly',
      };
    }

    return {};
  }, [selectedContractTerm]);

  const reset = () => {
    setSelectedPackageKey(undefined);
    setSelectedContractTermTypeId(undefined);
    setSelectedPaymentFrequencyKey(undefined);
  };

  const [selectedPaymentFrequencyKey, setSelectedPaymentFrequencyKey] =
    React.useState();

  React.useEffect(() => {
    setSelectedPaymentFrequencyKey((previous) => {
      if (paymentFrequencyOptions[previous]) return previous;
      return;
    });
  }, [paymentFrequencyOptions]);

  return {
    packageOptions,
    selectedPackageKey,
    setSelectedPackageKey,
    contractTermOptions,
    selectedContractTerm,
    selectedContractTermTypeId,
    setSelectedContractTermTypeId,
    paymentFrequencyOptions,
    selectedPaymentFrequencyKey,
    setSelectedPaymentFrequencyKey,
    reset,
  };
}

export default useSubscriptionPackagePicker;
