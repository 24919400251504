"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const enums_1 = require("@applauz/enums");
exports.default = {
    [`snappoints/transaction-statuses/${enums_1.SnappointsTransactionStatus.CANCELLED}`]: 'Annulée',
    [`snappoints/transaction-statuses/${enums_1.SnappointsTransactionStatus.COMPLETED}`]: 'Complétée',
    [`snappoints/transaction-statuses/${enums_1.SnappointsTransactionStatus.IN_PROGRESS}`]: 'En cours',
    [`snappoints/transaction-statuses/${enums_1.SnappointsTransactionStatus.PENDING}`]: 'En attente',
    [`snappoints/transaction-statuses/${enums_1.SnappointsTransactionStatus.UNKNOWN}`]: 'Inconnu',
};
