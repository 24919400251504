export const getToken = (code) => {
  const params = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      clientType: 'superadmin', // should be a different server, but we are using hub to make token request
    }),
  };

  if (code) {
    params.body = JSON.stringify({
      code,
      redirect_uri: `${process.env.REACT_APP_SUPER_ADMIN_URL}/auth/login-redirect`, // eslint-disable-line camelcase
      clientType: 'superadmin', // should be a different server, but we are using hub to make token request
    });
  }

  return fetch(`${process.env.REACT_APP_HUB_SERVER_URL}/auth/token`, params)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.status);
      }
      return res;
    })
    .then((res) => res.json())
    .then((tokenData) => {
      return {
        accessToken: tokenData.authorization.access_token,
        // getTimestamp in seconds and add expiresAt to it
        expiredIn: tokenData.authorization.expires_in,
      };
    });
};

export const getPermissions = ({ accessToken }) => {
  const params = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  return fetch(
    `${process.env.REACT_APP_HUB_SERVER_URL}/api/permissions`,
    params
  )
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.status);
      }
      return res;
    })
    .then((res) => res.json());
};

export const logout = async () => {
  const url = `${process.env.REACT_APP_HUB_SERVER_URL}/auth/revokeToken`;

  const params = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      clientType: 'superadmin', // should be a different server, but we are using hub to make token request
    }),
  };

  await fetch(url, params).then((res) => res.json());
};
