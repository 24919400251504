"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const enums_1 = require("@applauz/enums");
const translations = [
    {
        keys: [enums_1.OrderStatus.PENDING, enums_1.AwardOrderStatus.PENDING, 'PENDING'],
        translation: 'Pending',
    },
    {
        keys: [enums_1.OrderStatus.PROCESSED, enums_1.AwardOrderStatus.PROCESSED, 'PROCESSED'],
        translation: 'Processed',
    },
    {
        keys: [enums_1.OrderStatus.PROCESSING, enums_1.AwardOrderStatus.PROCESSING, 'PROCESSING'],
        translation: 'Processing',
    },
    {
        keys: [enums_1.OrderStatus.SHIPPED, enums_1.AwardOrderStatus.SHIPPED, 'SHIPPED'],
        translation: 'Shipped',
    },
    {
        keys: [
            enums_1.OrderStatus.PARTIALLY_SHIPPED,
            enums_1.AwardOrderStatus.PARTIALLY_SHIPPED,
            'PARTIALLY_SHIPPED',
        ],
        translation: 'Partially Shipped',
    },
    {
        keys: [enums_1.OrderStatus.CANCELLED, enums_1.AwardOrderStatus.CANCELLED, 'CANCELLED'],
        translation: 'Cancelled',
    },
];
exports.default = Object.assign({}, ...translations.map(({ keys, translation }) => Object.fromEntries(keys.map((key) => [`redemptions/order-statuses/${key}`, translation]))));
