import React, { useEffect, useState, useMemo } from 'react';
import Cleave from 'cleave.js/react';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';

// Custom form elements
import LabelWrapper from 'components/Applauz/FormElements/LabelWrapper';
import Textbox from 'components/Applauz/FormElements/Textbox';

import { saveUserInfo } from 'helpers/api/users';

const PersonalInfo = ({ user, setShowSuccess, setShowError }) => {
  const [personalInfo, setPersonalInfo] = useState({
    dateOfBirth: '',
    dateHired: '',
  });
  const [saveError, setSaveError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setPersonalInfo({
      id: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      jobTitle: user.jobTitle,
      dateOfBirth: user.dateOfBirth || '',
      dateHired: user.dateHired || '',
    });
  }, [user]);

  const handleChange = (name, value) => {
    setPersonalInfo({ ...personalInfo, [name]: value });
  };

  const saveChange = () => {
    console.log({ personalInfo });
    setIsLoading(true);
    saveUserInfo({ type: 'personal', ...personalInfo })
      .then(() => {
        setShowSuccess(true);
        setTimeout(() => {
          setShowSuccess(false);
        }, 1000);
        setIsLoading(false);
      })
      .catch((savePersonalInfoError) => {
        setIsLoading(false);
        setShowError(true);
        console.log({ savePersonalInfoError });
        setSaveError('Could not save user, please contact IT team');
      });
  };

  if (!personalInfo.firstName) return null;

  return (
    <GridContainer>
      <GridItem xs={12} md={6}>
        <GridContainer>
          <LabelWrapper label="First Name">
            <Textbox
              value={personalInfo.firstName}
              onChange={(value) => handleChange('firstName', value)}
            />
          </LabelWrapper>
          <LabelWrapper label="Last Name">
            <Textbox
              value={personalInfo.lastName}
              onChange={(value) => handleChange('lastName', value)}
            />
          </LabelWrapper>
          <LabelWrapper label="Email">
            <Textbox
              value={personalInfo.email}
              onChange={(value) => handleChange('email', value)}
            />
          </LabelWrapper>
          <LabelWrapper label="Job title">
            <Textbox
              value={personalInfo.jobTitle}
              onChange={(value) => handleChange('jobTitle', value)}
            />
          </LabelWrapper>
        </GridContainer>
      </GridItem>
      <GridItem xs={12} md={6}>
        <GridContainer>
          <LabelWrapper label="Date of Birth">
            <Cleave
              placeholder="MM-DD"
              options={{ date: true, datePattern: ['m', 'd'], delimiter: '-' }}
              onChange={(event) =>
                handleChange('dateOfBirth', event.target.value)
              }
              style={{
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                marginTop: 40,
              }}
              value={personalInfo.dateOfBirth}
            />
          </LabelWrapper>
          <LabelWrapper label="Hired date">
            <Cleave
              placeholder="YYYY-MM-DD"
              options={{
                date: true,
                datePattern: ['Y', 'm', 'd'],
                delimiter: '-',
              }}
              onChange={(event) =>
                handleChange('dateHired', event.target.value)
              }
              style={{
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                marginTop: 40,
              }}
              value={personalInfo.dateHired}
            />
          </LabelWrapper>
        </GridContainer>
      </GridItem>
      <GridItem xs={12} md={12}>
        <Button
          onClick={saveChange}
          color="info"
          fullWidth
          disabled={isLoading}
        >
          {'Save'}
        </Button>
      </GridItem>
    </GridContainer>
  );
};

export default PersonalInfo;
