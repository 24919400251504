import React from 'react';
import getNumericFormatComponent from './getNumericFormatComponent';

export default [
  {
    title: 'Date Invoiced',
    field: 'tsInserted',
    defaultSort: 'desc',
  },
  {
    title: 'Name',
    field: 'organizationName',
  },
  {
    title: 'Type',
    field: 'type',
  },
  {
    title: 'Total',
    field: 'total',
    render: (rowData) => getNumericFormatComponent(rowData),
  },
  {
    title: 'Invoice Number',
    field: 'xeroInvoiceNumber',
  },
  {
    title: 'Status',
    field: 'status',
  },
  { title: 'Payment Type', field: 'paymentType' },
];
