/* !

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/useAuth';
import AuthLayout from 'layouts/Auth';
import AdminLayout from 'layouts/Admin';
import { getEndpoints } from 'helpers/api/api';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'assets/scss/material-dashboard-pro-react.scss?v=1.8.0';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchAllOnWindowFocus: false,
      retry: 0,
      staleTime: 60000,
    },
  },
});

const App = () => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const bootstrapAsync = async () => {
      const endpoints = await getEndpoints();

      if (endpoints) {
        localStorage.setItem('endpoints', JSON.stringify(endpoints));
        setIsReady(true);
      }
    };

    bootstrapAsync();
  }, []);

  if (!isReady) return null;

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/auth/*" element={<AuthLayout />} />
            <Route path="/admin/*" element={<AdminLayout />} />
            <Route
              path="/"
              exact
              element={<Navigate to="/admin/dashboard" />}
            />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </QueryClientProvider>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
