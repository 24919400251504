import React, { useEffect } from 'react';
import cx from 'classnames';
import { Routes, Route, Navigate } from 'react-router-dom';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar';
import Sidebar from 'components/Sidebar/Sidebar';

import staticRoutes from 'routes';
import { useAuth } from '../context/useAuth';
import styles from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle';
import logo from 'assets/img/applauz-logo.svg';

// api
import { getUser } from '../helpers/api/auth';

let ps;

const useStyles = makeStyles(styles);

const Dashboard = (props) => {
  const {
    auth: { user = {}, accessToken, ...auth } = {},
    setAuth,
    can,
    logIn,
    logOut,
  } = useAuth();

  useEffect(() => {
    getUser()
      .then(({ scope, ...data }) => {
        setAuth({ ...auth, accessToken, user: data, scope });
      })
      .catch(() => {
        logIn();
      });
  }, []);

  const routes = React.useMemo(
    () =>
      (!auth.scope && staticRoutes)
      || staticRoutes
        .filter((r) => !r.permissions || auth.scope.includes(r.permissions))
        .map(
          (r) =>
            (!r.collapse && r) || {
              ...r,
              views: r.views.filter(
                (v) => !v.permissions || auth.scope.includes(v.permissions)
              ),
            }
        ),
    [auth]
  );

  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const color = 'blue';
  const bgColor = 'black';
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    `${classes.mainPanel
     } ${
     cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf('Win') > -1,
    })}`;
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
      window.removeEventListener('resize', resizeFunction);
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getActiveRoute = (routes) => {
    const activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        const collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/admin') {
        return (
          <Route path={`${prop.path}/*`} element={prop.component} key={key} />
        );
      } 
        return null;
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={'Super Admin'}
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        user={user}
        logOut={logOut}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {accessToken && can('access:super.admin') ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <Routes>
                {getRoutes(routes)}
                <Route
                  index
                  element={<Navigate from="/admin" to="/admin/dashboard" />}
                />
              </Routes>
            </div>
          </div>
        ) : (
          <Navigate
            to={{
              pathname: '/auth/login-page',
              state: { referer: rest.location, forbiden: accessToken && true },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
