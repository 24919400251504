import { useCallback, useMemo } from 'react';

const useWarningText = (
  selectedPackage,
  getUpcomingDates,
  discountTotalRate
) => {
  const getWarningText = useCallback(() => {
    if (!selectedPackage.typeId) return '';

    const {
      name: packageName,
      licencesCount: numberOfLicences,
      pricePerLicence,
      length,
    } = selectedPackage;

    const { startOfNewTerm, endOfNewTerm } = getUpcomingDates();
    const finalCalculatedPrice =
      numberOfLicences * pricePerLicence * discountTotalRate;

    return {
      finalCalculatedPrice: finalCalculatedPrice.toFixed(2),
      packageName,
      startOfNewTerm,
      endOfNewTerm,
      numberOfLicences,
      pricePerLicence,
      discountTotalRate,
      length,
    };
  }, [selectedPackage, getUpcomingDates, discountTotalRate]);

  const warningText = useMemo(getWarningText, [getWarningText]);

  return warningText;
};

export default useWarningText;
