import React, { useState } from 'react';
// react plugin for creating date-time-picker
import Datetime from 'react-datetime';
import moment from 'moment';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button';
import CardBody from 'components/Card/CardBody';

import InfoWrapper from 'components/Applauz/InfoWrapper';

import user from 'variables/mock/singleUser';
import vouchers from 'variables/mock/vouchers';

const Edit = () => {
  const voucher = vouchers[1];
  const [dateCelebration, setDateCeleberation] = useState(
    voucher.dateCelebration
  );
  const [isLoading, setIsLoading] = useState(false);
  const classes = makeStyles(styles)();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={5}>
        <Card>
          <CardBody>
            <h4 className={classes.cardTitle}>Voucher details</h4>
            <InfoWrapper label="Date created" value={voucher.dateCreated} />
            <InfoWrapper label="Celebration" value={voucher.celebration} />
            <InfoWrapper
              label="Celebration Date"
              value={voucher.dateCelebration}
            />
            <InfoWrapper
              label="Licence activated"
              value={`${voucher.dtActivated} (${moment(
                voucher.dtActivated
              ).fromNow()})`}
            />
            <InfoWrapper
              label="Licence deactivated"
              value={`${voucher.dtDeactivated} (${moment(
                voucher.dtDeactivated
              ).fromNow()})`}
            />
            <InfoWrapper label="Sent" value={voucher.dtSent} />
            <InfoWrapper label="Redeemed" value={voucher.dtRedeemed} />
            <h4 className={classes.cardTitle}>This voucher is assigned to</h4>
            <InfoWrapper label="Employee" value={user.fullName} />
            <InfoWrapper label="Organization" value={user.organization} />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={7}>
        <Card>
          <CardBody>
            <h4 className={classes.cardTitle}>Modify this voucher</h4>
            <GridContainer>
              <GridItem xs={12} md={4}>
                <InputLabel className={classes.label}>
                  Celebration Date
                </InputLabel>
                <br />
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    inputProps={{
                      placeholder: 'Choose a date',
                    }}
                    onChange={(date) =>
                      setDateCeleberation(date.format('YYYY-MM-DD'))
                    }
                    value={dateCelebration}
                    isValidDate={(current) => current.isAfter(moment())}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <h4 className={classes.cardTitle}>Actions</h4>
            <GridContainer>
              <GridItem xs={12} md={4}>
                <Button
                  onClick={() => {}}
                  color="info"
                  fullWidth
                  disabled={isLoading}
                >
                  {'Send voucher by email'}
                </Button>
                <Button
                  onClick={() => {}}
                  color="success"
                  fullWidth
                  disabled={isLoading}
                >
                  {'Reactivate voucher'}
                </Button>
                <Button
                  onClick={() => {}}
                  color="danger"
                  fullWidth
                  disabled={isLoading}
                >
                  {'Deactivate voucher'}
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Edit;
