import { get, post } from '.';

export const completeWiretransferPurchase = (invoiceID) =>
  post('as://awards/admin/completeWiretransferPurchase', {
    params: { invoiceID },
  });

export const getVoucherLicencePrice = (organizationID) =>
  get('as://awards/admin/getVoucherLicencePrice', {
    params: { organizationID },
  });

export const updateVoucherLicencePrice = (organizationID, price) =>
  post('as://awards/admin/updateVoucherLicencePrice', {
    params: { organizationID, price },
  });

export const getAwards = () => get('as://awards/admin/getAwards');

export const bulkUpdateAwards = (params) => {
  return post(
    `${process.env.REACT_APP_HUB_SERVER_URL}/serviceAwards/updateAwards`,
    {
      params,
    }
  );
};

export const bulkReactivateAwards = (awardIDs) =>
  post(`${process.env.REACT_APP_HUB_SERVER_URL}/serviceAwards/reactivate`, {
    params: awardIDs,
  });

export const convertVouchersToPoints = (awardIds) =>
  post(`${process.env.REACT_APP_HUB_SERVER_URL}/serviceAwards/convert`, {
    params: awardIds,
  });

export const getMagicLoginLink = (awardId) =>
  get(
    `${process.env.REACT_APP_HUB_SERVER_URL}/serviceAwards/getMagicLoginLink`,
    {
      params: { awardId },
    }
  );
