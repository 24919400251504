import React from 'react';
import { NumericFormat } from 'react-number-format';
// react component for creating dynamic tables
import MaterialTable from 'material-table';
import SearchIcon from '@material-ui/icons/Search';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import InfoWrapper from 'components/Applauz/InfoWrapper';

const ServiceAwards = ({
  awards: { allAwards, active, redeemed, expired },
}) => {
  return (
    <GridContainer>
      <GridItem xs={6}>
        <InfoWrapper label="Total Vouchers" value={allAwards.length} />
        <InfoWrapper label="Active" value={active} />
      </GridItem>
      <GridItem xs={6}>
        <InfoWrapper label="Expired" value={expired} />
        <InfoWrapper label="Redeemed" value={redeemed} />
      </GridItem>
      <GridItem xs={12}>
        <MaterialTable
          title=""
          columns={[
            { title: 'Celebration Date', field: 'date', defaultSort: 'desc' },
            { title: 'User', field: 'user' },
            { title: 'Milestone', field: 'milestone' },
            {
              title: 'Amount',
              field: 'amount',
              render: (rowData) => (
                <NumericFormat
                  value={rowData.amount}
                  displayType={'text'}
                  thousandSeparator
                  prefix={`${rowData.currency} $`}
                  decimalScale={2}
                  fixedDecimalScale
                />
              ),
              align: 'right',
            },
            { title: 'Status', field: 'status' },
          ]}
          data={allAwards}
          options={{
            actionsColumnIndex: -1,
            search: false,
            toolbar: false,
          }}
          icons={{ Filter: SearchIcon }}
          style={{ boxShadow: 'none' }}
        />
      </GridItem>
    </GridContainer>
  );
};

export default ServiceAwards;
