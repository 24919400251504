import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
} from '@material-ui/core';

const TypeOfCancel = {
  END_OF_TERM: 'END_OF_TERM',
  IMMEDIATELY: 'IMMEDIATELY',
};

export default function CancelPlanForm({
  currentPlan,
  onEndOfTermCancel,
  onImmediateCancel,
}) {
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] =
    React.useState(false);
  const [typeOfCancel, setTypeOfCancel] = React.useState();

  const handleOpen = (newTypeOfCancel) => {
    setTypeOfCancel(newTypeOfCancel);
    setConfirmationModalIsOpen(true);
  };
  const handleClose = () => {
    setTypeOfCancel();
    setConfirmationModalIsOpen(false);
  };

  const handleCancel = () => {
    if (typeOfCancel === TypeOfCancel.END_OF_TERM) {
      onEndOfTermCancel();
    } else if (typeOfCancel === TypeOfCancel.IMMEDIATELY) {
      onImmediateCancel();
    }
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          height: 300,
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <Box p={2} align="center">
          <Typography>When should this organization be cancelled?</Typography>
          <Box m={1}>
            <Button
              onClick={() => {
                handleOpen(TypeOfCancel.END_OF_TERM);
              }}
              color="primary"
              variant="outlined"
            >
              End of Term ({currentPlan.dt_next_bill})
            </Button>
          </Box>
          <Box m={1}>
            <Button
              disabled
              onClick={() => {
                handleOpen(TypeOfCancel.IMMEDIATELY);
              }}
              color="secondary"
              variant="outlined"
            >
              Immediately (No Refund!)
            </Button>{' '}
            (Currently Unavailable)
          </Box>
        </Box>
      </div>
      <Dialog onClose={handleClose} open={confirmationModalIsOpen}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This is the final step. Pressing CANCEL ACCOUNT is destructive and
            it WILL CANCEL THE ACCOUNT!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No, Do Not Cancel</Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              const shouldCancel = confirm(
                'Okay, there is this final fail-safe, but this really is the last step.'
              );
              if (shouldCancel) {
                handleCancel();
              }
              handleClose();
            }}
          >
            YES, CANCEL ACCOUNT
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
