import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import routes from 'routes';

import styles from 'assets/jss/material-dashboard-pro-react/layouts/authStyle';
import backgroundImage from 'assets/img/login-background.png';

// context
import { useAuth } from '../context/useAuth';

const useStyles = makeStyles(styles);

const Auth = () => {
  const { auth: { accessToken } = {}, can } = useAuth();
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = 'unset';
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/auth') {
        return <Route path={prop.path} element={prop.component} key={key} />;
      } 
        return null;
    });
  };

  return (
    <div>
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
          style={{
            backgroundImage: `url("${backgroundImage}")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom left',
            backgroundSize: 'auto',
          }}
        >
          {accessToken && can('access:super.admin') ? (
            <Navigate to="/admin/dashboard" />
          ) : (
            <Routes>
              {getRoutes(routes)}
              <Route index element={<Navigate to="/auth/login-page" />} />
            </Routes>
          )}
        </div>
      </div>
    </div>
  );
};

export default Auth;
