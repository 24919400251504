"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BadgeCategory = void 0;
var BadgeCategory;
(function (BadgeCategory) {
    BadgeCategory[BadgeCategory["DEPRECATED"] = 0] = "DEPRECATED";
    BadgeCategory[BadgeCategory["COMPANY_GOALS"] = 1] = "COMPANY_GOALS";
    BadgeCategory[BadgeCategory["CELEBRATIONS"] = 3] = "CELEBRATIONS";
    BadgeCategory[BadgeCategory["GOOD_WORKS"] = 4] = "GOOD_WORKS";
    BadgeCategory[BadgeCategory["VALUES"] = 5] = "VALUES";
})(BadgeCategory || (exports.BadgeCategory = BadgeCategory = {}));
