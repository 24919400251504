"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrencyDBId = void 0;
var CurrencyDBId;
(function (CurrencyDBId) {
    CurrencyDBId[CurrencyDBId["CAD"] = 1] = "CAD";
    CurrencyDBId[CurrencyDBId["USD"] = 2] = "USD";
    CurrencyDBId[CurrencyDBId["GBP"] = 3] = "GBP";
    CurrencyDBId[CurrencyDBId["AUD"] = 4] = "AUD";
})(CurrencyDBId || (exports.CurrencyDBId = CurrencyDBId = {}));
