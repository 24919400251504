import _ from 'lodash';

function getSubscriptionPackageOptions(res) {
  return _.groupBy(
    res.map((packageOption) => {
      return {
        ...packageOption,
        packageGroupKey: packageOption.language.en_ca,
      };
    }),
    'packageGroupKey'
  );
}

export default getSubscriptionPackageOptions;
