import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardText from 'components/Card/CardText';
import CardBody from 'components/Card/CardBody';

import Form from './Form';

import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

// api
import { getSuperAdminRoles } from 'helpers/api/auth';
import { getSuperAdminUsers, editAdminUser } from 'helpers/api/users';

// context
import { useAuth } from 'context/useAuth';

const useStyles = makeStyles(styles);

const messages = {
  ok: {
    text: 'Successfully edited user!',
    color: 'success',
  },
  not_found: {
    text: 'ERROR - Could not find this user',
    color: 'danger',
  },
  exist: {
    text: 'ERROR - This user already exists in the Applauz Super Admin',
    color: 'warning',
  },
  other: { text: 'Servr Error, an unexpected error happened', color: 'danger' },
};

const EditUser = () => {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();

  const [user, setUser] = useState({});
  const [roles, setRoles] = useState([]);
  const [message, setMessage] = useState(false);

  const { auth: { user: { userID } } = {} } = useAuth();

  useEffect(() => {
    getSuperAdminUsers()
      .then(({ success, users }) => {
        if (success) {
          setUser(users.find((user) => user.userID === parseInt(params.id)));
        }
      })
      .catch((err) => {
        console.log(err);
        setMessage('Could not get user');
      });

    getSuperAdminRoles().then((roles) => {
      setRoles(roles.filter(({ isAdmin }) => isAdmin));
    });
  }, []);

  if (!user) return null;

  if (user.userID === userID) return <h1>You cannot access this page</h1>;

  const handleSubmit = (editUser, setMessage) => {
    editAdminUser(editUser)
      .then(({ success, message }) => {
        if (success) {
          setMessage(messages.ok);
          navigate('/admin/users/manage');
        } else setMessage(messages[message]);
      })
      .catch(() => setMessage(messages.other));
  };

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="info" text>
            <CardText color="info">
              <h4 className={classes.cardTitle}>
                Edit account for {user.name}
              </h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit} user={user} roles={roles} />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default EditUser;
