"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaidLicenceType = void 0;
const LicenceType_1 = require("./LicenceType");
var PaidLicenceType;
(function (PaidLicenceType) {
    PaidLicenceType[PaidLicenceType["MONTHLY"] = LicenceType_1.LicenceType.MONTHLY] = "MONTHLY";
    PaidLicenceType[PaidLicenceType["ANNUALLY"] = LicenceType_1.LicenceType.ANNUALLY] = "ANNUALLY";
    PaidLicenceType[PaidLicenceType["BIANNUALLY"] = LicenceType_1.LicenceType.BIANNUALLY] = "BIANNUALLY";
    PaidLicenceType[PaidLicenceType["TRIANNUALLY"] = LicenceType_1.LicenceType.TRIANNUALLY] = "TRIANNUALLY";
    // SA GIFTING WITH POINTS
    PaidLicenceType[PaidLicenceType["SA_MONTHLY"] = LicenceType_1.LicenceType.SA_MONTHLY] = "SA_MONTHLY";
    PaidLicenceType[PaidLicenceType["SA_ANNUALLY"] = LicenceType_1.LicenceType.SA_ANNUALLY] = "SA_ANNUALLY";
    PaidLicenceType[PaidLicenceType["SA_BIANNUALLY"] = LicenceType_1.LicenceType.SA_BIANNUALLY] = "SA_BIANNUALLY";
    PaidLicenceType[PaidLicenceType["SA_TRIANNUALLY"] = LicenceType_1.LicenceType.SA_TRIANNUALLY] = "SA_TRIANNUALLY";
    // R360
    PaidLicenceType[PaidLicenceType["STARTER_MONTHLY"] = LicenceType_1.LicenceType.STARTER_MONTHLY] = "STARTER_MONTHLY";
    PaidLicenceType[PaidLicenceType["STARTER_ANNUALLY"] = LicenceType_1.LicenceType.STARTER_ANNUALLY] = "STARTER_ANNUALLY";
    PaidLicenceType[PaidLicenceType["STARTER_BIANNUALLY"] = LicenceType_1.LicenceType.STARTER_BIANNUALLY] = "STARTER_BIANNUALLY";
    PaidLicenceType[PaidLicenceType["STARTER_TRIANNUALLY"] = LicenceType_1.LicenceType.STARTER_TRIANNUALLY] = "STARTER_TRIANNUALLY";
    PaidLicenceType[PaidLicenceType["PRO_MONTHLY"] = LicenceType_1.LicenceType.PRO_MONTHLY] = "PRO_MONTHLY";
    PaidLicenceType[PaidLicenceType["PRO_ANNUALLY"] = LicenceType_1.LicenceType.PRO_ANNUALLY] = "PRO_ANNUALLY";
    PaidLicenceType[PaidLicenceType["PRO_BIANNUALLY"] = LicenceType_1.LicenceType.PRO_BIANNUALLY] = "PRO_BIANNUALLY";
    PaidLicenceType[PaidLicenceType["PRO_TRIANNUALLY"] = LicenceType_1.LicenceType.PRO_TRIANNUALLY] = "PRO_TRIANNUALLY";
})(PaidLicenceType || (exports.PaidLicenceType = PaidLicenceType = {}));
