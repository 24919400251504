import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardText from 'components/Card/CardText';
import CardBody from 'components/Card/CardBody';

import Form from './Form';

// api
import { addUser } from 'helpers/api/users';

import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import { getSuperAdminRoles } from 'helpers/api/auth';

const useStyles = makeStyles(styles);

const messages = {
  ok: {
    text: 'Applauz user with this email address was found!',
    color: 'success',
  },
  not_found: {
    text: 'ERROR - Could not find an active Applauz user with this email address',
    color: 'danger',
  },
  exist: {
    text: 'ERROR - This user already exists in the Applauz Super Admin',
    color: 'warning',
  },
  other: { text: 'Servr Error, an unexpected error happened', color: 'danger' },
};

const AddUser = () => {
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    getSuperAdminRoles().then((roles) => {
      setRoles(roles.filter(({ isAdmin }) => isAdmin));
    });
  }, []);
  const classes = useStyles();

  const handleSubmit = (toAddUser, setMessage) => {
    setMessage(false);
    addUser(toAddUser)
      .then(({ success, message }) => {
        if (success) {
          setMessage(messages.ok);
          navigate('admin/users/manage');
        } else setMessage(messages[message]);
      })
      .catch((err) => setMessage(messages.other));
  };

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="info" text>
            <CardText color="info">
              <h4 className={classes.cardTitle}>Add a new user</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit} roles={roles} />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default AddUser;
