export default [
  {
    id: 0,
    name: 'All',
  },
  {
    id: 1,
    name: 'Unpaid',
  },
  {
    id: 2,
    name: 'Scheduled',
  },
  {
    id: 3,
    name: 'Sent',
  },
  {
    id: 4,
    name: 'Redeemed',
  },
  {
    id: 5,
    name: 'Credited',
  },
];
