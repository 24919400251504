"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    'default-messages/customizations/5': `Cela fait déjà {{numOfYears}} an(s) que vous avez commencé à travailler avec nous!
  Nous aimerions profiter de cette occasion pour vous témoigner notre reconnaissance, car vous êtes un membre précieux de notre équipe.
  Votre fidélité, votre dévouement et la façon dont vous contribuez à notre entreprise nous impressionnent vraiment.
  En espérant que vous allez continuer à travailler (et à vous amuser) avec nous, pour de nombreuses années encore !`,
    'default-messages/customizations/4': `Cela fait déjà {{numOfYears}} an(s) que vous avez commencé à travailler avec nous !
  Nous aimerions profiter de cette occasion pour vous témoigner notre reconnaissance, car vous êtes un membre précieux de notre équipe.
  Votre fidélité, votre dévouement et la façon dont vous contribuez à notre entreprise nous impressionnent vraiment.
  En espérant que vous allez continuer à travailler (et à vous amuser) avec nous, pour de nombreuses années encore !`,
    'default-messages/customizations/3': 'Cela fait {{YEAR}} que vous êtes avec nous. Nous sommes fiers d’avoir un employé comme vous dans notre équipe. Félicitations du fond du coeur et meilleurs voeux pour votre anniversaire de service.',
    'default-messages/customizations/2': `Puisque qu’aujourd’hui est un jour particulier, nous aimerions en profiter pour vous envoyer nos meilleurs voeux. Nous sommes vraiment reconnaissants de vous avoir dans notre équipe!
  
  Nous espérons passer encore bien des années en votre compagnie. Vous connaissant, elles seront couronnées par de nombreux succès! Vous pouvez compter sur nous pour les célébrer ensemble.`,
    'default-messages/customizations/1': "Tout le monde mérite d'avoir au moins une journée spéciale dans l’année. Aujourd'hui c’est la tienne ! On espère que ton anniversaire soit aussi magnifique et extraordinaire que toi.",
    'default-messages/customizations/7': '{{sender.first_name}} vient de marquer votre objectif comme “atteint!” Bon travail!',
    'default-messages/customizations/11': '{{sender.first_name}} vient de marquer votre objectif comme “partiellement atteint!” Bon travail!',
    'default-messages/customizations/10': 'Félicitations! Vous avez atteint partiellement votre objectif.',
    'default-messages/customizations/6': 'Toutes nos félicitations! Vous avez atteint votre objectif avec succès.',
    'default-messages/customizations/8': `Félicitations {{first_name}} !

  Vous fêtez vos {{milestone}} ans avec nous, et nous aimerions profiter de cette occasion pour vous remercier d'être un membre si précieux de notre équipe.

  Pour vous remercier de votre fidélité et de votre dévouement, voici un bon cadeau que vous pouvez échanger sur le marché des récompenses Applauz. Cliquez simplement sur le lien ci-dessous pour sélectionner votre cadeau !`,
    'default-messages/customizations/9': '{{company}} vous invite à vous joindre à son programme de reconnaissance des employés où votre travail et vos accomplissements seront reconnus par vos collègues et récompensés par votre gestionnaire.',
    'default-messages/customizations/spot-gift': `Pour vous remercier de votre fidélité et de votre dévouement, voici un bon cadeau que vous pouvez échanger sur le marché des récompenses Applauz. Cliquez simplement sur le lien ci-dessous pour sélectionner votre cadeau !`,
};
