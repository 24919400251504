/**
 * If an invoice needs to default to USD, add its key here,
 * otherwise, it will get the currency from the organization's
 * own default currency.
 */

const purchaseTypeCurrencyDefaults = {
  licenceSubscription: 'usd',
};

export default purchaseTypeCurrencyDefaults;
