/* eslint-disable camelcase */

import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// core components
import SnackbarContent from 'components/Snackbar/SnackbarContent';

import Form from './Form';

import { addAccount } from 'helpers/api/accounts';
import { updateModules } from 'helpers/api/modules';

const AddAccounts = () => {
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [company, setCompany] = useState({
    company_name: '',
    hubspotCompanyID: '',
    xeroContactID: '',
    owner: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    address: {
      address_1: '',
      address_2: '',
      phone: '',
      postal_code: '',
      city: '',
      province_id: '',
      country_id: '38',
    },
  });

  const catalog = useMemo(() => {
    return {
      grsCatalogId: '',
      supplierIds: [],
    };
  }, []);

  const [activeModuleIds, setActiveModuleIds] = useState([]);

  const updateCompany = (name, value, subProp) => {
    if (subProp) {
      setCompany({
        ...company,
        [name]: { ...company[name], [subProp]: value },
      });
      return;
    }
    setCompany({ ...company, [name]: value });
  };

  // module
  const onChangeModuleIdsHandler = React.useCallback((id) => {
    id
      && setActiveModuleIds((prev) =>
        prev.includes(id)
          ? prev.filter((moduleId) => moduleId !== id)
          : [...prev, id]
      );
  }, []);

  const onSubmit = () => {
    setIsLoading(true);

    if (company.address.province_id === '') delete company.address.province_id;

    addAccount(company)
      .then(async (res) => {
        if (res.success && res.organizationId) {
          await updateModules(res.organizationId, activeModuleIds);
          setSuccess(true);
          setTimeout(() => {
            navigate(`/admin/accounts/view/${res.organizationId}`);
          }, 1000);
        }
        setIsLoading(false);
      })
      .catch((addAccountError) => {
        setIsLoading(false);
        console.error({ addAccountError });
        setError(`Could not register company properly. Please contact IT team`);
      });
  };

  return (
    <>
      {error && <SnackbarContent message={error} color="rose" />}

      <Form
        company={company}
        catalog={catalog}
        setCompany={updateCompany}
        activeModuleIds={activeModuleIds}
        onChangeActiveModuleIds={onChangeModuleIdsHandler}
        isEdit={false}
        onSubmit={onSubmit}
        isLoading={isLoading}
        success={success}
        setIsLoading={setIsLoading}
      />
    </>
  );
};

export default AddAccounts;
