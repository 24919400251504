import React from 'react';
import { NumericFormat } from 'react-number-format';

const getNumericFormatComponent = (rowData) => (
  <NumericFormat
    value={rowData.total}
    displayType={'text'}
    thousandSeparator
    prefix={`$`}
    decimalScale={2}
    fixedDecimalScale
  />
);

export default getNumericFormatComponent;
