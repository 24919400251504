import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import Modal from 'components/Modal/Modal';

export default function UpdatePlanConfirmationModal({
  warningModalIsOpen,
  setWarningModalIsOpen,
  onUpdateUpcomingPlanHandler,
  warningText,
  isLoading,
}) {
  return (
    <Modal
      visible={warningModalIsOpen}
      title={'Confirmation'}
      cancelText="Cancel"
      // actions
      onCancel={() => setWarningModalIsOpen(false)}
      confirmText="Confirm"
      confirmColor="info"
      cancelColor="tumblr"
      onConfirm={onUpdateUpcomingPlanHandler}
      onClickCloseButton={() => setWarningModalIsOpen(false)}
    >
      <Typography variant="body1">
        You are about to set a custom price of:{' '}
        <Box component="span" fontWeight="bold">
          ${warningText.pricePerLicence}{' '}
        </Box>
        per licence <br />
        From{' '}
        <Box component="span" fontWeight="bold">
          {warningText.startOfNewTerm}
        </Box>{' '}
        to{' '}
        <Box component="span" fontWeight="bold">
          {warningText.endOfNewTerm}
        </Box>
        <br />
        Package:{' '}
        <Box component="span" fontWeight="bold">
          {warningText.packageName} - {warningText.length}
        </Box>{' '}
        <br />
        Number of licences:{' '}
        <Box component="span" fontWeight="bold">
          {warningText.numberOfLicences}{' '}
        </Box>
        <br />
        The total invoice amount will be{' '}
        <Box component="span" fontWeight="bold">
          ${warningText.finalCalculatedPrice}{' '}
        </Box>{' '}
        + taxes
      </Typography>

      <Typography variant="body1">
        <Box sx={{ mt: 2, mb: 2 }}>Do you want to continue?</Box>
      </Typography>

      {isLoading && (
        <Box sx={{ m: 2 }} align="center">
          <CircularProgress size={24} />
        </Box>
      )}
    </Modal>
  );
}
