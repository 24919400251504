import React, { useEffect, useState } from 'react';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

const styles = {
  formControl: {
    minWidth: 200,
    maxWidth: 300,
    marginRight: '15px',
    marginBottom: '30px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
};

const useStyles = makeStyles(styles);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default (props) => {
  const classes = useStyles();

  const { suppliers, categories } = props;

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [name, setName] = useState('');
  const [ID, setID] = useState('');

  const handleSupplierSelect = (e) => {
    const supplier = e.target.value;
    setSelectedSupplier(supplier);
  };

  const handleCategorySelect = (e) => {
    const categoryArray = e.target.value;
    if (categoryArray.includes('hasNoCategories')) {
      setSelectedCategories(['hasNoCategories']);
    } else {
      setSelectedCategories(categoryArray);
    }
  };

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setName(newName);
  };

  const handleIDChange = (e) => {
    const newID = e.target.value;
    setID(newID);
  };

  useEffect(() => {
    if (props.handleSupplierSelect) {
      props.handleSupplierSelect(selectedSupplier);
    }
  }, [selectedSupplier]);

  useEffect(() => {
    if (props.handleCategorySelect) {
      if (!selectedCategories.length) {
        props.handleCategorySelect(undefined);
      } else if (selectedCategories.includes('hasNoCategories')) {
        props.handleCategorySelect([]);
      } else {
        props.handleCategorySelect(
          categories
            .filter(({ name }) => selectedCategories.includes(name))
            .map(({ category_id }) => category_id)
        );
      }
    }
  }, [selectedCategories]);

  useEffect(() => {
    if (props.handleNameChange) {
      props.handleNameChange(name);
    }
  }, [name]);

  useEffect(() => {
    if (props.handleIDChange) {
      props.handleIDChange(ID);
    }
  }, [ID]);

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel>Supplier ID</InputLabel>
        <Select
          value={selectedSupplier}
          onChange={handleSupplierSelect}
          label="Supplier ID"
        >
          {suppliers.map((supplier, i) => (
            <MenuItem key={i} value={supplier.supplier_id}>
              {supplier.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="category-chip-label">Categories</InputLabel>
        <Select
          labelId="category-chip-label"
          id="demo-mutiple-chip"
          multiple
          value={selectedCategories}
          onChange={handleCategorySelect}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value, i) => (
                <Chip key={i} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          <MenuItem key={-1} value="hasNoCategories">
            Has No Categories
          </MenuItem>
          {categories.map(({ name }, i) => (
            <MenuItem key={i} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField label="Product ID" onChange={handleIDChange} value={ID} />
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          label="Product Name"
          onChange={handleNameChange}
          value={name}
        />
      </FormControl>
    </>
  );
};
