"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AwardOrderStatus = void 0;
var AwardOrderStatus;
(function (AwardOrderStatus) {
    AwardOrderStatus[AwardOrderStatus["CART"] = 18] = "CART";
    AwardOrderStatus[AwardOrderStatus["PENDING"] = 19] = "PENDING";
    AwardOrderStatus[AwardOrderStatus["PROCESSING"] = 20] = "PROCESSING";
    AwardOrderStatus[AwardOrderStatus["PROCESSED"] = 21] = "PROCESSED";
    AwardOrderStatus[AwardOrderStatus["SHIPPED"] = 22] = "SHIPPED";
    AwardOrderStatus[AwardOrderStatus["CANCELLED"] = 23] = "CANCELLED";
    AwardOrderStatus[AwardOrderStatus["REVIEW"] = 24] = "REVIEW";
    AwardOrderStatus[AwardOrderStatus["PICK_UP"] = 25] = "PICK_UP";
    AwardOrderStatus[AwardOrderStatus["PARTIALLY_SHIPPED"] = 26] = "PARTIALLY_SHIPPED";
    AwardOrderStatus[AwardOrderStatus["PROCESSING_RETURN"] = 27] = "PROCESSING_RETURN";
    AwardOrderStatus[AwardOrderStatus["READY_FOR_RETURN"] = 28] = "READY_FOR_RETURN";
})(AwardOrderStatus || (exports.AwardOrderStatus = AwardOrderStatus = {}));
