"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationType = void 0;
var NotificationType;
(function (NotificationType) {
    NotificationType["NEW_RECOGNITION"] = "NEW_RECOGNITION";
    NotificationType["NEW_POST"] = "NEW_POST";
    NotificationType["NEW_REACTION"] = "NEW_REACTION";
    NotificationType["REACTION_TO_COMMENT"] = "REACTION_TO_COMMENT";
    NotificationType["ANSWER_TO_POLL"] = "ANSWER_TO_POLL";
    NotificationType["NEW_POINTS"] = "NEW_POINTS";
    NotificationType["NEW_BADGE"] = "NEW_BADGE";
    NotificationType["ADD_AWARD_TO_RECOGNITION"] = "ADD_AWARD_TO_RECOGNITION";
    NotificationType["BIRTHDAY"] = "BIRTHDAY";
    NotificationType["MILESTONES_WORK_ANNIVERSARY_RECOGNITION"] = "MILESTONES_WORK_ANNIVERSARY_RECOGNITION";
    NotificationType["WORK_ANNIVERSARY_RECOGNITION"] = "WORK_ANNIVERSARY_RECOGNITION";
    NotificationType["COMMENT_TO_POST"] = "COMMENT_TO_POST";
    NotificationType["COMMENT_TO_RECOGNITION_POST"] = "COMMENT_TO_RECOGNITION_POST";
    NotificationType["COMMENT_TO_RECOGNITION_POST_FOR_RECIPIENT"] = "COMMENT_TO_RECOGNITION_POST_FOR_RECIPIENT";
    NotificationType["COMMENT_TO_GOAL_POST_FOR_RECIPIENT"] = "COMMENT_TO_GOAL_POST_FOR_RECIPIENT";
    NotificationType["COMMENT_TO_BIRTHDAY_POST_FOR_RECIPIENT"] = "COMMENT_TO_BIRTHDAY_POST_FOR_RECIPIENT";
    NotificationType["COMMENT_TO_ANNIVERSARY_POST_FOR_RECIPIENT"] = "COMMENT_TO_ANNIVERSARY_POST_FOR_RECIPIENT";
    NotificationType["COMMENT_TO_POLL_POST"] = "COMMENT_TO_POLL_POST";
    NotificationType["REPLY_TO_COMMENT"] = "REPLY_TO_COMMENT";
})(NotificationType || (exports.NotificationType = NotificationType = {}));
