"use strict";
/**
 * RecurrentType and Type are directly correlated.
 * - refill -> normal
 * - deposit -> carry_over
 *
 * NOT_SET is when there is no enabled record. NOT_SET does not
 * have a direct correlation to RecurrentType.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutoPointsType = void 0;
var AutoPointsType;
(function (AutoPointsType) {
    AutoPointsType["REFILL"] = "refill";
    AutoPointsType["DEPOSIT"] = "deposit";
    AutoPointsType["NOT_SET"] = "not-set";
})(AutoPointsType || (exports.AutoPointsType = AutoPointsType = {}));
