import React from 'react';
import { Box, InputLabel, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const DropdownWithSearch = ({
  type,
  label,
  items,
  placeholder,
  defaultValue,
  onChange,
}) => {
  const onChangeHandler = (e, newValue) => {
    if (!newValue) {
      return onChange(type, '');
    }
    onChange(type, newValue.value);
  };

  React.useEffect(() => {
    if (defaultValue) {
      onChange(type, defaultValue.value);
    }
  }, [placeholder, defaultValue, onChange, type]);

  const getOptionLabel = (option) => {
    if (!option.value) return option.text;
    return `[ ${option.value} ] - ${option.text}`;
  };

  return (
    <Autocomplete
      id={`label-id-${type}`}
      autoHighlight
      options={items}
      renderOption={(option) => (
        <Box display="flex">
          <div style={{ minWidth: 60, fontWeight: 'bold' }}>{option.value}</div>
          <div>{option.text}</div>
        </Box>
      )}
      getOptionLabel={(option) => getOptionLabel(option)}
      // adds placeholder here due to style different with other form elements
      defaultValue={defaultValue ? defaultValue : { text: placeholder }}
      renderInput={(params) => {
        return (
          <>
            <InputLabel
              htmlFor="multiple-select"
              id={`label-id-${type}`}
              // need adjustment to fit in other form input styles due to Autocomplete has different styles for child input
              // cannot use "hiddenLabel" in Autocomplete due to react warning msg
              style={{ fontSize: 14, marginTop: 2 }}
              className="MuiFormLabel-root"
            >
              {label}
            </InputLabel>

            <TextField
              {...params}
              htmlFor="multiple-select"
              id={`label-id-${type}`}
              placeholder={placeholder}
              variant="standard"
            />
          </>
        );
      }}
      onChange={onChangeHandler}
    />
  );
};

export default DropdownWithSearch;
