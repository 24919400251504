import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import forms from './forms';
import { getAccount } from 'helpers/api/accounts';
import { useQuery } from 'react-query';

const GenerateInvoice = ({
  title,
  selectedAccountId,
  selectedPurchaseType,
  selectedCurrency,
  onQuoteGenerate,
  onGeneratedQuoteURLClear,
}) => {
  const FormComponent = forms[selectedPurchaseType];

  const isReady = !!(selectedAccountId && FormComponent);

  const { data: selectedAccount } = useQuery(
    ['invoice-selected-account', selectedAccountId],
    () => selectedAccountId && getAccount(selectedAccountId),
    {
      retryOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div>
      <Typography variant="h6" style={{ textTransform: 'none' }}>
        {title}
      </Typography>

      <Card>
        <CardBody>
          {isReady ? (
            <FormComponent
              currency={selectedCurrency}
              onQuoteGenerate={onQuoteGenerate}
              onGeneratedQuoteURLClear={onGeneratedQuoteURLClear}
              customPrices={selectedAccount?.account?.currentPlan?.customPrices}
            />
          ) : (
            <Typography variant="body1">
              Select an account and a type of purchase.
            </Typography>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default GenerateInvoice;
