import React, { useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridContainer from 'components/Grid/GridContainer';
import Danger from 'components/Typography/Danger';
import Button from 'components/CustomButtons/Button';
import GridItem from 'components/Grid/GridItem';
import LinearProgress from 'components/CustomLinearProgress/CustomLinearProgress';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle';
import logo from 'assets/img/applauz-logo-full.svg';

// context
import { useAuth } from '../../context/useAuth';

const useStyles = makeStyles(styles);

const LoginRedirect = () => {
  const { auth: { accessToken } = {}, logIn } = useAuth();

  const [cardAnimation, setCardAnimation] = React.useState('cardHidden');
  const [authenticationError, setAuthenticationError] = React.useState(false);
  const [showProgress, setShowProgress] = React.useState(false);

  setTimeout(() => setCardAnimation(''), 700);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const code = query.get('code');
  const state = JSON.parse(query.get('state'));
  const referer =
    (state && typeof state === 'object' && state.pathname)
    || '/admin/dashboard';

  const authUrl =
    `${process.env.REACT_APP_AUTH_URL}/auth`
    + `?client_id=${process.env.REACT_APP_SUPER_ADMIN_CLIENT_ID}`
    + `&response_type=code`
    + `&scope=openid offline_access`
    + `&redirect_uri=${process.env.REACT_APP_SUPER_ADMIN_URL}/auth/login-redirect`;

  useEffect(() => {
    if (!code) {
      return;
    }
    setShowProgress(true);
    // log in
    logIn(code)
      .catch((authError) => {
        console.log({ authError });
        setAuthenticationError(authError);
      })
      .then(() => {
        setShowProgress(false);
      });
  }, [code]);

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          <Card
            login
            className={classes[cardAnimation]}
            style={{ padding: 30 }}
          >
            <CardHeader className={classes.textCenter}>
              <img
                src={logo}
                alt="logo"
                className={classes.img}
                style={{ width: 300 }}
              />
              <h4>Applauz Super Admin</h4>
            </CardHeader>
            <CardBody>
              {showProgress && <LinearProgress />}

              {!showProgress && (
                <>
                  {authenticationError ? (
                    <>
                      <Danger>
                        Authentication failed. Please return to login page and
                        try again
                      </Danger>
                      <Button color="info" size={'lg'} fullWidth href={authUrl}>
                        Try Again
                      </Button>
                    </>
                  ) : (
                    <Button color="info" size={'lg'} fullWidth href={authUrl}>
                      Login with your Applauz account
                    </Button>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {accessToken && <Navigate to={referer} />}
    </div>
  );
};

export default LoginRedirect;
