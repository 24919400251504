import { get, post } from '.';

export const getSuperAdminUsers = () => get('as://users/admin/users');

export const addUser = (user) =>
  get('as://users/admin/find', { params: { ...user } });

export const removeUser = (user_id) =>
  post('as://users/admin/remove', { params: { user_id } });

export const editAdminUser = (user) =>
  post('as://users/admin/edit', { params: { ...user } });

export const getUser = (id) => get('as://users/admin/user', { params: { id } });

export const saveUserInfo = (user) =>
  post('as://users/admin/saveUser', { params: { ...user } });

export const resetPassword = (id, organizationId) =>
  post(
    `${process.env.REACT_APP_HUB_SERVER_URL}/employees/sendPasswordResetLink`,
    {
      params: { employeeIds: [id], organizationId },
    }
  );

export const deleteUser = (id) =>
  post('as://users/admin/deleteUser', { params: { id } });

export const loginAs = (user_id) =>
  get('as://users/admin/loginAs', { params: { user_id } });

export const verifyEmail = (email) =>
  get('as://users/users/verifyEmail', { params: { email } });

export const getGiveCreditEntities = () =>
  get('as://users/admin/getGiveCreditEntities');
