import React, { useEffect, useState, useMemo } from 'react';
import { decode } from 'html-entities';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

// Custom form elements
import LabelWrapper from 'components/Applauz/FormElements/LabelWrapper';
import Textbox from 'components/Applauz/FormElements/Textbox';
import CustomSelect from 'components/Applauz/FormElements/CustomSelect';

// api
import { getCountries } from 'helpers/api/api';
import { saveUserInfo } from 'helpers/api/users';

const Address = ({
  address = {},
  type,
  setShowSuccess,
  setShowError,
  fetchUser,
}) => {
  const [countries, setCountries] = useState([]);

  const [userAddress, setUserAddress] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [saveError, setSaveError] = useState(false);

  const handleChange = (name, value) => {
    setUserAddress({ ...userAddress, [name]: value });
  };

  useEffect(() => {
    setUserAddress(address);
  }, [address]);

  useEffect(() => {
    getCountries()
      .then(({ success, countries }) => {
        if (success) setCountries(countries);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const provinces = useMemo(() => {
    const country = countries.find(({ id }) => id == userAddress.country_id);

    return (
      (country
        && country.provinces.map(({ id: value, name }) => ({
          value,
          name: decode(name),
        })))
      || []
    );
  }, [userAddress, countries]);

  const saveChange = () => {
    console.log({ userAddress });
    setIsLoading(true);
    saveUserInfo({ type, ...userAddress })
      .then(({ success }) => {
        if (success) {
          fetchUser();
          setShowSuccess(true);
          setTimeout(() => {
            setShowSuccess(false);
          }, 1000);
        }
        setIsLoading(false);
      })
      .catch((savePersonalInfoError) => {
        setIsLoading(false);
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 1000);
        console.log({ savePersonalInfoError });
        setSaveError('Could not save user, please contact IT team');
      });
  };

  if (!userAddress) return null;

  return (
    <Card>
      <CardBody>
        <form>
          <GridContainer>
            <LabelWrapper label="Address Line 1">
              <Textbox
                value={userAddress.address_1}
                onChange={(value) => handleChange('address_1', value)}
              />
            </LabelWrapper>
            <LabelWrapper label="Address Line 2">
              <Textbox
                value={userAddress.address_2}
                onChange={(value) => handleChange('address_2', value)}
              />
            </LabelWrapper>
            <LabelWrapper label="Phone">
              <Textbox
                value={userAddress.phone}
                onChange={(value) => handleChange('phone', value)}
              />
            </LabelWrapper>
            <LabelWrapper label="City">
              <Textbox
                value={userAddress.city}
                onChange={(value) => handleChange('city', value)}
              />
            </LabelWrapper>
            <LabelWrapper label="Postal Code">
              <Textbox
                value={userAddress.postal_code}
                onChange={(value) => handleChange('postal_code', value)}
              />
            </LabelWrapper>
            <LabelWrapper label="Country">
              <CustomSelect
                placeholder="Select Country"
                containerStyle={{ width: '100%' }}
                options={countries.map(({ id: value, name }) => ({
                  value,
                  name,
                }))}
                value={userAddress.country_id}
                onChange={(value) => handleChange('country_id', value)}
              />
            </LabelWrapper>

            <LabelWrapper label="Province">
              <CustomSelect
                disabled={
                  !userAddress.country_id || userAddress.country_id === ''
                }
                placeholder="Select Province"
                containerStyle={{ width: '100%' }}
                options={provinces}
                value={userAddress.province_id}
                onChange={(value) => handleChange('province_id', value)}
              />
            </LabelWrapper>
          </GridContainer>
        </form>
        <GridItem xs={12} md={12}>
          <Button
            onClick={saveChange}
            color="info"
            fullWidth
            disabled={
              isLoading
              || !userAddress.address_1
              || !userAddress.postal_code
              || !userAddress.country_id
              || !userAddress.province_id
            }
          >
            {'Save'}
          </Button>
        </GridItem>
      </CardBody>
    </Card>
  );
};

export default Address;
