import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import styles from 'assets/jss/material-dashboard-pro-react/components/customInputStyle';

const useStyles = makeStyles(styles);

export default function CustomSelect({
  onChange,
  label,
  value,
  id,
  labelId,
  formControlProps,
  error,
  success,
  containerStyle,
  selectStyle,
  inputStyle,
  options = [],
  placeholder,
  disabled,
  ...props
}) {
  const classes = useStyles();
  let formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }

  return (
    <FormControl
      {...formControlProps}
      className={formControlClasses}
      style={containerStyle}
    >
      <Select
        disabled={disabled}
        native
        id={id}
        value={value}
        style={selectStyle}
        onChange={(event) => onChange(event.target.value)}
      >
        {!value && (
          <option value={value} disabled>
            {placeholder}
          </option>
        )}
        {options.map(({ name, value }) => (
          <option key={value} value={value}>
            {name}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}

CustomSelect.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node,
};
