// this hook returns jsx, but is not a component file
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import moment from 'moment';
import { Box, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

function getSubscriptionTypeName(typeId) {
  const typeNames = {
    1: 'Free',
    2: 'Monthly',
    3: 'Annually',
    4: 'Voucher',
    5: 'Biannually',
    6: 'Triannually',
  };
  return typeNames[typeId] || 'Unknown';
}

export default function useGridColumns(navigate) {
  return React.useMemo(() => {
    const columns = [
      {
        headerName: 'ID',
        field: 'organizationId',
        width: 75,
      },
      {
        headerName: 'Action',
        field: 'edit',
        width: 150,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <div>
              <IconButton
                onClick={() =>
                  navigate(`/admin/accounts/view/${row.organizationId}`)
                }
              >
                <VisibilityIcon />
              </IconButton>
              <IconButton
                onClick={() =>
                  navigate(`/admin/accounts/edit/${row.organizationId}`)
                }
              >
                <EditIcon />
              </IconButton>
            </div>
          );
        },
      },
      {
        headerName: 'Name',
        field: 'name',
        width: 250,
      },
      {
        headerName: 'Status',
        field: 'licencesStatus',
        width: 150,
        valueGetter: ({ row }) => {
          if (row.disable) {
            return 'Deleted';
          }
          const hasActiveLicences = row.licences[0]?.totalAssigned !== 0;
          const subscriptionType = row.licences[0]?.subscriptionType;
          if (hasActiveLicences) {
            return getSubscriptionTypeName(subscriptionType);
          }
        },
        renderCell: ({ value }) => {
          const subscriptionTypeName = value;
          return (
            <div>
              <Typography
                style={
                  subscriptionTypeName === 'Deleted' ? { color: 'red' } : {}
                }
              >
                {subscriptionTypeName}
              </Typography>
            </div>
          );
        },
      },
      {
        headerName: 'Created at',
        field: 'tsInserted',
        width: 150,
        valueFormatter: ({ value }) => {
          return moment(value).format('YYYY-MM-DD');
        },
      },
      {
        headerName: 'Owner',
        field: 'owner',
        width: 200,
        valueGetter: ({ value }) => {
          const { name, email } = value;
          return `<${name}> ${email}`;
        },
        renderCell: ({ row }) => {
          const { name, email } = row.owner;
          return (
            <div>
              <Typography>{name}</Typography>
              <Typography>{email}</Typography>
            </div>
          );
        },
      },
      {
        headerName: 'Licences',
        field: 'licences',
        width: 200,
        valueGetter: ({ value }) => {
          const totalAssigned = value[0]?.totalAssigned;
          const subscriptionType = value[0]?.subscriptionType;
          const subscriptionTypeName =
            getSubscriptionTypeName(subscriptionType);
          return `${totalAssigned} ${subscriptionTypeName} licences`;
        },
        renderCell: ({ row }) => {
          const value = row.licences;
          const formatDate = (date) =>
            date ? moment(date).format('YYYY-MM-DD') : null;
          const subscriptionType = value[0]?.subscriptionType;
          const isSA = subscriptionType === 4;
          const subscriptionTypeName =
            getSubscriptionTypeName(subscriptionType);
          const totalAssigned = value[0]?.totalAssigned;
          const renewalDate = value[0]?.nextRenewalDate;
          const expirationDate = value[0]?.expirationDate;
          const formattedRenewalDate = formatDate(renewalDate);
          const formattedExpirationDate = formatDate(expirationDate);

          return (
            <div>
              <Typography>
                {totalAssigned} {subscriptionTypeName} licences
              </Typography>
              {!isSA && (renewalDate || expirationDate) && (
                <Typography>
                  {renewalDate
                    ? `Renews on ${formattedRenewalDate}`
                    : `Expires on ${formattedExpirationDate}`}
                </Typography>
              )}
            </div>
          );
        },
      },
      {
        headerName: 'Points balance',
        field: 'wallet',
        width: 150,
        valueGetter: ({ value }) => {
          return value.organizationPoints;
        },
        renderCell: ({ row }) => {
          const points = row.wallet.organizationPoints.toLocaleString('en-US');
          return <Typography>{points}</Typography>;
        },
      },
      {
        headerName: 'Employees',
        field: 'employees',
        width: 150,
        valueGetter: ({ value }) => {
          return value.totalCount;
        },
        renderCell: ({ row }) => {
          const value = row.employees;
          const totalEmployees = value.totalCount;
          const activatedEmployees = value.totalActive;
          return (
            <div>
              <Typography>{totalEmployees} employees</Typography>
              <Typography>{activatedEmployees} licenced</Typography>
            </div>
          );
        },
      },
      {
        headerName: 'Sandbox',
        field: 'isSandbox',
        width: 150,
        renderCell: ({ row }) => (
          <Box paddingLeft="20px" width="100%">
            {row.isSandbox && <CheckIcon />}
          </Box>
        ),
      },
    ];

    return columns;
  }, [navigate]);
}
