"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MediaAttachmentType = void 0;
var MediaAttachmentType;
(function (MediaAttachmentType) {
    MediaAttachmentType["IMAGE"] = "image";
    MediaAttachmentType["PHOTO"] = "photo";
    MediaAttachmentType["GIF"] = "gif";
    MediaAttachmentType["VIDEO"] = "video";
    MediaAttachmentType["FILE"] = "file";
})(MediaAttachmentType || (exports.MediaAttachmentType = MediaAttachmentType = {}));
