import React from 'react';
import { NumericFormat } from 'react-number-format';
// react component for creating dynamic tables
import MaterialTable from 'material-table';
import SearchIcon from '@material-ui/icons/Search';
import LinkIcon from '@material-ui/icons/Link';
import { Box, Typography } from '@material-ui/core';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import InfoWrapper from 'components/Applauz/InfoWrapper';
import { getMagicLoginLink } from 'helpers/api/awards';

function handleGenerateMagicLink(id, status) {
  if (status !== 'active') {
    if (
      !confirm(
        'This voucher is not active. Are you sure you want to generate a magic link?'
      )
    ) {
      return;
    }
  }
  getMagicLoginLink(id)
    .then((res) => {
      prompt('Magic link:', res.link);
    })
    .catch((err) => {
      alert(`Error getting magic link: ${err}`);
    });
}

const ServiceAwards = ({
  awards: { allAwards, active, redeemed, expired },
}) => {
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box display="flex" justifyContent="center">
          <Typography variant="body1">Voucher Licence Price</Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography variant="caption" align="center" paragraph>
            Voucher licence price can be set in CUSTOM PRICES under
            &quot;Subscription Plan & Pricing&quot;.
            <br />
            Please select &quot;Gifting with vouchers&quot; option.
          </Typography>
        </Box>
      </GridItem>
      <GridItem xs={6}>
        <InfoWrapper label="Total Vouchers" value={allAwards.length} />
        <InfoWrapper label="Active" value={active} />
      </GridItem>
      <GridItem xs={6}>
        <InfoWrapper label="Expired" value={expired} />
        <InfoWrapper label="Redeemed" value={redeemed} />
      </GridItem>
      <GridItem xs={12}>
        <MaterialTable
          title=""
          columns={[
            { title: 'Celebration Date', field: 'date', defaultSort: 'desc' },
            { title: 'User', field: 'user' },
            { title: 'Milestone', field: 'milestone' },
            {
              title: 'Amount',
              field: 'amount',
              render: (rowData) => (
                <NumericFormat
                  value={rowData.amount}
                  displayType={'text'}
                  thousandSeparator
                  prefix={`${rowData.currency} $`}
                  decimalScale={2}
                  fixedDecimalScale
                />
              ),
              align: 'right',
            },
            { title: 'Status', field: 'status' },
            {
              title: 'Actions',
              field: 'action',
              sortable: false,
              render: (rowData) => {
                return (
                  <LinkIcon
                    onClick={() =>
                      handleGenerateMagicLink(rowData.id, rowData.status)
                    }
                    style={{ cursor: 'pointer' }}
                  />
                );
              },
            },
          ]}
          data={allAwards}
          options={{
            actionsColumnIndex: -1,
            search: false,
            toolbar: false,
          }}
          icons={{ Filter: SearchIcon }}
          style={{ boxShadow: 'none' }}
        />
      </GridItem>
    </GridContainer>
  );
};
export default ServiceAwards;
