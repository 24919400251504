import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from 'assets/jss/material-dashboard-pro-react/components/customLinearProgressStyle';

const useStyles = makeStyles(styles);

export default function Loading(props) {
  const classes = useStyles();
  const { color, ...rest } = props;
  return (
    <CircularProgress
      {...rest}
      classes={{
        root: `${classes.root} ${classes[`${color}Background`]}`,
      }}
    />
  );
}

CircularProgress.defaultProps = {
  color: 'primary',
};

CircularProgress.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'warning',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
};
