import moment from 'moment';

export const validateDateRange = (startDateString, endDateString) => {
  // if one of the dates is null
  if (!startDateString || !endDateString) {
    return false;
  }

  const startDate = moment(startDateString).startOf('day');
  const endDate = moment(endDateString).endOf('day');

  if (!startDate.isValid() || !endDate.isValid()) {
    return false;
  }

  const startDateIsSameOrAfterToday = startDate.isSameOrAfter(undefined, 'day');
  const endDateIsAfterToday = endDate.isAfter(undefined, 'day');
  const endDateIsAfterStartDate = endDate.isAfter(startDate, 'day');

  return (
    startDateIsSameOrAfterToday
    && endDateIsAfterToday
    && endDateIsAfterStartDate
  );
};
