"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomizationEntityCode = void 0;
var CustomizationEntityCode;
(function (CustomizationEntityCode) {
    CustomizationEntityCode[CustomizationEntityCode["BIRTHDAY_EMAIL"] = 1033] = "BIRTHDAY_EMAIL";
    CustomizationEntityCode[CustomizationEntityCode["ANNIVERSARY_EMAIL_REGULAR"] = 1035] = "ANNIVERSARY_EMAIL_REGULAR";
    CustomizationEntityCode[CustomizationEntityCode["ANNIVERSARY_EMAIL_MILESTONE"] = 1034] = "ANNIVERSARY_EMAIL_MILESTONE";
    CustomizationEntityCode[CustomizationEntityCode["WELCOME_EMAIL"] = 1015] = "WELCOME_EMAIL";
    CustomizationEntityCode[CustomizationEntityCode["GOALS_EMAIL"] = 1101] = "GOALS_EMAIL";
    CustomizationEntityCode[CustomizationEntityCode["GOALS_PARTIALLY_COMPLETED_EMAIL"] = 1404] = "GOALS_PARTIALLY_COMPLETED_EMAIL";
    CustomizationEntityCode[CustomizationEntityCode["SERVICE_AWARD_MESSAGE"] = 1131] = "SERVICE_AWARD_MESSAGE";
})(CustomizationEntityCode || (exports.CustomizationEntityCode = CustomizationEntityCode = {}));
