import React from 'react';
import { NumericFormat } from 'react-number-format';
// react component for creating dynamic tables
import MaterialTable from 'material-table';
import GetAppIcon from '@material-ui/icons/GetApp';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

// import invoices from "variables/mock/invoices";
import { getPDF } from 'helpers/api/payments';

const Invoices = ({ invoices }) => {
  const onDownload = (invoiceID) => {
    getPDF(invoiceID)
      .then((data) => {
        const fileURL = URL.createObjectURL(data);

        window.open(fileURL);
      })
      .catch((getInvoicePDFError) => console.log({ getInvoicePDFError }));
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <MaterialTable
          title=""
          columns={[
            { title: 'Date', field: 'date', defaultSort: 'desc' },
            { title: 'Type', field: 'type' },
            {
              title: 'Amount',
              field: 'amount',
              render: (rowData) => (
                <NumericFormat
                  value={rowData.amount}
                  displayType={'text'}
                  thousandSeparator
                  prefix={`${rowData.currency} $`}
                  decimalScale={2}
                  fixedDecimalScale
                />
              ),
              align: 'right',
            },
            { title: 'Status', field: 'status' },
          ]}
          data={invoices}
          options={{
            actionsColumnIndex: -1,
            search: false,
            toolbar: false,
          }}
          actions={[
            (rowData) => ({
              icon: () => <GetAppIcon />,
              tooltip: 'Download Invoice',
              onClick: () => onDownload(rowData.id),
            }),
          ]}
          style={{ boxShadow: 'none' }}
        />
      </GridItem>
    </GridContainer>
  );
};

export default Invoices;
