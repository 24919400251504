import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const Dropdown = ({ label, value, options, onChange }) => {
  const classes = makeStyles(styles)();
  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>
        {label}
      </InputLabel>
      <Select
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        MenuProps={{ className: classes.selectMenu }}
        classes={{ select: classes.select }}
      >
        {options.map((option) => {
          return (
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelectedMultiple,
              }}
              value={option.id}
            >
              {option.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
