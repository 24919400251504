"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostMediaType = void 0;
var PostMediaType;
(function (PostMediaType) {
    PostMediaType["GIF"] = "gif";
    PostMediaType["DOCUMENT"] = "document";
    PostMediaType["IMAGE"] = "image";
    PostMediaType["VIDEO"] = "video";
})(PostMediaType || (exports.PostMediaType = PostMediaType = {}));
