import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import Homepage from './Homepage';
import Edit from './Edit';

const VouchersPage = () => {
  return (
    <Routes>
      <Route path="manage" element={<Homepage />} />
      <Route path="edit/:id" element={<Edit />} />
      <Route index element={<Navigate to="manage" />} />
    </Routes>
  );
};
export default VouchersPage;
