import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import Homepage from './Accounts/Homepage';
import AddAccount from './Accounts/AddAccount';
import EditAccount from './Accounts/EditAccount';
import ViewAccount from './Accounts/ViewAccount';
import EditEmployee from './Employee/EditEmployee';
import LoginAs from './Employee/LoginAs';

const AccountsPage = () => {
  return (
    <Routes>
      <Route path="manage" element={<Homepage />} />
      <Route path="add" element={<AddAccount />} />
      <Route path="edit/:id" element={<EditAccount />} />
      <Route path="view/:id" element={<ViewAccount />} />
      <Route path="employee/:id" element={<EditEmployee />} />
      <Route path="login/:id/:version" element={<LoginAs />} />
      <Route index element={<Navigate to="manage" />} />
    </Routes>
  );
};

export default AccountsPage;
