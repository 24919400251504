import React from 'react';

function useSubscriptionCustomDiscounts() {
  const [list, setList] = React.useState([]);

  const add = React.useCallback(() => {
    setList((previous) => {
      return [
        ...previous,
        {
          label: '',
          value: 0,
          percentage: '0',
          key: `custom-discount-${previous.length}`,
        },
      ];
    });
  }, []);

  const remove = React.useCallback((indexToRemove) => {
    setList((previous) => {
      // I don't need the first argument; clashing with lodash
      // eslint-disable-next-line no-shadow
      return previous.filter((_, index) => index !== indexToRemove);
    });
  }, []);

  const edit = React.useCallback((indexToEdit, newValues) => {
    setList((previous) => {
      return previous.map((discount, index) => {
        if (index !== indexToEdit) {
          return discount;
        }
        return newValues;
      });
    });
  }, []);

  const editDescription = React.useCallback(
    (index, discount, newDescription) => {
      edit(index, {
        ...discount,
        label: newDescription,
      });
    },
    [edit]
  );

  const editPercentage = React.useCallback(
    (index, discount, newPercentage) => {
      const sanitizeValue = () => {
        if (newPercentage > 100) {
          return 100;
        } else if (newPercentage < 0) {
          return 0;
        }
        return newPercentage;
      };
      const newValue = sanitizeValue();
      edit(index, {
        ...discount,
        percentage: newValue,
        value: newValue / 100,
      });
    },
    [edit]
  );

  const areValid = React.useMemo(() => {
    // Having no custom discounts is valid
    if (!list.length) return true;

    return !list.some((discount) => {
      return discount.value <= 0 || !discount.label;
    });
  }, [list]);

  return {
    list,
    add,
    remove,
    edit,
    editDescription,
    editPercentage,
    areValid,
  };
}

export default useSubscriptionCustomDiscounts;
