import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import formatPrice from 'helpers/lib/formatPrice';

function DiscountRow({
  discount,
  quantity,
  pricePerLicence,
  onRemove,
  onDescriptionChange,
  onPercentageChange,
}) {
  return (
    <>
      <Grid item xs={6}>
        {onDescriptionChange ? (
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <IconButton size="small" color="secondary" onClick={onRemove}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </Box>
            <TextField
              label="Discount description"
              fullWidth
              multiline
              variant="outlined"
              value={discount.label}
              onChange={onDescriptionChange}
            />
          </Box>
        ) : (
          <Typography
            variant="body1"
            style={{ color: 'rgb(28,167,84)', fontWeight: '500' }}
          >
            {discount.label}
          </Typography>
        )}
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Discount per licence"
          InputProps={{
            endAdornment: (
              <Box>
                <Typography noWrap>%</Typography>
              </Box>
            ),
          }}
          type="number"
          disabled={!onPercentageChange}
          variant="outlined"
          value={
            (onPercentageChange ? discount.percentage : discount.value * 100)
            || ''
          }
          onChange={onPercentageChange}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Quantity"
          type="number"
          disabled
          variant="outlined"
          value={quantity || 0}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          variant="outlined"
          label="Total discount"
          disabled
          value={formatPrice(-discount.value * pricePerLicence * quantity)}
        />
      </Grid>
    </>
  );
}

export default DiscountRow;
