import React, { useState, useEffect } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
// Local components
import Dropdown from '../components/Dropdown';

// Mock data
import statuses from 'variables/mock/voucherStatuses';

import styles from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
const useStyles = makeStyles(styles);

const Filters = ({ onChange }) => {
  const classes = useStyles();
  const [statusID, setStatusID] = useState(0);
  const [organizationName, setOrganizationName] = useState('');
  const [employeeName, setEmployeeName] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isExpiringSoon, setIsExpiringSoon] = useState(false);

  useEffect(() => {
    onChange({
      organizationName: organizationName && organizationName.trim(),
      employeeName: employeeName && employeeName.trim(),
      statusID,
      isActive,
      isExpiringSoon,
    });
  }, [statusID, organizationName, employeeName, isActive, isExpiringSoon]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <h4 className={classes.cardIconTitle}>Search</h4>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  <Dropdown
                    value={statusID}
                    label={'Status'}
                    onChange={setStatusID}
                    options={statuses}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Accounts"
                    id="organization"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'text',
                      onChange: (event) =>
                        setOrganizationName(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Employee"
                    id="employee"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'text',
                      onChange: (event) => setEmployeeName(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isActive}
                        onChange={(event) => setIsActive(event.target.checked)}
                        value="isActive"
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          thumb: classes.switchIcon,
                          track: classes.switchBar,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    label="Active only"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isExpiringSoon}
                        onChange={(event) =>
                          setIsExpiringSoon(event.target.checked)
                        }
                        value="isExpiringSoon"
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          thumb: classes.switchIcon,
                          track: classes.switchBar,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    label="Expiring in 30 days"
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Filters;
