import React, { useState, useEffect, useCallback } from 'react';
import { useQuery, usePaginatedQuery, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

// react component for creating dynamic tables
import ReactSelect from 'react-select';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import SnackbarContent from 'components/Snackbar/SnackbarContent';
import Modal from 'components/Modal/Modal';
import { DataGrid } from '@material-ui/data-grid';

// Applauz components
import ProductSearchFilters from 'components/Applauz/ProductSearchFilters';

// api
import {
  getProducts,
  getProductsCount,
  getSuppliers,
  getCategories,
  saveProduct,
} from 'helpers/api/catalog';

const columns = [
  {
    headerName: 'ID',
    field: 'productID',
    width: 150,
  },
  {
    headerName: 'Name',
    field: 'name',
    flex: 1,
  },
  {
    headerName: 'Supplier',
    field: 'supplier',
    flex: 1,
  },
  {
    headerName: 'Date Added',
    field: 'dateAdded',
    flex: 1,
  },
  {
    headerName: 'Gift Card',
    field: 'isGiftCard',
    flex: 0.5,
  },
  {
    headerName: 'Categories',
    field: 'categories',
    flex: 2,
    valueGetter: ({ data }) => {
      return (
        data.categories
        && data.categories.map((category) => category.name).join(',')
      );
    },
  },
];

const LIMIT = 50;

const Homepage = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [selectedSupplier, setSelectedSupplier] = useState();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedModalCategories, setSelectedModalCategories] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productID, setProductID] = useState('');
  const [productName, setProductName] = useState('');
  const [debouncedProductID, setDebouncedProductID] = useState('');
  const [debouncedProductName, setDebouncedProductName] = useState('');
  const [offset, setOffset] = useState(0);
  const [products, setProducts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [categoryMenuOpen, setCategoryMenuOpen] = useState(false);

  const getProductsWrapper = (_, params) => getProducts(params);
  const getProductsCountWrapper = (_, params) => getProductsCount(params);

  const { data: fetchedProducts = [], isFetching } = usePaginatedQuery(
    [
      'products',
      {
        supplier_id: selectedSupplier,
        categories: Array.isArray(selectedCategories)
          ? selectedCategories.join(',')
          : undefined,
        name: debouncedProductName || undefined,
        product_id: debouncedProductID || undefined,
        offset,
        limit: LIMIT,
      },
    ],
    getProductsWrapper
  );
  const { data: count = 0 } = useQuery(
    [
      'productsCount',
      {
        supplier_id: selectedSupplier,
        categories: Array.isArray(selectedCategories)
          ? selectedCategories.join(',')
          : undefined,
        name: debouncedProductName || undefined,
        product_id: debouncedProductID || undefined,
      },
    ],
    getProductsCountWrapper
  );
  const { data: categories = [] } = useQuery('categories', getCategories);
  const { data: suppliers = [] } = useQuery('suppliers', getSuppliers);

  const [
    mutate,
    {
      // isLoading: saveProductLoading,
      isSuccess: saveProductSuccess,
      error: saveProductError,
    },
  ] = useMutation(saveProduct);

  const updateProducts = () => {
    // adds categories to the products that were saved successfully
    const selectedCategoryIds = selectedModalCategories.map(
      ({ category_id }) => category_id
    );

    products.forEach((product, i) => {
      selectedProducts.forEach((selectedProduct) => {
        if (product.productID === selectedProduct) {
          products[i].categories = categories.filter((category) =>
            selectedCategoryIds.includes(category.category_id)
          );
        }
      });
    });
  };

  const onSaveProduct = async () => {
    await mutate({
      product_id: selectedProducts,
      categories: selectedCategories.map(({ category_id }) => category_id),
    });
  };

  const reset = () => {
    setProducts([]);
    setPage(1);
    setOffset(0);
  };

  const handleChangePage = (nextPage) => {
    if (nextPage > page) {
      setOffset(products.length);
      setPage(nextPage);
    }
  };

  const handleDebouncedProductNameChange = useCallback(
    debounce((value) => {
      setDebouncedProductName(value);
    }, 500),
    []
  );
  const handleDebouncedProductIDChange = useCallback(
    debounce((value) => {
      setDebouncedProductID(value);
    }, 500),
    []
  );

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    setProducts([...products, ...fetchedProducts]);
  }, [fetchedProducts]);

  useEffect(() => {
    if (saveProductSuccess) {
      updateProducts();
      setSelectedModalCategories([]);
      handleModalClose();
    }
  }, [saveProductSuccess]);

  console.log({
    selectedSupplier,
    selectedCategories,
    productName,
    productID,
  });
  return (
    <GridContainer>
      {saveProductSuccess ? (
        <GridItem xs={12}>
          <SnackbarContent message="Saved successfully!" color="success" />
        </GridItem>
      ) : null}
      <GridItem xs={12}>
        <ProductSearchFilters
          categories={categories}
          suppliers={suppliers}
          name={productName}
          ID={productID}
          handleSupplierSelect={(supplier) => {
            reset();
            setSelectedSupplier(supplier);
          }}
          handleCategorySelect={(categories) => {
            reset();
            setSelectedCategories(categories);
          }}
          handleNameChange={(name) => {
            reset();
            setProductName(name);
            handleDebouncedProductNameChange(name);
          }}
          handleIDChange={(ID) => {
            reset();
            setProductID(ID);
            handleDebouncedProductIDChange(ID);
          }}
        />
      </GridItem>
      <GridItem>
        {selectedProducts.length ? (
          <Button color="info" onClick={handleModalOpen}>
            Edit {selectedProducts.length}&nbsp; product
            {selectedProducts.length === 1 ? '' : 's'}
          </Button>
        ) : null}
      </GridItem>
      <GridItem xs={12}>
        <div style={{ height: 1150, width: '100%' }}>
          <DataGrid
            page={page}
            loading={isFetching}
            rowCount={count}
            rows={products.map((product, index) => ({ ...product, id: index }))}
            columns={columns}
            pageSize={50}
            onPageChange={({ page }) => handleChangePage(page)}
            hideFooterPagination={isFetching}
            onCellClick={(params) => {
              if (params.field === 'actions') {
                navigate(
                  `/admin/products/edit/${params.getValue('productID')}`
                );
              }
            }}
            onSelectionChange={(params) => {
              const { rowIds } = params;
              setSelectedProducts(
                rowIds.map((id) => products[parseInt(id)].productID)
              );
            }}
            autoPageSize
            checkboxSelection
            disableSelectionOnClick
          />
        </div>
      </GridItem>
      <Modal
        visible={modalOpen}
        onClose={handleModalClose}
        onClickCloseButton={handleModalClose}
        onCancel={handleModalClose}
        cancelText={'Cancel'}
        onConfirm={onSaveProduct}
        confirmText={'Save'}
        title="Edit the categories of several products at a time:"
        body={
          <>
            <ReactSelect
              value={selectedModalCategories}
              menuIsOpen={categoryMenuOpen}
              onMenuOpen={() => setCategoryMenuOpen(true)}
              onMenuClose={() => setCategoryMenuOpen(false)}
              closeMenuOnSelect={false}
              isMulti
              onChange={(options) => {
                if (options) setSelectedModalCategories(options);
                else setSelectedModalCategories([]);
              }}
              options={categories}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ category_id }) => category_id}
              placeholder={'Select Categories'}
            />
            <p>
              {selectedProducts.length} product
              {selectedProducts.length === 1 ? '' : 's'} are selected.
            </p>
            {saveProductError ? (
              <SnackbarContent
                message={saveProductError.toString()}
                color="danger"
              />
            ) : null}
          </>
        }
      />
    </GridContainer>
  );
};

export default Homepage;
