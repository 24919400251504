export default [
  {
    id: 1,
    dateCreated: '2020-05-15',
    celebration: '1 year',
    organization: 'Golden Harvest',
    user: 'Jackie Chan',
    amount: '200 CAD',
    statusID: 1,
    status: 'Unpaid',
    dtSent: '2020-05-15 10:10:10',
    dateCelebration: '2020-05-15',
    dtActivated: '2021-03-12 10:10:10',
    dtDeactivated: '2021-04-26 10:10:10',
  },
  {
    id: 2,
    dateCreated: '2020-05-15',
    date: '2020-05-15',
    celebration: '2 years',
    organization: 'Golden Harvest',
    user: 'Jet Li',
    amount: '300 CAD',
    statusID: 3,
    status: 'Sent 2020-01-01',
    dtSent: '2020-05-15 10:10:10',
    dateCelebration: '2020-05-15',
    dtActivated: '2021-03-12 10:10:10',
    dtDeactivated: '2021-04-16 10:10:10',
  },
  {
    id: 3,
    dateCreated: '2020-05-15',
    date: '2020-05-15',
    celebration: '5 years',
    organization: 'McLaren',
    user: 'James Hunt',
    amount: '400 CAD',
    statusID: 2,
    status: 'Scheduled 2020-01-01',
    dtSent: '2020-05-15 10:10:10',
    dateCelebration: '2020-05-15',
    dtActivated: '2021-03-12 10:10:10',
    dtDeactivated: '2021-06-10 10:10:10',
  },
  {
    id: 4,
    dateCreated: '2020-05-15',
    date: '2020-05-15',
    celebration: '10 years',
    user: 'Loren Hill',
    organization: 'ééàà',
    amount: '2000 CAD',
    statusID: 4,
    status: 'Redeemed 2020-01-01',
    dtSent: null,
    dateCelebration: '2020-05-15',
    dtActivated: '2021-03-12 10:10:10',
    dtDeactivated: '2021-06-10 10:10:10',
  },
];
