import React from 'react';
// material-ui components
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// @material-ui/icons
import Close from '@material-ui/icons/Close';
// core components
import Button from 'components/CustomButtons/Button';

import styles from 'assets/jss/material-dashboard-pro-react/modalStyle';

const useStyles = makeStyles(styles);

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Modal({
  visible,
  onClose,
  onClickCloseButton,
  onCancel,
  cancelText,
  onConfirm,
  confirmText,
  title,
  body,
  children,
  confirmColor,
  cancelColor,
}) {
  //   const [modal, setModal] = React.useState(false);
  const classes = useStyles();
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={visible}
        transition={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={onClickCloseButton}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{title || 'Modal Title'}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          {!children ? (
            <h5>{body || 'Are you sure you want to do this?'}</h5>
          ) : (
            children
          )}
        </DialogContent>
        <DialogActions
          className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
        >
          <Button onClick={onCancel} color={cancelColor || 'github'}>
            {cancelText || 'Cancel'}
          </Button>
          <Button onClick={onConfirm} color={confirmColor || 'success'}>
            {confirmText || 'Yes'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
